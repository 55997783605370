import { useMemo } from 'react';
import { type Scene, useScenes } from '../scenes/useScenes';
import { useSelectedScenes } from './useSelectedScenes';

export const useActionSceneIds = (clickedOnSceneId: string) => {
  const { selectedItems } = useSelectedScenes();
  const { scenes } = useScenes();

  return useMemo(
    () => getActionSceneIds(clickedOnSceneId, scenes, selectedItems),
    [selectedItems, clickedOnSceneId, scenes]
  );
};

export const getActionSceneIds = (clickedOnSceneId: string, scenes: Scene[], selectedItems: Scene[]) => {
  // If the clicked scene is not part of a multi-selection, perform action
  // only on that scene
  if (selectedItems.findIndex(({ id }) => id === clickedOnSceneId) === -1) {
    return [clickedOnSceneId];
  }

  // If nothing is multi-selected, use the clicked scene as the action
  if (selectedItems.length === 0) return [clickedOnSceneId];

  const selectedItemsSortedByOriginalOrder = selectedItems.toSorted((a, b) => scenes.indexOf(a) - scenes.indexOf(b));

  // If multiple scenes are selected, perform the action on all selected scenes
  return selectedItemsSortedByOriginalOrder.map(({ id }) => id);
};
