import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import { preventForwardProps, styled } from '@cofenster/web-components';

import type { Project } from '../../../contexts/project/useProject';

// 1. Weird hack for Safari to honor the rounded corner on that element.
//    See: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
// 2. Enforcing the expected aspect ratio can look awkward when there is no
//    asset yet and the content is the asset dropzone, particularly on mobile
//    where room is scarce. This makes it possible to drop the aspect ratio on
//    small screens if it leads to better results.
const Container = styled(
  'div',
  preventForwardProps(['enforceAspectRatioOnSmallScreens'])
)<{ enforceAspectRatioOnSmallScreens?: boolean }>(({ theme, enforceAspectRatioOnSmallScreens }) => ({
  maxHeight: `min(
    calc(
      100vh - (
          var(--project-header-height)
        + var(--project-editor-preview-spacing) * 2
        + var(--project-editor-player-actions-height)
        + var(--project-editor-player-actions-spacing)
        + var(--project-editor-footer-height)
        + var(--impersonation-banner-height, 0px)
      )
    ),
    var(--video-height)
  )`,
  objectFit: 'contain',
  margin: 'auto',
  borderRadius: theme.shape['borderRadius-l'],
  position: 'relative',
  WebkitMaskImage: '-webkit-radial-gradient(white, black)', // 1
  aspectRatio: enforceAspectRatioOnSmallScreens ? 'var(--aspect-ratio)' : undefined, // 2

  [theme.breakpoints.up('md')]: {
    minHeight: 350,
  },

  [theme.breakpoints.up('sm')]: {
    aspectRatio: 'var(--aspect-ratio)',
  },
}));

export const ResponsiveContainer: FC<
  PropsWithChildren<{ project: Project; enforceAspectRatioOnSmallScreens?: boolean }>
> = ({ children, project, enforceAspectRatioOnSmallScreens = true, ...rest }) => {
  const { aspectRatio, height } = VIDEO_FORMATS[project.videoFormat];

  return (
    <Container
      {...rest}
      enforceAspectRatioOnSmallScreens={enforceAspectRatioOnSmallScreens}
      style={
        {
          '--aspect-ratio': String(aspectRatio),
          '--video-height': `${height}px`,
        } as CSSProperties
      }
    >
      {children}
    </Container>
  );
};
