import { type ChangeEventHandler, type ComponentProps, type FC, memo } from 'react';

import { Icon } from '../../../assets/icons/Icon';
import { Button, type ButtonProps } from '../../Button/Button';

export const UploadFileButton: FC<
  {
    handleFilePick: ChangeEventHandler<HTMLInputElement>;
    buttonText: string;
    id: string;
    inputProps?: Partial<ComponentProps<'input'>>;
  } & ButtonProps
> = memo(({ handleFilePick, buttonText, id, inputProps, ...rest }) => {
  return (
    <>
      <input id={id} type="file" hidden onChange={handleFilePick} {...inputProps} />
      <Button htmlFor={id} component="label" startIcon={<Icon size="s" type="UploadIcon" />} fullWidth={true} {...rest}>
        {buttonText}
      </Button>
    </>
  );
});
UploadFileButton.displayName = 'UploadFileButton';
