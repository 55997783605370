import { type FC, useCallback } from 'react';

import {
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  useFeatureFlags,
  useSnackbars,
  withPopoverMenu,
} from '@cofenster/web-components';

import { useArchiveProjectFolder } from '../../../api/hooks/projectFolder/useArchiveProjectFolder';
import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { useUnarchiveProjectFolder } from '../../../api/hooks/projectFolder/useUnarchiveProjectFolder';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProjectSpace } from '../../../contexts/projectSpace/useProjectSpace';
import { TeamPermissionRestriction, useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useDeleteFolderWithConfirmation } from '../../../hooks/projectFolder/useDeleteFolderWithConfirmation';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

const useMoveFolder = (projectFolder: ProjectFolder) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    return openDialog('MoveProjectFolderDialog', { projectFolder });
  }, [openDialog, projectFolder]);
};

const useRename = (projectFolder: ProjectFolder) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('RenameProjectFolderDialog', { projectFolder, trackingSource: 'folderCard' });
  }, [openDialog, projectFolder]);
};

const useDelete = (projectFolder: ProjectFolder) => useDeleteFolderWithConfirmation(projectFolder, 'folderCard');

const useArchive = (projectFolder: ProjectFolder) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const archiveProjectFolder = useArchiveProjectFolder();

  return useCallback(async () => {
    await archiveProjectFolder(projectFolder.id);
    openSnackbar({ children: 'i18n.snackbars.folderArchivedSnackbar.copy' });
    tracking.trackEvent({
      event: 'folderArchived',
      details: {
        folderIds: [projectFolder.id],
      },
    });
  }, [projectFolder, archiveProjectFolder, tracking, openSnackbar]);
};

/**
 * Restore the project folder.
 */
const useUnarchive = (projectFolder: ProjectFolder) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const restoreProjectFolder = useUnarchiveProjectFolder();

  return useCallback(async () => {
    await restoreProjectFolder(projectFolder.id);
    openSnackbar({ children: 'i18n.snackbars.folderUnarchivedSnackbar.copy' });
    tracking.trackEvent({
      event: 'folderUnarchived',
      details: {
        folderIds: [projectFolder.id],
      },
    });
  }, [projectFolder, restoreProjectFolder, tracking, openSnackbar]);
};

type PopoverMenuProps = {
  projectFolder: ProjectFolder;
};

export const PopoverMenu: FC<PopoverMenuProps> = ({ projectFolder }) => {
  const [projectSpace] = useProjectSpace();
  const withMultipleTeamsFlag = useFeatureFlags().hasFeature('MULTIPLE_TEAMS');
  const isInPrivateSpace = projectSpace === 'private';
  const isMovable = isInPrivateSpace || withMultipleTeamsFlag;

  const renameProjectFolder = useRename(projectFolder);
  const archiveProjectFolder = useArchive(projectFolder);
  const unarchiveProjectFolder = useUnarchive(projectFolder);
  const deleteProjectFolder = useDelete(projectFolder);
  const moveProjectFolder = useMoveFolder(projectFolder);

  const isAllowedToUpdate = useTeamPermissionStatus({ has: 'FolderUpdate' }) === 'ALLOWED';
  const isAllowedToDelete = useTeamPermissionStatus({ has: 'FolderDelete' }) === 'ALLOWED';

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <TeamPermissionRestriction has="FolderUpdate">
          {isMovable && isAllowedToUpdate && (
            <PopoverMenuItem icon="ArrowBoxedRightIcon" onClick={moveProjectFolder}>
              i18n.common.move
            </PopoverMenuItem>
          )}
        </TeamPermissionRestriction>

        <TeamPermissionRestriction has="FolderUpdate">
          <PopoverMenuItem icon="PencilIcon" onClick={renameProjectFolder} data-testid="folder-menu-rename-button">
            i18n.common.rename
          </PopoverMenuItem>
        </TeamPermissionRestriction>

        <TeamPermissionRestriction has="FolderUpdate">
          <PopoverMenuItem
            icon={projectFolder.archivedAt ? 'UndoIcon' : 'ArchiveIcon'}
            onClick={projectFolder.archivedAt ? unarchiveProjectFolder : archiveProjectFolder}
            data-testid="folder-menu-archive-button"
          >
            {projectFolder.archivedAt ? 'i18n.common.unarchive' : 'i18n.common.archive'}
          </PopoverMenuItem>
        </TeamPermissionRestriction>

        <TeamPermissionRestriction has="FolderDelete">
          <PopoverMenuItem
            icon="TrashIcon"
            color="negative"
            onClick={deleteProjectFolder}
            data-testid="folder-menu-delete-button"
          >
            i18n.global.delete
          </PopoverMenuItem>
        </TeamPermissionRestriction>
      </>
    ),
  });

  if (!isAllowedToUpdate && !isAllowedToDelete) return null;

  return <PopoverMenuIcon data-testid="folder-menu-button" />;
};
