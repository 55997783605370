import { styled } from '@mui/material';
import { type ComponentType, type FC, Fragment } from 'react';

import { Typography } from '../../typography/Typography';
import { ProfileCircle } from '../ProfileCircle/ProfileCircle';

const SubGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '32px auto',
  gridTemplateRows: '1fr 1fr',
  columnGap: theme.spacing(2),
  alignItems: 'center',
}));

const StyledProfileCircle = styled(ProfileCircle)(() => ({
  gridRow: 'span 2',
}));

export const UserProfile: FC<{
  user: {
    firstname?: string | null | undefined;
    lastname?: string | null | undefined;
    email: string;
  };
  component?: ComponentType;
}> = ({ user, component: Component = Fragment }) => (
  <SubGrid>
    <StyledProfileCircle size="large" color="dark">
      {user.firstname ?? ''}
    </StyledProfileCircle>
    <Typography variant="h6" color="carbon">
      <Component>
        {user.firstname} {user.lastname}
      </Component>
    </Typography>
    <Typography color="grey600">{user.email}</Typography>
  </SubGrid>
);
