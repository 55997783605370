import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';

import { ADMIN_URL } from '@cofenster/constants';
import { PopoverMenuItem, Text, styled } from '@cofenster/web-components';
import { useCloneScenes } from '../../../../../api/hooks/scene/useCloneScenes';
import { useDeleteScenes } from '../../../../../api/hooks/scene/useDeleteScenes';
import { useUpdateScenes } from '../../../../../api/hooks/scene/useUpdateScenes';
import type { ThemeVideoType } from '../../../../../api/hooks/user/useMe';
import { useAddNewSceneCard } from '../../../../../components/common/AddScene/AddSceneCard';
import { useIntroOutro } from '../../../../../contexts/editorPlayer/useIntroOutro';
import { useProject } from '../../../../../contexts/project/useProject';
import { type Scene, useScenes } from '../../../../../contexts/scenes/useScenes';
import { useActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';
import { useSelectedScenes } from '../../../../../contexts/selectedScenes/useSelectedScenes';
import { useUser } from '../../../../../contexts/user/useUser';
import {
  isAudioSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../../../../helpers/sceneAssets/is';
import { useGotoProjectEditScene } from '../../../../../hooks/navigation/useGotoProjectEditScene';
import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import { useCopySceneToClipboard } from '../../../hooks/useCopySceneToClipboard';
import { usePasteSceneFromClipboard } from '../../../hooks/usePasteSceneFromClipboard';
import { useSceneInsertionIndex } from '../hooks/useSceneInsertionIndex';

const STAGE = process.env.STAGE;

const useDelete = (scene: Scene) => {
  const deleteScene = useDeleteScenes({ awaitRefetchQueries: true });
  const tracking = useWebManagerTracking();
  const actionSceneIds = useActionSceneIds(scene.id);

  const onDelete = useCallback(async () => {
    const result = await deleteScene(actionSceneIds);
    const updatedScenes = result.data?.deleteScenes;

    if (updatedScenes?.length) {
      tracking.trackEvent({
        event: 'scenesDeleted',
        details: {
          sceneIds: updatedScenes?.map((scene) => scene.id),
          source: 'edit',
        },
      });
    }
  }, [deleteScene, tracking, actionSceneIds]);

  const confirmDeleteScene = useConfirmDialog({
    title: 'i18n.dialogs.DeleteSceneDialog.headline',
    titleI18nParams: { count: actionSceneIds.length },
    content: (
      <Text i18nParams={{ count: actionSceneIds.length }} variant="l" color="grey600">
        i18n.dialogs.DeleteSceneDialog.text
      </Text>
    ),
    confirm: 'i18n.dialogs.DeleteSceneDialog.button',
  });

  const safeOnDelete = useCallback(async () => {
    if (await confirmDeleteScene()) {
      await onDelete();
    }
  }, [confirmDeleteScene, onDelete]);

  const { selectedItems } = useSelectedScenes();
  const requiresConfirmation = actionSceneIds
    .map((id) => {
      if (scene.id === id) return scene;
      return selectedItems.find((scene) => scene.id === id);
    })
    .filter((scene) => scene !== undefined)
    .some((scene) => {
      // If the scene is considered empty, or has an error, skip the confirmation
      // dialog and directly delete it
      const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);
      const audioSceneAsset = scene.sceneAssets.find(isAudioSceneAsset);

      if (!scene.instruction && !scene.title && !mainSceneAsset?.asset && !audioSceneAsset?.asset) {
        return false;
      }

      const isEmpty = !mainSceneAsset && !audioSceneAsset;

      const hasError =
        (mainSceneAsset && isVideoSceneAsset(mainSceneAsset) && mainSceneAsset.asset?.videoAsset?.status === 'Error') ||
        (mainSceneAsset && isImageSceneAsset(mainSceneAsset) && mainSceneAsset.asset?.imageAsset?.status === 'Error') ||
        audioSceneAsset?.asset?.audioAsset?.status === 'Error';

      const isEmptyOrHasError = isEmpty || hasError;

      if (!scene.instruction && !scene.title && isEmptyOrHasError) {
        return false;
      }

      return true;
    });

  return requiresConfirmation ? safeOnDelete : onDelete;
};

const useDuplicate = (scene: Scene) => {
  const actionSceneIds = useActionSceneIds(scene.id);

  const tracking = useWebManagerTracking();
  const cloneScenes = useCloneScenes({
    awaitRefetchQueries: true,
    refetchRenderDescription: true,
  });

  return useCallback(async () => {
    if (!actionSceneIds.length) return;

    const result = await cloneScenes(actionSceneIds);

    if (!result.data?.cloneScenes?.length) return;

    tracking.trackEvent({
      event: 'scenesDuplicated',
      details: {
        sceneIds: actionSceneIds,
        source: 'edit',
      },
    });
  }, [cloneScenes, actionSceneIds, tracking]);
};

const findSceneToSelect = (index: number, scenes: Scene[]) => {
  const previousScenes = scenes.slice(0, index).reverse();
  const nextScenes = scenes.slice(index + 1);
  // Check previous scenes, excluding any that are marked as excluded.
  // If there are non-excluded scenes, select the first one found (the array is reversed).
  const sceneFromPrevious = previousScenes.find((scene) => !scene.excluded);
  if (sceneFromPrevious) return sceneFromPrevious.id;
  // Check next scenes, excluding any that are marked as excluded.
  // If there are non-excluded scenes, select the first one found.
  const sceneFromNext = nextScenes.find((scene) => !scene.excluded);
  if (sceneFromNext) return sceneFromNext.id;

  return undefined;
};

type ToggleSceneFromRenderValues = {
  excluded: boolean;
  sceneId: string;
};

const useToggleSceneFromRender = () => {
  const updateScene = useUpdateScenes();

  return useCallback(
    (values: FormikValues, sceneIds: string[]) => {
      const { excluded } = values as ToggleSceneFromRenderValues;
      return updateScene(sceneIds.map((sceneId) => ({ sceneId, excluded })));
    },
    [updateScene]
  );
};

type Props = {
  scene: Scene;
};

export const CopyPopoverMenuItem: FC<Props> = ({ scene }) => {
  return (
    <PopoverMenuItem icon="ClipboardIcon" onClick={useCopySceneToClipboard(scene.id)} data-testid="scene-action-copy">
      i18n.global.copyToClipboard
    </PopoverMenuItem>
  );
};

export const PastePopoverMenuItem: FC<Props & { projectId?: string }> = ({ projectId, scene }) => {
  const { pasteScene, hasContent } = usePasteSceneFromClipboard(projectId);
  const index = useSceneInsertionIndex(scene.id);
  return hasContent ? (
    <PopoverMenuItem icon="ClipboardTextIcon" onClick={() => pasteScene(index)} data-testid="scene-action-paste">
      i18n.global.pasteFromClipboard
    </PopoverMenuItem>
  ) : null;
};

export const DuplicatePopoverMenuItem: FC<Props> = ({ scene }) => {
  const onDuplicateScene = useDuplicate(scene);

  return (
    <PopoverMenuItem icon="CopyIcon" onClick={onDuplicateScene} data-testid="scene-action-duplicate">
      i18n.common.duplicate
    </PopoverMenuItem>
  );
};

export const InsertScenePopoverMenuItem: FC<{ sceneId: string; hasContributionRequestListAvailable: boolean }> = ({
  sceneId,
  hasContributionRequestListAvailable,
}) => {
  const { project } = useProject();
  const index = useSceneInsertionIndex(sceneId);
  const projectId = project?.id ?? '';
  const openDialog = useAddNewSceneCard({
    index,
    projectId,
    videoFormat: project?.videoFormat ?? 'Horizontal',
    hasContributionRequestListAvailable,
  });

  if (!projectId) return null;

  return (
    <PopoverMenuItem icon="AddSceneIcon" onClick={openDialog} data-testid="scene-action-insert">
      i18n.projectEditor.sceneAssetThumbs.popover.actions.insertScene
    </PopoverMenuItem>
  );
};

export const DeletePopoverMenuItem: FC<Props> = ({ scene }) => {
  const onDeleteScene = useDelete(scene);

  return (
    <PopoverMenuItem icon="TrashIcon" onClick={onDeleteScene} color="negative" data-testid="scene-action-delete">
      i18n.projectEditor.sceneAssetThumbs.popover.actions.delete
    </PopoverMenuItem>
  );
};

// If multiple scenes are selected and at least one is included,
// exclude all selected scenes. If a single scene is selected, toggle
// the exclude state of the selected scene.
export const useShowExcludeDecision = (scene: Scene) => {
  const { selectedItems } = useSelectedScenes();
  const actionSceneIds = useActionSceneIds(scene.id);

  const hasAnyIncludedScene = useMemo(
    () =>
      actionSceneIds.some((sceneId) => {
        const currentScene = scene.id === sceneId ? scene : selectedItems.find((item) => item.id === sceneId);
        return currentScene?.excluded === false;
      }),
    [selectedItems, actionSceneIds, scene]
  );
  const isOnlyCurrentScene = actionSceneIds.length === 1 && actionSceneIds[0] === scene.id;

  const action = isOnlyCurrentScene
    ? scene.excluded
      ? 'include'
      : 'exclude'
    : hasAnyIncludedScene
      ? 'exclude'
      : 'include';

  return useMemo(() => ({ actionSceneIds, action }), [actionSceneIds, action]);
};

export const ToggleSceneFromVideo: FC<Props> = ({ scene }) => {
  const toggleSceneRender = useToggleSceneFromRender();
  const { actionSceneIds, action } = useShowExcludeDecision(scene);
  const { project } = useProject();
  const selectScene = useGotoProjectEditScene(project?.id, { withTimeTravel: true });
  const { scenes } = useScenes();
  const tracking = useWebManagerTracking();
  const popoverIcon = action === 'include' ? 'EyeIcon' : 'EyeSlashIcon';
  const title =
    action === 'include'
      ? 'i18n.projectEditor.sceneAssetThumbs.popover.actions.showScene'
      : 'i18n.projectEditor.sceneAssetThumbs.popover.actions.hideScene';

  const selectNotExcludedScene = useCallback(
    (sceneId: string) => {
      const index = scenes?.findIndex((scene) => sceneId === scene.id);
      const selectSceneId = findSceneToSelect(index, scenes);

      selectScene(selectSceneId);
    },
    [scenes, selectScene]
  );

  const toggleScene = useCallback(async () => {
    await toggleSceneRender({ excluded: action === 'exclude' }, actionSceneIds);

    if (!__PREVIEW_EXCLUDED_SCENES__) {
      if (!scene.excluded) selectNotExcludedScene(scene.id);
      else selectScene(scene.id);
    }

    const eventTitle = !scene.excluded ? 'scenesHidden' : 'scenesShown';

    tracking.trackEvent({
      event: eventTitle,
      details: {
        sceneIds: actionSceneIds,
        source: 'dropdown',
        sceneType: scene.type,
      },
    });
  }, [
    toggleSceneRender,
    scene.excluded,
    scene.id,
    scene.type,
    selectNotExcludedScene,
    selectScene,
    tracking,
    actionSceneIds,
    action,
  ]);

  return (
    <PopoverMenuItem icon={popoverIcon} onClick={toggleScene} data-testid="scene-action-hide-show">
      {title}
    </PopoverMenuItem>
  );
};

// If multiple scenes are selected and at least one doesn't have a
// transition, add a transition to all the scenes. If a single scene
// is selected, toggle the transition state of the selected scene.
export const useShowHideTransitionDecision = (scene: Scene) => {
  const { selectedItems } = useSelectedScenes();
  const actionSceneIds = useActionSceneIds(scene.id);

  const hasAnySceneWithoutTransition = useMemo(
    () =>
      actionSceneIds.some((sceneId) => {
        const currentScene = scene.id === sceneId ? scene : selectedItems.find((item) => item.id === sceneId);
        return currentScene?.withTransition === false;
      }),
    [selectedItems, actionSceneIds, scene]
  );
  const isOnlyCurrentScene = actionSceneIds.length === 1 && actionSceneIds[0] === scene.id;

  const action = isOnlyCurrentScene
    ? scene.withTransition
      ? 'remove'
      : 'add'
    : hasAnySceneWithoutTransition
      ? 'add'
      : 'remove';

  return useMemo(() => ({ actionSceneIds, action }), [actionSceneIds, action]);
};

export const AddTransitionPopoverMenuItem: FC<Props> = ({ scene }) => {
  const tracking = useWebManagerTracking();
  const updateScene = useUpdateScenes();

  const { actionSceneIds, action } = useShowHideTransitionDecision(scene);
  const title =
    action === 'remove'
      ? 'i18n.projectEditor.sceneAssetThumbs.popover.actions.deleteTransition'
      : 'i18n.projectEditor.sceneAssetThumbs.popover.actions.addTransition';

  const onClick = useCallback(async () => {
    await updateScene(actionSceneIds.map((sceneId) => ({ sceneId, withTransition: action === 'add' })));
    tracking.trackEvent({
      event: action === 'add' ? 'transitionsAdded' : 'transitionsDeleted',
      details: { sceneIds: actionSceneIds },
    });
  }, [action, tracking, updateScene, actionSceneIds]);

  return (
    <PopoverMenuItem icon="AddTransitionIcon" color={action === 'remove' ? 'negative' : 'carbon'} onClick={onClick}>
      {title}
    </PopoverMenuItem>
  );
};

const AdminPopoverMenuItem = styled(PopoverMenuItem)(({ theme }) => ({ color: theme.palette.brand.admin }));

export const useCanInspectAsset = (videoAssetId?: string) => {
  const { user } = useUser();
  return videoAssetId && (STAGE === 'local' || user?.isImpersonated);
};

export const InspectPopoverMenuItem: FC<{ videoAssetId?: string }> = ({ videoAssetId }) => {
  return (
    <AdminPopoverMenuItem
      icon="SearchIcon"
      href={`${ADMIN_URL}/inspect/video/${videoAssetId}`}
      target="_blank"
      component="a"
    >
      Inspect asset
    </AdminPopoverMenuItem>
  );
};

export const ToggleIntroOutroFromVideo: FC<{ themeVideoType: ThemeVideoType }> = ({ themeVideoType }) => {
  const { enable, disable, isEnabled } = useIntroOutro();

  return isEnabled(themeVideoType) ? (
    <PopoverMenuItem
      key="disable-asset"
      icon="EyeSlashIcon"
      data-testid="scene-disable-asset"
      onClick={() => disable(themeVideoType)}
    >
      i18n.projectEditor.sceneAssetThumbs.popover.actions.hideScene
    </PopoverMenuItem>
  ) : (
    <PopoverMenuItem
      key="enable-asset"
      icon="EyeIcon"
      data-testid="scene-enable-asset"
      onClick={() => enable(themeVideoType)}
    >
      i18n.projectEditor.sceneAssetThumbs.popover.actions.showScene
    </PopoverMenuItem>
  );
};
