import { type FC, useCallback } from 'react';

import { PopoverMenuItem, PopoverMenuTriggerIcon, withPopoverMenu } from '@cofenster/web-components';
import { useCurrentTeam } from '../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../contexts/dialogs';
import type { Project } from '../../contexts/project/useProject';
import { AccountPermissionRestriction } from '../../contexts/user/AccountPermissionRestriction';
import { TeamPermissionRestriction, useTeamPermissionStatus } from '../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../contexts/user/useUser';
import { useGotoProjectFolder } from '../../hooks/navigation/useGotoProjectFolder';
import { RouterLink } from '../button/RouterLink';
import { FeatureFlagRestriction } from '../featureFlag/FeatureFlagRestriction';
import { useSaveAsProjectTemplate } from '../team/Projects/PopoverMenu';

export const ProjectMenu: FC<{ project: Project }> = ({ project }) => {
  const { user } = useUser();
  const { openDialog } = useDialogs();
  const _gotoProjectFolder = useGotoProjectFolder();
  const personalFolderId = user?.rootProjectFolder.id;
  const { team } = useCurrentTeam();

  const isAllowedToEditProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const isAllowedToDeleteProject = useTeamPermissionStatus({ has: 'ProjectDelete' }) === 'ALLOWED';

  const gotoProjectFolder = useCallback(() => {
    const parentProjectFolderId = project.projectFolders[0]?.id;
    _gotoProjectFolder(parentProjectFolderId === personalFolderId ? 'personal' : parentProjectFolderId);
  }, [_gotoProjectFolder, personalFolderId, project.projectFolders]);

  const onRename = useCallback(() => {
    openDialog('RenameProjectDialog', { project, trackingSource: 'projectDetail', teamId: team?.id });
  }, [openDialog, project, team]);

  const onMove = useCallback(() => {
    const fromProjectFolder = project.projectFolders[0];
    if (!fromProjectFolder) return;

    const fromProjectFolderId = fromProjectFolder.id;
    const rootProjectFolderId = fromProjectFolder.parentFolder?.id ?? fromProjectFolder.id;
    const privateRootFolderId = fromProjectFolder.team ? undefined : rootProjectFolderId;

    openDialog('MoveProjectsDialog', {
      projectIds: [project.id],
      fromProjectFolderId,
      rootProjectFolderId,
      privateRootFolderId,
      status: project.archivedAt ? 'ARCHIVED' : 'ACTIVE',
      trackingSource: 'projectDetail',
    });
  }, [openDialog, project]);

  const onDelete = useCallback(() => {
    openDialog('DeleteProjectDialog', {
      project,
      trackingSource: 'projectDetail',
      onProjectDeleted: gotoProjectFolder,
      teamId: team?.id,
    });
  }, [openDialog, project, gotoProjectFolder, team]);

  const onCreateTemplate = useSaveAsProjectTemplate(project);

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <TeamPermissionRestriction has="ProjectUpdate">
          <PopoverMenuItem icon="PencilIcon" onClick={onRename}>
            i18n.common.rename
          </PopoverMenuItem>
          <PopoverMenuItem icon="ArrowBoxedRightIcon" onClick={onMove}>
            i18n.common.move
          </PopoverMenuItem>
        </TeamPermissionRestriction>
        <AccountPermissionRestriction has="ConsentRead">
          <FeatureFlagRestriction has="CONSENT">
            <PopoverMenuItem
              icon="FilePdfIcon"
              component={RouterLink}
              to="settingsAccountProjectConsents"
              params={{ projectId: project.id }}
            >
              i18n.common.viewConsents
            </PopoverMenuItem>
          </FeatureFlagRestriction>
        </AccountPermissionRestriction>
        <TeamPermissionRestriction has="ProjectDelete">
          <PopoverMenuItem
            icon="TrashIcon"
            color="negative"
            onClick={onDelete}
            data-testid="project-detail-popover-delete-button"
          >
            i18n.global.delete
          </PopoverMenuItem>
        </TeamPermissionRestriction>
        {__PROJECT_TEMPLATES__ && (
          <TeamPermissionRestriction has="ProjectCreate">
            <PopoverMenuItem icon="LayoutIcon" onClick={onCreateTemplate} data-testid="create-project-template-button">
              i18n.projectTemplates.createPopoverText
            </PopoverMenuItem>
          </TeamPermissionRestriction>
        )}
      </>
    ),
    transformOriginX: 'left',
    anchorOriginX: 'left',
    offsetY: 4,
  });

  if (!isAllowedToEditProject && !isAllowedToDeleteProject) return null;

  return <PopoverMenuIcon data-testid="project-detail-popover" />;
};
