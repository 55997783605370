import { isImageSceneAsset, isVideoSceneAsset } from '../../../helpers/sceneAssets/is';
import { useScenes } from '../scene/useScenes';
import type { Project } from './useProjects';

export const useProjectThumbnail = (project: Project) => {
  const thumbnailUrl = project.videoAsset?.thumbnailUrl;
  const { scenes } = useScenes(thumbnailUrl ? undefined : project.id);

  if (thumbnailUrl) {
    return thumbnailUrl;
  }

  for (const scene of scenes) {
    for (const sceneAsset of scene.sceneAssets) {
      if (!sceneAsset.asset) continue;
      if (isVideoSceneAsset(sceneAsset)) return sceneAsset.asset.videoAsset?.thumbnailUrl;
      if (isImageSceneAsset(sceneAsset)) return sceneAsset.asset.imageAsset?.imageUrl;
    }
  }
};
