import type { FC } from 'react';

import { GridContainer, GridItem, LoadingSpinner, Spacing, Typography, styled } from '@cofenster/web-components';

import { useContributionRequestsByProject } from '../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useContributionRequestListByProject } from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { RequestPermissionButton } from '../../../components/requestPermissions';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../../contexts/user/useUser';
import { AddContributionRequestCard } from '../AddContributionRequestCard';
import { SortableContributionRequestList } from './SortableContributionRequestList';
import { VideoBriefingCard } from './VideoBriefingCard';

const ContributionRequestListContainer = styled('div')(() => ({
  maxWidth: 768,
  alignSelf: 'center',
  width: '100%',
}));

export const ContributionRequestList: FC<{ projectId: string }> = ({ projectId }) => {
  const { user } = useUser();
  const { data, loading: loadingRequests } = useContributionRequestsByProject(projectId);
  const contributionRequestsByProject = data?.contributionRequestsByProject;

  const { contributionRequestLists, loading: loadingLists } = useContributionRequestListByProject(projectId);
  const defaultList = contributionRequestLists.find((list) => list.default);
  const loading = loadingRequests || loadingLists;

  if (loading || !contributionRequestsByProject || !defaultList) return <LoadingSpinner />;

  return (
    <GridContainer>
      <TeamPermissionRestriction has="ProjectUpdate">
        <GridItem xs={12}>
          <VideoBriefingCard projectId={projectId} list={defaultList} />
        </GridItem>
      </TeamPermissionRestriction>

      <GridItem xs={12}>
        <Spacing bottom={2}>
          <Typography variant="h4" component="h2">
            i18n.projectContributionConfiguration.list.requestsSection.title
          </Typography>
        </Spacing>
        <ContributionRequestListContainer>
          <SortableContributionRequestList items={data.contributionRequestsByProject} projectId={projectId} />
          <TeamPermissionRestriction
            has="ProjectUpdate"
            fallback={user && <RequestPermissionButton user={user} permission="ProjectUpdate" />}
          >
            <AddContributionRequestCard projectId={projectId} />
          </TeamPermissionRestriction>
        </ContributionRequestListContainer>
      </GridItem>
    </GridContainer>
  );
};
