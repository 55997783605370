import type { FC } from 'react';

import { Button, Icon, styled } from '@cofenster/web-components';

import { useAddNewSceneCard } from '../../../../../components/common/AddScene/AddSceneCard';
import type { Project } from '../../../../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useSceneInsertionIndex } from '../hooks/useSceneInsertionIndex';

const AddSceneButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.brand.white,
  flexWrap: 'wrap',

  '&:hover, &:focus-visible': {
    backgroundColor: theme.palette.brand.grey100,
  },
}));

const StickyHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  zIndex: theme.zIndex.top,
  backgroundColor: theme.palette.brand.white,
}));

export const SidebarHeader: FC<{
  project: Project;
  currentSceneId?: string;
}> = ({ project, currentSceneId }) => {
  const index = useSceneInsertionIndex(currentSceneId);
  const openDialog = useAddNewSceneCard({
    index,
    projectId: project.id,
    videoFormat: project.videoFormat,
    hasContributionRequestListAvailable: project.hasContributionRequestListAvailable,
  });

  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const withSceneAddition = !project.archivedAt && canUpdateProject;

  // Always return the `StickyHeader` component even if the button is not shown
  // so that the layout remains the same.
  return (
    <StickyHeader>
      {withSceneAddition && (
        <AddSceneButton
          startIcon={<Icon type="PlusIcon" size="s" />}
          type="button"
          variant="tertiary"
          fullWidth
          onClick={openDialog}
          data-testid="add-scene-button"
        >
          i18n.projectStoryboard.AddSceneCard.text
        </AddSceneButton>
      )}
    </StickyHeader>
  );
};
