import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Card,
  Form,
  FormSubmitButton,
  FormTextField,
  RemainingCharacterCounter,
  styled,
} from '@cofenster/web-components';

import { useUpdateTeam } from '../../../api/hooks/team/useUpdateTeam';
import { CardHeadline } from '../../../components/card/CardHeadline';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: theme.spacing(3.125),
}));

const StyledFormTextField = styled(FormTextField)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

type Values = {
  name: string;
};

const useInitialValues = (teamName: string | undefined) => {
  return useMemo<Values>(
    () => ({
      name: teamName ?? '',
    }),
    [teamName]
  );
};

const MAX_NAME_LENGTH = 50;

const useValidationSchema = () => {
  const validations = {
    name: Yup.string()
      .trim()
      .required('i18n.team.error.nameRequired')
      .test('len', 'i18n.team.error.nameLength', (val) => {
        if (!val) return false;

        return val.length <= MAX_NAME_LENGTH;
      }),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);

  return baseSchema;
};

const useSubmit = (teamId: string) => {
  const tracking = useWebManagerTracking();
  const updateTeam = useUpdateTeam();

  const trackTeamSpaceRenamed = useCallback(
    (team: { id: string; name: string; public: boolean }) => {
      tracking.trackEvent({
        event: 'teamSpaceRenamed',
        details: {
          teamId: team.id,
          teamName: team.name,
          teamType: team.public ? 'public' : 'private',
        },
      });
    },
    [tracking]
  );

  return useCallback(
    async (values: FormikValues) => {
      if (!teamId) return;
      const data = values as Values;

      const result = await updateTeam(data, teamId);

      if (result.data?.updateTeam.__typename === 'FieldError') {
        throw new Error('i18n.form.error.teamName.exists');
      }

      if (result.data?.updateTeam.__typename === 'Team') {
        trackTeamSpaceRenamed(result.data.updateTeam);
      }
    },
    [teamId, updateTeam, trackTeamSpaceRenamed]
  );
};

type TeamNameFormProps = {
  teamName: string;
  teamId: string;
};

export const TeamNameForm: FC<TeamNameFormProps> = ({ teamName, teamId }) => {
  const initialValues = useInitialValues(teamName);
  const validationSchema = useValidationSchema();
  const onSubmit = useSubmit(teamId);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Card>
        <CardHeadline color="carbon" variant="h4" component="h2">
          i18n.team.details
        </CardHeadline>

        <StyledFormTextField
          maxRows={2}
          id="teamName"
          name="name"
          label="i18n.team.name"
          placeholder="i18n.team.name"
          autoComplete="off"
          autoFocus
          disabled={useTeamPermissionStatus({ not: 'TeamUpdate' }) === 'ALLOWED'}
          maxLength={MAX_NAME_LENGTH}
          data-testid="team-name-input"
          inputProps={{
            'aria-describedby': 'teamNewName-counter',
          }}
          InputProps={{
            endAdornment: <RemainingCharacterCounter name="name" id="teamNewName-counter" maxChars={MAX_NAME_LENGTH} />,
          }}
        />

        <CardFooter>
          <FormSubmitButton autoDisable data-testid="team-settings-save-button">
            i18n.team.save
          </FormSubmitButton>
        </CardFooter>
      </Card>
    </Form>
  );
};
