import { type FC, useCallback, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  GridContainer,
  GridItem,
  Icon,
  IconButton,
  LoadingSpinner,
  PopoverMenuItem,
  Responsive,
  withPopoverMenu,
} from '@cofenster/web-components';

import {
  type ContributionRequest,
  useContributionRequestsByProject,
} from '../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import {
  type ContributionRequestList,
  useContributionRequestListByProject,
} from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { RouterButton } from '../../../components/button/RouterButton';
import { RouterIconButton } from '../../../components/button/RouterIconButton';
import { Redirect } from '../../../components/common/Redirect';
import { FullPageWithTitleAndActions } from '../../../components/page/FullPageWithTitleAndActions';
import { useProject } from '../../../contexts/project/useProject';
import { NoAccessLayout } from '../../NoAccess';

import { ContributionConfigurationTabs } from './ContributionConfigurationTabs';
import { CreateFirstContributionRequest } from './CreateFirstContributionRequest';
import { PreviewButton, usePreviewButtonHref } from './PreviewButton';
import { SendRequestButton, useOpenRequestRecordingsDialog } from './SendRequestButton';
import type { ContributionConfigurationTab } from './types';

type ProjectContributionConfigurationContentProps = { projectId: string; type: ContributionConfigurationTab };

export const ProjectContributionConfigurationContent: FC<ProjectContributionConfigurationContentProps> = ({
  projectId,
  type,
}) => {
  const { project, loading: loadingProject } = useProject();
  const { data, loading: loadingContributionRequests } = useContributionRequestsByProject(projectId);
  const { contributionRequestLists, loading: loadingContributionRequestLists } =
    useContributionRequestListByProject(projectId);

  const loading = loadingProject || loadingContributionRequests || loadingContributionRequestLists || !project;

  if (project?.archivedAt) {
    return <NoAccessLayout resource="Project" />;
  }

  return (
    <FullPageWithTitleAndActions
      title="i18n.projectContributionConfiguration.pageTitle"
      leftActions={[<LeftActions projectId={projectId} key="leftActions" />]}
      rightActions={type === 'initial' ? [] : [<RightActions projectId={projectId} key="rightActions" />]}
    >
      <GridItem alignSelf="stretch" display="flex" justifyContent="center" m={{ xs: 1, md: 0 }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Content
            projectId={projectId}
            type={type}
            contributionRequests={data?.contributionRequestsByProject}
            contributionRequestLists={contributionRequestLists}
            videoFormat={project.videoFormat}
          />
        )}
      </GridItem>
    </FullPageWithTitleAndActions>
  );
};

const LeftActions: FC<{ projectId: string }> = ({ projectId }) => (
  <>
    <Responsive up="md">
      <RouterButton
        to="projectEdit"
        params={{ projectId }}
        variant="tertiary"
        startIcon={<Icon type="ArrowLeftIcon" />}
        type={null}
      >
        i18n.projectContributionConfiguration.backToEditing
      </RouterButton>
    </Responsive>
    <Responsive down="sm">
      <RouterIconButton
        icon="ArrowLeftIcon"
        label="i18n.projectContributionConfiguration.backToEditing"
        to="projectEdit"
        params={{ projectId }}
      />
    </Responsive>
  </>
);

const RightActions: FC<{ projectId: string }> = ({ projectId }) => {
  const previewHref = usePreviewButtonHref(projectId);
  const onPreviewClicked = useCallback(() => window.open(previewHref, '_blank'), [previewHref]);
  const openRequestRecording = useOpenRequestRecordingsDialog();

  const MobileActions = withPopoverMenu(IconButton, {
    children: [
      <PopoverMenuItem key="preview" icon="EyeIcon" onClick={onPreviewClicked}>
        i18n.projectContributionConfiguration.preview
      </PopoverMenuItem>,
      <PopoverMenuItem key="request" icon="ShareFatIcon" onClick={openRequestRecording}>
        i18n.projectContributions.RequestRecordingsButton.label
      </PopoverMenuItem>,
    ],
  });

  return (
    <>
      <Responsive up="md">
        <GridContainer spacing={1}>
          <GridItem>
            <PreviewButton key="preview" projectId={projectId} />
          </GridItem>
          <GridItem>
            <SendRequestButton key="sendRequest" />
          </GridItem>
        </GridContainer>
      </Responsive>
      <Responsive down="sm">
        <MobileActions icon="ThreeDotsIcon" label="i18n.global.popover.toggle" />
      </Responsive>
    </>
  );
};

const Content: FC<
  ProjectContributionConfigurationContentProps & {
    contributionRequests: ContributionRequest[] | undefined;
    contributionRequestLists: ContributionRequestList[];
    videoFormat: VideoFormat;
  }
> = ({ projectId, type, contributionRequests, contributionRequestLists, videoFormat }) => {
  const defaultList = useMemo(() => contributionRequestLists?.find((list) => list.default), [contributionRequestLists]);

  if (contributionRequests?.length && type === 'initial') {
    return <Redirect to="projectContributionsConfiguration" params={{ projectId, type: 'requests' }} />;
  }

  if (contributionRequests?.length === 0 && type === 'requests' && !defaultList?.briefing.video) {
    return <Redirect to="projectContributionsConfiguration" params={{ projectId, type: 'initial' }} />;
  }

  if (type === 'initial') {
    return <CreateFirstContributionRequest projectId={projectId} />;
  }

  return <ContributionConfigurationTabs projectId={projectId} currentTab={type} videoFormat={videoFormat} />;
};
