import { type ComponentProps, type FC, useCallback, useEffect, useMemo } from 'react';

import { MultiSortable, useKeypress, useRefOfState } from '@cofenster/web-components';
import { ClickAwayListener } from '@mui/base';
import type { Scene } from '../../../../../api/hooks/scene/useScenes';
import type { Project } from '../../../../../contexts/project/useProject';
import { useSelectedScenes } from '../../../../../contexts/selectedScenes/useSelectedScenes';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useSortableScenes } from '../../../../../hooks/project/useSortableScenes';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import { useSetCurrentScene } from '../hooks/useSetCurrentScene';
import { IntroOutroThumbnail } from './IntroOutroThumbnail';
import { Thumbnail } from './Thumbnail';
import { ThumbnailsList } from './ThumbnailsList';

export type ThumbnailsProps = {
  project: Project;
  currentSceneId?: string;
};

export const Thumbnails: FC<ThumbnailsProps> = ({ project, currentSceneId }) => {
  const { scenes, sortScenes } = useSortableScenes();

  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const canReorderScenes = !project.archivedAt && scenes.length > 1 && canUpdateProject;

  const selectedScenes = useSelectedScenes();

  const isIntroOrOutro = currentSceneId === 'intro' || currentSceneId === 'outro';
  const setCurrentScene = useRefOfState(useSetCurrentScene(project.id));

  const tracking = useWebManagerTracking();
  const onTrackItemMove = useCallback(
    (scene: Scene, oldIndex: number, newIndex: number) => {
      tracking.trackEvent({
        event: 'sceneReordered',
        details: {
          sceneId: scene.id,
          sceneType: scene.type,
          source: 'edit',
          oldIndex,
          newIndex,
        },
      });
    },
    [tracking]
  );

  useEffect(() => {
    if (isIntroOrOutro) selectedScenes.clearSelection();
  }, [isIntroOrOutro, selectedScenes.clearSelection]);

  const desiredCurrentSceneId = useMemo(() => {
    const lastSelectedScene = selectedScenes.selectedItems[selectedScenes.selectedItems.length - 1];
    if (!lastSelectedScene) return currentSceneId ?? 'intro';
    return lastSelectedScene.id;
  }, [selectedScenes.selectedItems, currentSceneId]);

  useEffect(() => {
    setCurrentScene.current(desiredCurrentSceneId);
  }, [desiredCurrentSceneId, setCurrentScene]);

  useKeypress(selectedScenes.clearSelection, 'Escape');
  useKeypress(selectedScenes.selectAll, '$mod+a');

  return (
    <ClickAwayListener disableReactTree onClickAway={selectedScenes.clearSelection}>
      <ThumbnailsList aria-multiselectable={true}>
        <IntroOutroThumbnail
          project={project}
          isCurrent={currentSceneId === 'intro'}
          themeVideoType="Intro"
          onSelect={() => {
            selectedScenes.clearSelection();
            setCurrentScene.current('intro');
          }}
        />

        <MultiSortable
          items={scenes}
          multiSelect={selectedScenes}
          onSort={sortScenes}
          onTrackItemMove={onTrackItemMove}
          sortableProps={{
            canReorderScenes,
            currentSceneId,
          }}
          Sortable={Sortable}
          restrictTo="y"
        />

        <IntroOutroThumbnail
          project={project}
          isCurrent={currentSceneId === 'outro'}
          themeVideoType="Outro"
          onSelect={() => {
            selectedScenes.clearSelection();
            setCurrentScene.current('outro');
          }}
        />
      </ThumbnailsList>
    </ClickAwayListener>
  );
};

type SortableProps = ComponentProps<
  ComponentProps<typeof MultiSortable<Scene, { currentSceneId?: string; canReorderScenes: boolean }>>['Sortable']
>;

const Sortable: FC<SortableProps> = ({
  item: scene,
  isSelected,
  onSelect,
  selectionSize,
  currentSceneId,
  canReorderScenes,
}) => (
  <Thumbnail
    scene={scene}
    canReorder={canReorderScenes}
    isCurrent={scene.id === currentSceneId}
    isSelected={isSelected}
    onSelect={(event) => {
      onSelect(event);
    }}
    selectionSize={selectionSize}
  />
);
