import type { FC } from 'react';

import {
  FormCheckbox,
  FormSelectableBoxInput,
  GridContainer,
  GridItem,
  Icon,
  Typography,
  VisuallyHidden,
} from '@cofenster/web-components';

import { BlankFieldset } from './BlankFieldset';

export const DestinationStep: FC<{ sceneIds: string[] }> = ({ sceneIds }) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <BlankFieldset>
          <VisuallyHidden as="legend">i18n.dialogs.MoveScenesToProjectDialog.destination.legend</VisuallyHidden>
          <FormSelectableBoxInput
            name="destination"
            id="new-project-destination"
            data-testid="new-project-destination"
            type="radio"
            value="NEW_PROJECT"
            title="i18n.dialogs.MoveScenesToProjectDialog.destination.newProject"
            mainIcon={<Icon type="PlusCircleIcon" />}
          />
          <FormSelectableBoxInput
            name="destination"
            id="existing-project-destination"
            data-testid="existing-project-destination"
            type="radio"
            value="EXISTING_PROJECT"
            title="i18n.dialogs.MoveScenesToProjectDialog.destination.existingProject"
            mainIcon={<Icon type="MonitorPlayIcon" />}
          />
        </BlankFieldset>
      </GridItem>
      <GridItem xs={12}>
        <FormCheckbox
          id="keep-source-scenes"
          name="keepSourceScenes"
          data-testid="keep-source-scenes-switch"
          label={
            <Typography variant="l" i18nParams={{ count: sceneIds.length }}>
              i18n.dialogs.MoveScenesToProjectDialog.keepSourceScenes
            </Typography>
          }
        />
      </GridItem>
    </GridContainer>
  );
};
