import { useEffect, useMemo, useState } from 'react';

import { useUrlParameter } from '@cofenster/web-components';

import type { ProjectsFilter } from '../../api/generated';
export type { ProjectsFilter as ProjectsSearchFilters } from '../../api/generated';

export const useProjectsSearchFilters = (projectsPerPage: number) => {
  const [page, setPage] = useUrlParameter(1, 'page', String, Number);
  const [status, setStatus] = useUrlParameter<ProjectsFilter['status']>('ACTIVE', 'status');
  const [search, setSearch] = useUrlParameter<ProjectsFilter['search']>('', 'search');
  const [createdBy, setCreatedBy] = useUrlParameter<ProjectsFilter['createdBy']>('ANYONE', 'createdBy');

  const filter = useMemo(
    () => ({
      createdBy,
      status,
      search,
      limit: projectsPerPage,
      offset: Math.max(page - 1, 0) * projectsPerPage,
    }),
    [createdBy, status, search, page, projectsPerPage]
  );

  // Whenever the search parameters have been changed, reinitialize the page to
  // the first one.
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (search || status || createdBy) setPage(1);
  }, [search, createdBy, status]);

  return useMemo(
    () => ({ filter, setSearch, page, setPage, createdBy, setCreatedBy, setStatus }),
    [filter, setSearch, page, setPage, createdBy, setCreatedBy, setStatus]
  );
};

export const useProjectsSearchFiltersWithoutQueryParams = (projectsPerPage: number) => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<ProjectsFilter['status']>('ACTIVE');
  const [search, setSearch] = useState<ProjectsFilter['search']>('');
  const [createdBy, setCreatedBy] = useState<ProjectsFilter['createdBy']>('ANYONE');
  const filter = useMemo(
    () => ({
      createdBy,
      status,
      search,
      limit: projectsPerPage,
      offset: Math.max(page - 1, 0) * projectsPerPage,
    }),
    [createdBy, status, search, page, projectsPerPage]
  );

  // Whenever the search parameters have been changed, reinitialize the page to
  // the first one.
  useEffect(() => {
    if (search || status || createdBy) setPage(1);
  }, [search, createdBy, status]);

  return useMemo(
    () => ({ filter, setSearch, page, setPage, createdBy, setCreatedBy, setStatus }),
    [filter, page, createdBy]
  );
};
