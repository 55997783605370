import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
} from '@cofenster/web-components';

import type { UpdateProjectFolderMutation } from '../../../api/generated';
import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { useUpdateProjectFolder } from '../../../api/hooks/projectFolder/useUpdateProjectFolder';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n/useI18n';

type Values = {
  name: string;
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const validations = {
    name: Yup.string()
      .trim()
      .max(250, translate('form.error.generic.maxLength', { max: 250 }))
      .required('i18n.form.error.generic.required'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = ({ name }: ProjectFolder) => {
  return useMemo<Values>(
    () => ({
      name,
    }),
    [name]
  );
};

const useSubmit = (projectFolder: ProjectFolder, closeDialog: () => unknown, source: string) => {
  const updateProjectFolder = useUpdateProjectFolder();
  const tracking = useWebManagerTracking(projectFolder.team?.id);

  const trackFolderRenamed = useCallback(
    (data: UpdateProjectFolderMutation) => {
      tracking.trackEvent({
        event: 'folderRenamed',
        details: {
          teamId: data.updateProjectFolder.team?.id,
          teamName: data.updateProjectFolder.team?.name,
          folderId: data.updateProjectFolder.id,
          folderName: data.updateProjectFolder.name,
          folderType: data.updateProjectFolder.team ? 'team' : 'private',
          source,
        },
      });
    },
    [tracking, source]
  );

  return useCallback(
    async (values: FormikValues) => {
      if (!projectFolder) return;

      const data = values as Values;
      const response = await updateProjectFolder(projectFolder.id, data);

      closeDialog();

      if (response.data?.updateProjectFolder) {
        trackFolderRenamed(response.data);
      }
    },
    [updateProjectFolder, closeDialog, projectFolder, trackFolderRenamed]
  );
};

export type RenameProjectFolderDialogProps = {
  projectFolder: ProjectFolder;
  isOpen: boolean;
  closeDialog: () => unknown;
  trackingSource: 'folderCard' | 'folderDetail';
};

export const RenameProjectFolderDialog: FC<RenameProjectFolderDialogProps> = ({
  isOpen,
  closeDialog,
  projectFolder,
  trackingSource,
}) => {
  const initialValues = useInitialValues(projectFolder);
  const validationSchema = useValidationSchema();
  const onSubmit = useSubmit(projectFolder, closeDialog, trackingSource);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="folder-rename-dialog"
      title="i18n.dialogs.renameProjectFolderDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.dialogs.renameProjectFolderDialog.description
            </Text>
          </Spacing>

          <FormTextField
            id="folderNewName"
            name="name"
            label="i18n.common.name"
            placeholder="i18n.common.name"
            autoFocus
            data-testid="folder-rename-input"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth data-testid="folder-rename-dialog-button">
            i18n.dialogs.renameProjectFolderDialog.button
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
