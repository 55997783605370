import { useMemo } from 'react';

import type { FetchPolicy } from '@cofenster/api-client-apollo';

import { type ContributionRequestByIdQuery, useContributionRequestByIdQuery } from '../../generated';

export type ContributionRequestById = ContributionRequestByIdQuery['contributionRequestById'];

export const useContributionRequestsById = (id: string, fetchPolicy?: FetchPolicy) => {
  const { data, loading, error, refetch } = useContributionRequestByIdQuery({
    variables: { contributionRequestId: id },
    fetchPolicy,
  });

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refetch,
    }),
    [data, loading, error, refetch]
  );
};
