import { useCallback } from 'react';

import {
  type CreateProjectInput,
  type CreateProjectMutationVariables,
  useCreateProjectMutation,
} from '../../generated';

export type StoryInput = NonNullable<CreateProjectInput['story']>;

export const useCreateProject = () => {
  const [createProject] = useCreateProjectMutation();
  return useCallback(
    async (projectFolderId: CreateProjectMutationVariables['projectFolderId'], input: CreateProjectInput) =>
      createProject({ variables: { input, projectFolderId } }),
    [createProject]
  );
};
