import { Fragment, useCallback } from 'react';

import { PageContentContainer } from '@cofenster/web-components';

import { type SearchProjectsResponse, useSearchProjects } from '../../api/hooks/project/useSearchProjects';
import { AppLayout } from '../../components/page/Layout';
import { ListViewTypeProvider } from '../../contexts/listViewType/ListViewTypeProvider';
import { useSearchFilter } from '../../contexts/searchFilter/useSearchFilter';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

import { HomeHeader } from './HomeHeader';
import { HomeInspirations } from './HomeInspirations';
import { HomeLatestProjects } from './HomeLatestProjects';
import { HomeSearch } from './HomeSearch';

export const HomePage = () => {
  const { filter } = useSearchFilter();
  const tracking = useWebManagerTracking();
  const trackSearch = useCallback(
    ({ searchProjects }: SearchProjectsResponse) =>
      tracking.trackEvent({
        event: 'projectsViewed',
        details: {
          searchText: filter.search,
          page: searchProjects.limit ? Math.floor(searchProjects.offset / searchProjects.limit) + 1 : 1,
          totalResults: searchProjects.total,
          status: filter.status,
          resultsOnPage: searchProjects.items.length,
          folderId: 'home',
        },
      }),
    [tracking, filter.search, filter.status]
  );
  const { paginatedProjects, loading } = useSearchProjects(filter, { onCompleted: trackSearch });

  return (
    <AppLayout documentTitle="i18n.home.title" MainContentAreaComponent={Fragment}>
      <HomeHeader />

      <PageContentContainer>
        <ListViewTypeProvider id="lvt-projects">
          {filter.search ? (
            <HomeSearch loading={loading} paginatedProjects={paginatedProjects} />
          ) : (
            <>
              <HomeLatestProjects />
              <HomeInspirations />
            </>
          )}
        </ListViewTypeProvider>
      </PageContentContainer>
    </AppLayout>
  );
};
