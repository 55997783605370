import type { VideoFormat } from '@cofenster/constants';
import {
  FormError,
  FormSelect,
  FormTextField,
  GridContainer,
  GridItem,
  Icon,
  type IconType,
  SelectOption,
  Translatable,
  Typography,
  styled,
} from '@cofenster/web-components';
import type { FC } from 'react';

type VideoFormatOption = {
  value: VideoFormat;
  label: string;
};

const options: VideoFormatOption[] = [
  { value: 'Horizontal', label: 'i18n.common.horizontal' },
  { value: 'Vertical', label: 'i18n.common.vertical' },
  { value: 'Square', label: 'i18n.common.square' },
  { value: 'SocialMedia', label: 'i18n.common.socialMedia' },
];

const videoFormatIcons: Record<VideoFormat, IconType> = {
  Horizontal: 'FrameCornersIcon',
  Vertical: 'DeviceMobileCameraIcon',
  Square: 'SquareIcon',
  SocialMedia: 'DeviceTabletCameraIcon',
};

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const NewProjectSubForm: FC = () => {
  return (
    <GridContainer spacing={2}>
      <GridItem xs={12}>
        <Typography variant="m" component="p" color="grey700">
          i18n.dialogs.MoveScenesToProjectDialog.decisionStep.newProjectDescription
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <FormTextField
          autoFocus
          id="project-name"
          data-testid="project-name"
          name="projectName"
          autoComplete="name"
          label="i18n.dialogs.MoveScenesToProjectDialog.decisionStep.newProjectInputLabel"
          fullWidth
        />
        <FormSelect
          pb={0}
          name="videoFormat"
          label="i18n.common.videoFormat"
          data-testid="video-format-select"
          renderValue={(value) => {
            if (!value || typeof value !== 'string') return null;
            return (
              <Row>
                <Icon type={videoFormatIcons[value as VideoFormat]} />
                <Translatable>{options.find((option) => option.value === value)?.label ?? value}</Translatable>
              </Row>
            );
          }}
        >
          {options.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              <Translatable>{option.label}</Translatable>
            </SelectOption>
          ))}
        </FormSelect>
      </GridItem>
      <GridItem xs={12}>
        <FormError data-testid="form-submit-errors" />
      </GridItem>
    </GridContainer>
  );
};
