import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormColorPicker,
  FormToggleSwitch,
  GridContainer,
  GridItem,
  Headline,
  Spacing,
} from '@cofenster/web-components';

import { useUpdateProject } from '../../../api/hooks/project/useUpdateProject';
import { useUpdateTheme } from '../../../api/hooks/theme/useUpdateTheme';
import { BackgroundSelector } from '../../../components/branding/BackgroundSelector';
import { ThemeLogoUpload } from '../../../components/form/input/upload/ThemeLogoUpload';
import { PollingImageAsset } from '../../../components/form/input/upload/ThemeLogoUpload/PollingImageAsset';
import type { BackgroundType, Project, ProjectTheme } from '../../../contexts/project/useProject';
import type { AccountTheme } from '../../../contexts/user/useUser';

type Values = {
  colorPrimary: string;
  colorSecondary: string;
  logoAssetId: string | undefined;
  withLogo: boolean;
  background: BackgroundType;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  colorPrimary: Yup.string().trim().required('i18n.form.error.color.required'),
  colorSecondary: Yup.string().trim().required('i18n.form.error.color.required'),
  logoAssetId: Yup.string().trim(),
  withLogo: Yup.boolean().required(),
  background: Yup.mixed<BackgroundType>().required(),
});

const useValues = (theme: ProjectTheme | AccountTheme | undefined, project: Project | undefined) => {
  return useMemo(
    () => ({
      colorPrimary: theme?.colorPrimary ?? '#000000',
      colorSecondary: theme?.colorSecondary ?? '#ffffff',
      logoAssetId: theme?.logoAsset?.id || undefined,
      withLogo: project?.withLogo || false,
      background: theme?.background ?? 'Black',
    }),
    [theme?.colorPrimary, theme?.colorSecondary, theme?.logoAsset?.id, theme?.background, project?.withLogo]
  );
};

const useSubmit = (themeId: string | undefined, projectId: string | undefined) => {
  const updateTheme = useUpdateTheme();
  // Updating the theme has an impact on the preview and therefore should cause
  // a refetch of the render description.
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', 'ProjectRenderDescription'] });

  return useCallback(
    async (values: FormikValues) => {
      if (!themeId || !projectId) return;
      const { colorPrimary, colorSecondary, logoAssetId, withLogo, background } = values as Values;
      try {
        await updateTheme(themeId, { colorPrimary, colorSecondary, logoAssetId, background });
        await updateProject(projectId, { withLogo });
      } catch {
        throw new Error('i18n.projectDesign.themeForm.error');
      }
    },
    [themeId, projectId, updateTheme, updateProject]
  );
};

type Props = {
  project: Project;
};

export const DesignForm: FC<Props> = ({ project }) => {
  const projectTheme = project.theme || undefined;
  const initialValues = useValues(projectTheme, project);
  const onSubmit = useSubmit(projectTheme?.id, project.id);

  return (
    <Form
      autoSubmit
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      <Spacing bottom={2}>
        <FormToggleSwitch id="withLogo" name="withLogo" switchPosition="right" isBlock>
          <Headline variant="h4" color="grey700" component="span">
            i18n.projectDesign.themeForm.headline.logo
          </Headline>
        </FormToggleSwitch>
      </Spacing>

      <Spacing bottom={4}>
        <PollingImageAsset imageAssetId={projectTheme?.logoAsset?.id}>
          {(polledImageAsset) => (
            <ThemeLogoUpload themeId={projectTheme?.id} imageAsset={polledImageAsset} projectId={project.id} />
          )}
        </PollingImageAsset>
      </Spacing>

      <GridContainer mb={5}>
        <GridItem xs={12}>
          <Headline variant="h4" color="grey700" component="h3">
            i18n.projectDesign.themeForm.headline.colors
          </Headline>

          <GridContainer mt={-1}>
            <GridItem xs={12} md={6}>
              <FormColorPicker
                id="colorPrimary"
                name="colorPrimary"
                label="i18n.projectDesign.themeForm.colorPrimary.label"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <FormColorPicker
                id="colorSecondary"
                name="colorSecondary"
                label="i18n.projectDesign.themeForm.colorSecondary.label"
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12}>
          <Spacing bottom={1}>
            <Headline variant="h4" color="grey700" component="h3">
              i18n.projectDesign.templateForm.background
            </Headline>
          </Spacing>

          <BackgroundSelector primaryColor={project?.theme?.colorPrimary} />
        </GridItem>
      </GridContainer>
    </Form>
  );
};
