import { FormikContext, type FormikHelpers, type FormikValues, useFormik } from 'formik';
import { type FC, useCallback, useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  generatePath,
  styled,
  useGetErrorMessage,
} from '@cofenster/web-components';

import { useMoveScenesToExistingProject } from '../../../api/hooks/scene/useMoveScenesToExistingProject';
import { useMoveScenesToNewProject } from '../../../api/hooks/scene/useMoveScenesToNewProject';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n';
import { routes } from '../../../routes';
import { DestinationStep } from './DestinationStep';
import { SearchOrCreateProjectStep } from './SearchOrCreateProjectStep';
import type { MoveScenesToProjectValues } from './types';

const validationSchema = Yup.object().shape({
  destination: Yup.string().oneOf(['NEW_PROJECT', 'EXISTING_PROJECT']).required('i18n.form.error.generic.required'),
  keepSourceScenes: Yup.boolean(),
  projectName: Yup.string().when('destination', {
    is: 'NEW_PROJECT',
    // biome-ignore lint/suspicious/noThenProperty: Yup API
    then: (schema) => schema.min(3).required('i18n.form.error.generic.required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  videoFormat: Yup.string().when('destination', {
    is: 'NEW_PROJECT',
    // biome-ignore lint/suspicious/noThenProperty: Yup API
    then: (schema) => schema.required('i18n.form.error.generic.required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  targetProjectId: Yup.string().when('destination', {
    is: 'EXISTING_PROJECT',
    // biome-ignore lint/suspicious/noThenProperty: Yup API
    then: (schema) => schema.required('i18n.form.error.generic.required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const useSubmit = (sceneIds: string[], closeDialog: VoidFunction) => {
  const [moveScenesToExistingProject] = useMoveScenesToExistingProject();
  const [moveScenesToNewProject] = useMoveScenesToNewProject();
  const errorMessage = useGetErrorMessage();
  const tracking = useWebManagerTracking();

  return useCallback(
    async (values: FormikValues, { setStatus }: FormikHelpers<MoveScenesToProjectValues>) => {
      const input = values as MoveScenesToProjectValues;
      const navigationTarget = 'Cypress' in window ? '_self' : '_blank';

      try {
        if (input.destination === 'NEW_PROJECT') {
          const { data } = await moveScenesToNewProject({
            keepSourceScenes: input.keepSourceScenes,
            projectName: input.projectName,
            videoFormat: input.videoFormat,
            sceneIds,
          });
          tracking.trackEvent({
            event: 'sceneMoved',
            details: {
              destination: input.destination,
              scenesCount: sceneIds.length,
              sceneIds,
              targetProjectId: data?.moveScenesToNewProject?.project?.id,
            },
          });

          if (data?.moveScenesToNewProject) {
            window.open(
              generatePath(routes.projectEdit, { projectId: data.moveScenesToNewProject.project.id }),
              navigationTarget
            );
          }
        } else {
          await moveScenesToExistingProject({
            keepSourceScenes: input.keepSourceScenes,
            sceneIds,
            targetProjectId: input.targetProjectId,
          });
          tracking.trackEvent({
            event: 'sceneMoved',
            details: {
              destination: input.destination,
              scenesCount: sceneIds.length,
              sceneIds,
              targetProjectId: input.targetProjectId,
            },
          });
          window.open(generatePath(routes.projectEdit, { projectId: input.targetProjectId }), navigationTarget);
        }
        setStatus({ success: true, error: false });
        closeDialog();
      } catch (error) {
        setStatus({ success: false, error: errorMessage(error) });
      }
    },
    [sceneIds, closeDialog, moveScenesToExistingProject, moveScenesToNewProject, errorMessage, tracking]
  );
};

type MoveScenesToProjectDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  sceneIds: string[];
  currentProjectId: string;
};

// 1. Prevent the horizontal scrollbar from appearing when the dialog width changes.
const AnimatedWidthDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': { transition: 'width 0.4s' },
  '.MuiDialogContent-root': {
    overflowX: 'hidden', // 1
  },
}));

export const MoveScenesToProjectDialog: FC<MoveScenesToProjectDialogProps> = ({
  isOpen,
  closeDialog,
  sceneIds,
  currentProjectId,
}) => {
  const [step, setStep] = useState<'DESTINATION_STEP' | 'SEARCH_OR_CREATE_PROJECT_STEP'>('DESTINATION_STEP');
  const onSubmit = useSubmit(sceneIds, closeDialog);
  const { translate } = useI18n();

  const initialValues: MoveScenesToProjectValues = {
    destination: 'NEW_PROJECT',
    keepSourceScenes: false,
    projectName: translate('projectCreate.form.name.defaultValue'),
    videoFormat: 'Horizontal',
  };

  const formikContext = useFormik<MoveScenesToProjectValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const { isSubmitting, submitForm, values, setStatus } = formikContext;

  const goToDestination = useCallback(() => {
    setStep('DESTINATION_STEP');
    setStatus({ success: false, error: false });
  }, [setStatus]);
  const goToSearchOrCreateProjectStep = useCallback(() => setStep('SEARCH_OR_CREATE_PROJECT_STEP'), []);

  const primaryOnClick = step === 'DESTINATION_STEP' ? goToSearchOrCreateProjectStep : submitForm;
  const primaryText = step === 'DESTINATION_STEP' ? 'i18n.global.continue' : 'i18n.common.move';

  const secondaryOnClick = step === 'SEARCH_OR_CREATE_PROJECT_STEP' ? goToDestination : closeDialog;
  const secondaryText = step === 'SEARCH_OR_CREATE_PROJECT_STEP' ? 'i18n.global.back' : 'i18n.global.cancel';

  const noProjectSelected =
    step === 'SEARCH_OR_CREATE_PROJECT_STEP' && values.destination === 'EXISTING_PROJECT' && !values.targetProjectId;

  return (
    <AnimatedWidthDialog
      open={isOpen}
      onClose={closeDialog}
      title="i18n.dialogs.MoveScenesToProjectDialog.title"
      size={step === 'DESTINATION_STEP' || values.destination === 'NEW_PROJECT' ? 's' : 'l'}
      leftIcon={
        step === 'SEARCH_OR_CREATE_PROJECT_STEP' && (
          <IconButton icon="ArrowLeftIcon" label="i18n.global.back" onClick={goToDestination} />
        )
      }
      scroll="paper"
    >
      <DialogContent data-testid="move-scenes-to-project-form">
        <FormikContext.Provider value={formikContext}>
          <form onSubmit={submitForm}>
            {step === 'DESTINATION_STEP' ? (
              <DestinationStep sceneIds={sceneIds} />
            ) : (
              <SearchOrCreateProjectStep currentProjectId={currentProjectId} />
            )}
          </form>
        </FormikContext.Provider>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" onClick={secondaryOnClick} style={{ marginLeft: 'auto' }}>
          {secondaryText}
        </Button>
        <Button
          onClick={primaryOnClick}
          loading={isSubmitting}
          data-testid="move-scenes-to-project-next-or-submit"
          disabled={noProjectSelected}
        >
          {primaryText}
        </Button>
      </DialogActions>
    </AnimatedWidthDialog>
  );
};
