import { styled } from '@mui/material';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { Typography } from '../../typography/Typography';

import type { SelectableBoxInputProps } from './types';

const HiddenInput = styled('input')(({ theme }) => ({
  appearance: 'none',
  position: 'absolute',
  inset: 0,
  opacity: 0,
  cursor: 'pointer',

  '&:checked + *': {
    color: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue50,
  },

  '&:focus-visible + *': theme.mixins.focusRing,
  '&:not(:disabled):hover + *': { borderColor: theme.palette.brand.grey600 },
  '&:checked:hover + *': { borderColor: theme.palette.brand.blue },

  '&:disabled': { cursor: 'not-allowed' },
  '&:disabled + *': { color: theme.palette.brand.grey600 },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey200}`,
  transition: '250ms border-color',
}));

const CheckedIcon = styled(Icon)(() => ({ marginLeft: 'auto', flexShrink: 0 }));

const Label = styled('label')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  margin: theme.spacing(1, 0),
}));

export const SelectableBoxInput: FC<SelectableBoxInputProps> = ({
  id,
  checkedIcon = 'CheckIcon' as const,
  title,
  description,
  mainIcon,
  secondaryIcon,
  ...inputProps
}) => {
  return (
    <>
      <Label htmlFor={id}>
        <HiddenInput id={id} {...inputProps} />
        <ContentContainer>
          {mainIcon}
          <div>
            <Typography variant="h6" component="p">
              {title}
            </Typography>
            <Typography component="p">{description}</Typography>
          </div>
          <CheckedIcon type={checkedIcon} style={{ visibility: inputProps.checked ? 'visible' : 'hidden' }} />
        </ContentContainer>
      </Label>
    </>
  );
};
