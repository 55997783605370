import {
  Button,
  DialogActions,
  DialogContent,
  Form,
  FormCheckbox,
  FormSubmitButton,
  GridContainer,
  GridItem,
  Icon,
  Tooltip,
  Translatable,
  Typography,
  VisuallyHidden,
  styled,
  useSnackbars,
} from '@cofenster/web-components';
import { type FormikValues, useFormikContext } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import type { Project } from '../../../api/hooks/project/useProject';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';
import { useUpdateSharingLinkSettings } from '../../../api/hooks/sharingLink/useUpdateSharingLinkSettings';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n';

type FormValues = {
  exportedLanguages: string[];
};

const useSubmit = (sharingLinkId: string, closeDialog: VoidFunction) => {
  const tracking = useWebManagerTracking();
  const updateSharingLinkSettings = useUpdateSharingLinkSettings();
  const { openSnackbar } = useSnackbars();
  return useCallback(
    async (formikValues: FormikValues) => {
      const values = formikValues as FormValues;
      await updateSharingLinkSettings(sharingLinkId, { exportedLanguages: values.exportedLanguages });
      closeDialog();
      openSnackbar({
        variant: 'success',
        children: 'i18n.SharingLinkSettingsDialog.successSnackbar',
      });
      tracking.trackEvent({
        event: 'SharingLinkSettingsUpdated',
        details: {
          sharingLink: sharingLinkId,
          exportedLanguages: values.exportedLanguages,
        },
      });
    },
    [sharingLinkId, closeDialog, openSnackbar, tracking, updateSharingLinkSettings]
  );
};

const Fieldset = styled('fieldset')(() => ({
  border: 0,
  padding: 0,
  margin: 0,
}));

export const SharingLinkSettingsForm: FC<{
  closeDialog: () => unknown;
  sharingLink: NonNullable<Project['sharingLink']>;
  languages: string[];
  renderJobs: RenderJob[];
}> = ({ closeDialog, sharingLink, languages, renderJobs }) => {
  const initialValues = useMemo(
    () => ({ exportedLanguages: sharingLink.exportedLanguages ?? [] }),
    [sharingLink.exportedLanguages]
  );
  const onSubmit = useSubmit(sharingLink.id, closeDialog);

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <DialogContent>
        <GridContainer>
          <GridItem>
            <Typography variant="h5" component="h3">
              i18n.SharingLinkSettingsDialog.availableSubtitlesSection
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Fieldset>
              <VisuallyHidden as="legend">
                <Translatable>i18n.SharingLinkSettingsDialog.subtitleLanguagesLegend</Translatable>
              </VisuallyHidden>
              <GridContainer spacing={1.5}>
                {languages.map((language) => (
                  <GridItem key={language} xs={12} display="flex" gap={2}>
                    <LanguageCheckboxRow language={language} renderJobs={renderJobs} />
                  </GridItem>
                ))}
              </GridContainer>
            </Fieldset>
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" onClick={closeDialog} fullWidth>
          i18n.global.cancel
        </Button>
        <FormSubmitButton variant="primary" fullWidth>
          i18n.global.save
        </FormSubmitButton>
      </DialogActions>
    </Form>
  );
};

const IconContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const LanguageCheckboxRow: FC<{ language: string; renderJobs: RenderJob[] }> = ({ language, renderJobs }) => {
  const { languageNames } = useI18n();
  const renderJob = renderJobs.find((job) => job.subtitlesLanguage === language);
  const { values } = useFormikContext<FormValues>();
  const isLanguageChecked = values.exportedLanguages?.includes(language);

  return (
    <>
      <FormCheckbox
        id={language}
        label={languageNames.of(language)}
        name="exportedLanguages"
        value={language}
        labelProps={{ color: renderJob?.status !== 'Done' && isLanguageChecked ? 'grey700' : undefined }}
      />
      {renderJob?.status !== 'Done' && isLanguageChecked && (
        <Tooltip title="i18n.SharingLinkSettingsDialog.notExportedYet">
          <IconContainer>
            <Icon size="m" type="InfoIcon" />
          </IconContainer>
        </Tooltip>
      )}
    </>
  );
};
