import { type FormikValues, useField } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  Icon,
  Spacing,
  Text,
  styled,
} from '@cofenster/web-components';

import { useCreateSceneSubtitleForAllScenesInLanguage } from '../../../api/hooks/sceneSubtitle/useCreateSceneSubtitleForAllScenesInLanguage';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { FormTypeSelect } from '../../subtitle/scene/FormTypeSelect';
import { FromSRTSubForm } from '../../subtitle/scene/FromSRTSubForm';
import { type LanguageGroup, LanguageSelect } from '../../subtitle/scene/LanguageSelect';
import { TranslationQualityHint } from '../../subtitle/scene/TranslationQualityHint';

type FormType = 'AUTOMATIC' | 'FROM_SRT' | 'MANUAL';

const validationSchema = Yup.object().shape({
  language: Yup.string().trim().required('i18n.form.error.generic.required'),
  formType: Yup.mixed<FormType>().oneOf(['AUTOMATIC', 'FROM_SRT', 'MANUAL']).required(),
  srtContent: Yup.string().when('formType', {
    is: 'FROM_SRT',
    // biome-ignore lint/suspicious/noThenProperty: Yup API
    then: (schema) => schema.required('i18n.sceneSubtitle.forms.srtContentRequired'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
});

type Values = Yup.InferType<typeof validationSchema>;

const useSubmit = (projectId: string, closeDialog: VoidFunction, onAfterAction: (language: string) => void) => {
  const { createSceneSubtitleForAllScenesInLanguage } = useCreateSceneSubtitleForAllScenesInLanguage();
  const tracking = useWebManagerTracking();

  const trackLanguageAdded = useCallback(
    (language: string) => {
      tracking.trackEvent({
        event: 'sceneSubtitleLanguageAdded',
        details: {
          projectId,
          language,
        },
      });
    },
    [tracking, projectId]
  );

  return useCallback(
    async (values: FormikValues) => {
      const data = values as Values;
      await createSceneSubtitleForAllScenesInLanguage({
        projectId,
        language: data.language,
        srt: data.formType === 'FROM_SRT' ? data.srtContent : data.formType === 'MANUAL' ? '' : null,
      });
      closeDialog();
      onAfterAction(data.language);
      trackLanguageAdded(data.language);
    },
    [createSceneSubtitleForAllScenesInLanguage, projectId, closeDialog, trackLanguageAdded, onAfterAction]
  );
};

export type SceneSubtitlesAddLanguageDialogProps = {
  projectId: string;
  initialLanguage: string;
  languageOptionGroups: LanguageGroup[];
  isOpen: boolean;
  closeDialog: VoidFunction;
  onAfterAction: (language: string) => void;
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

export const SceneSubtitlesAddLanguageDialog: FC<SceneSubtitlesAddLanguageDialogProps> = ({
  isOpen,
  closeDialog,
  projectId,
  initialLanguage,
  languageOptionGroups,
  onAfterAction,
}) => {
  const onSubmit = useSubmit(projectId, closeDialog, onAfterAction);

  const initialValues: Values = {
    language: initialLanguage,
    formType: 'AUTOMATIC',
    srtContent: undefined,
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.sceneSubtitles.addLanguage.headline">
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Container>
          <ContentComponent languageOptionGroups={languageOptionGroups} />
          <TranslationQualityHint />
        </Container>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton fullWidth>i18n.dialogs.sceneSubtitles.addLanguage.submitButton</FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

const ContentComponent: FC<{ languageOptionGroups: LanguageGroup[] }> = ({ languageOptionGroups }) => {
  const [{ value }] = useField({ name: 'formType' });
  return (
    <DialogContent>
      <Spacing bottom={3}>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.sceneSubtitles.addLanguage.description
        </Text>
      </Spacing>

      <LanguageSelect name="language" languageGroups={languageOptionGroups} placeholder="i18n.common.language" />
      <FormTypeSelectWrapper />
      {value === 'FROM_SRT' && <FromSRTSubForm />}
    </DialogContent>
  );
};

const FormTypeSelectWrapper: FC = () => {
  const options = useMemo(
    () => [
      {
        value: 'AUTOMATIC',
        title: 'i18n.sceneSubtitle.translateForm.fromOtherScene.title',
        description: 'i18n.sceneSubtitle.translateForm.fromOtherScene.description',
        mainIcon: <Icon type="SparkleIcon" />,
        testId: 'scene-subtitle-form-type-automatic',
      },
      {
        value: 'MANUAL',
        title: 'i18n.sceneSubtitle.translateForm.manual.title',
        description: 'i18n.sceneSubtitle.translateForm.manual.description',
        mainIcon: <Icon type="PencilIcon" />,
        testId: 'scene-subtitle-form-type-manual',
      },
      {
        value: 'FROM_SRT',
        title: 'i18n.sceneSubtitle.translateForm.fromSRT.title',
        description: 'i18n.sceneSubtitle.translateForm.fromSRT.description',
        mainIcon: <Icon type="UploadIcon" />,
        testId: 'scene-subtitle-form-type-srt-upload',
      },
    ],
    []
  );

  return <FormTypeSelect name="formType" options={options} />;
};
