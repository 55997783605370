import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useIntroOutro } from '../../../../../contexts/editorPlayer/useIntroOutro';
import { useProject } from '../../../../../contexts/project/useProject';
import { useScenes } from '../../../../../contexts/scenes/useScenes';
import { useGotoProjectEditScene } from '../../../../../hooks/navigation/useGotoProjectEditScene';
import type { ProjectEditorRouteParams } from '../../../../../routes';

export const useRedirectToScene = () => {
  const { projectId, sceneId } = useParams() as ProjectEditorRouteParams;
  const { loading } = useProject();
  const { scenes, loading: scenesLoading } = useScenes();
  const gotoProjectEditScene = useGotoProjectEditScene(projectId, { replace: true, withTimeTravel: true });
  const { isUploadedAndEnabled } = useIntroOutro();
  const includedScenes = useMemo(
    () => scenes.filter((scene) => (__PREVIEW_EXCLUDED_SCENES__ ? true : !scene.excluded)),
    [scenes]
  );
  const anyLoading = loading || scenesLoading;

  // Auto redirect to the first valid scene
  useEffect(() => {
    // If the scene is loaded from the URL params, there is no need to perform
    // any redirect
    if (sceneId) return;

    // If the project or the scenes are loading, do not redirect yet as the data
    // is not ready
    if (anyLoading) return;

    // If there are no scenes or all scenes are excluded, the wizard and not the
    // actual player is displayed, so no need for a redirect either
    const firstScene = includedScenes[0];
    if (!firstScene) return;

    // If there is an intro, simply go to the intro scene
    if (isUploadedAndEnabled('Intro')) return gotoProjectEditScene('intro');

    // Otherwise go to the first scene
    gotoProjectEditScene(firstScene.id);
  }, [sceneId, isUploadedAndEnabled, gotoProjectEditScene, anyLoading, includedScenes]);
};
