import { type CSSProperties, type FC, type MouseEvent as ReactMouseEvent, useCallback, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  Button,
  Icon,
  IconButton,
  NativeVideoPlayer,
  PopoverMenuItem,
  SpinningIcon,
  Tooltip,
  Typography,
  preventForwardProps,
  styled,
  useI18n,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { Contributor } from '../../../../api/generated';
import type { ContributionRequestById } from '../../../../api/hooks/contributionRequest/useContributionRequestsById';
import { useCreateSceneFromContributionRequestAsset } from '../../../../api/hooks/contributionRequest/useCreateSceneFromContributionRequestAsset';
import { useDeleteAssetFromContributionRequest } from '../../../../api/hooks/contributionRequest/useDeleteAssetFromContributionRequest';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

type VideoAsset = ContributionRequestById['videoAssets'][number];
type ImageAsset = ContributionRequestById['imageAssets'][number];

const isVideoAsset = (asset: VideoAsset | ImageAsset): asset is VideoAsset => {
  return asset.__typename === 'VideoAsset';
};

export const ContributionAssetListItem: FC<{
  asset: VideoAsset | ImageAsset;
  videoFormat: VideoFormat;
  contributionRequestId: string;
  contributionRequestTitle: string;
  scenesCount: number;
  contributors?: Contributor[];
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
  canUpdateProject: boolean;
}> = ({
  asset,
  videoFormat,
  contributionRequestId,
  contributionRequestTitle,
  scenesCount,
  contributors = [],
  onSceneCreated,
  newSceneIndex,
  canUpdateProject,
}) => {
  return (
    <>
      <ContributionAssetContainer>
        {isVideoAsset(asset) ? (
          <VideoContribution
            asset={asset}
            newSceneIndex={newSceneIndex}
            videoFormat={videoFormat}
            contributionRequestId={contributionRequestId}
            onSceneCreated={onSceneCreated}
          />
        ) : (
          <ImageContribution
            asset={asset}
            newSceneIndex={newSceneIndex}
            videoFormat={videoFormat}
            contributionRequestId={contributionRequestId}
            onSceneCreated={onSceneCreated}
          />
        )}
      </ContributionAssetContainer>
      <ContributionLabel
        asset={asset}
        contributors={contributors}
        contributionRequestId={contributionRequestId}
        contributionRequestTitle={contributionRequestTitle}
        scenesCount={scenesCount}
        canUpdateProject={canUpdateProject}
      />
    </>
  );
};

const ContributionAssetContainer = styled('div')(() => ({
  position: 'relative',
  '&:hover > div, &:focus-within > div': {
    opacity: 1,
  },
}));

const Video = styled(
  NativeVideoPlayer,
  preventForwardProps(['assetReady'])
)<{ assetReady: boolean }>(({ theme, assetReady }) => ({
  aspectRatio: 'var(--aspect-ratio)',
  backgroundColor: assetReady ? '#000' : theme.palette.brand.linen50,
}));

const AddContributionToProjectButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
}));

const LoadingAssetContainer = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const VideoContribution: FC<{
  asset: VideoAsset;
  videoFormat: VideoFormat;
  contributionRequestId: string;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
}> = ({ asset, videoFormat, contributionRequestId, onSceneCreated, newSceneIndex }) => {
  const format = VIDEO_FORMATS[videoFormat];
  const [hasControls, setHasControls] = useState(false);

  const { createSceneFromContributionRequestAsset, loading } = useCreateSceneFromContributionRequestAsset();
  const onAddVideo = useCallback(async () => {
    const { data } = await createSceneFromContributionRequestAsset(contributionRequestId, {
      assetId: asset.id,
      index: newSceneIndex,
    });
    if (onSceneCreated) {
      const sceneId = data?.createSceneFromContributionRequestAsset?.id;
      if (sceneId) {
        onSceneCreated(sceneId);
      }
    }
  }, [createSceneFromContributionRequestAsset, asset, contributionRequestId, onSceneCreated, newSceneIndex]);

  return (
    <>
      <Video
        assetReady={asset.status !== 'Ready'}
        src={asset.videoUrl ?? undefined}
        poster={asset.thumbnailUrl ?? undefined}
        style={{ '--aspect-ratio': String(format.aspectRatio) } as CSSProperties}
        controls={hasControls}
        actions={['FULLSCREEN']}
        objectFit="contain"
        tabIndex={0}
        onFocus={() => setHasControls(true)}
        onBlur={() => setHasControls(false)}
        onMouseEnter={(event: ReactMouseEvent<HTMLVideoElement, MouseEvent> & { target: HTMLVideoElement }) =>
          event.target.focus()
        }
        onMouseLeave={(event: ReactMouseEvent<HTMLVideoElement, MouseEvent> & { target: HTMLVideoElement }) =>
          event.target.blur()
        }
      />
      {asset.status === 'Ready' ? (
        <AddContributionToProjectButtonContainer>
          <Button variant="blurred" onClick={onAddVideo} loading={loading}>
            i18n.ContributionRequestsDialog.addToVideoButton
          </Button>
        </AddContributionToProjectButtonContainer>
      ) : (
        <LoadingAssetContainer>
          <SpinningIcon type="GearIcon" color="carbon" />
        </LoadingAssetContainer>
      )}
    </>
  );
};

const Image = styled(
  'img',
  preventForwardProps(['assetReady'])
)<{ assetReady: boolean }>(({ theme, assetReady }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.shape['borderRadius-l'],
  aspectRatio: 'var(--aspect-ratio)',
  objectFit: 'contain',
  backgroundColor: assetReady ? '#000' : theme.palette.brand.linen50,
}));

const ImageContribution: FC<{
  asset: ImageAsset;
  videoFormat: VideoFormat;
  contributionRequestId: string;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
}> = ({ asset, videoFormat, contributionRequestId, onSceneCreated, newSceneIndex }) => {
  const { translate } = useI18n();
  const { aspectRatio } = VIDEO_FORMATS[videoFormat];

  const { createSceneFromContributionRequestAsset, loading } = useCreateSceneFromContributionRequestAsset();
  const onAddImage = useCallback(async () => {
    const { data } = await createSceneFromContributionRequestAsset(contributionRequestId, {
      assetId: asset.id,
      index: newSceneIndex,
    });
    const sceneId = data?.createSceneFromContributionRequestAsset?.id;
    if (sceneId) onSceneCreated?.(sceneId);
  }, [createSceneFromContributionRequestAsset, asset, contributionRequestId, onSceneCreated, newSceneIndex]);

  return (
    <>
      <Image
        assetReady={asset.status !== 'Ready'}
        src={asset.imageUrl ?? undefined}
        style={{ '--aspect-ratio': String(aspectRatio) } as CSSProperties}
        alt={translate('ContributionRequestsDialog.imageContribution.alt', {
          date: new Date(asset.createdAt).toLocaleString(),
        })}
      />
      {asset.status === 'Ready' ? (
        <AddContributionToProjectButtonContainer>
          <Button variant="blurred" onClick={onAddImage} loading={loading}>
            i18n.ContributionRequestsDialog.addToVideoButton
          </Button>
        </AddContributionToProjectButtonContainer>
      ) : (
        <LoadingAssetContainer>
          <SpinningIcon type="GearIcon" color="carbon" />
        </LoadingAssetContainer>
      )}
    </>
  );
};

const ContributionLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

const CenteredChildrenContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RightColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ContributionLabel: FC<{
  asset: VideoAsset | ImageAsset;
  contributors?: Contributor[];
  contributionRequestId: string;
  contributionRequestTitle: string;
  scenesCount: number;
  canUpdateProject: boolean;
}> = ({ asset, contributors = [], contributionRequestId, contributionRequestTitle, scenesCount, canUpdateProject }) => {
  return (
    <ContributionLabelContainer>
      <div>
        <TwoLinesTypography variant="h6" component="p">
          {contributionRequestTitle}
        </TwoLinesTypography>

        {contributors.length > 0 && (
          <Typography variant="m" component="p">
            {contributors.map((it) => it.name).join(', ')}
          </Typography>
        )}
      </div>
      <RightColumn>
        {!!asset?.scenes?.length && (
          <Tooltip title="i18n.ContributionRequestsDialog.includedTooltip">
            {/* Without container for the icon tooltip does not work */}
            <CenteredChildrenContainer>
              <Icon tabIndex={0} type="CheckIcon" background={{ size: 's', color: 'blue' }} color="white" size="xs" />
            </CenteredChildrenContainer>
          </Tooltip>
        )}
        {canUpdateProject && (
          <MoreOptions contributionRequestId={contributionRequestId} asset={asset} scenesCount={scenesCount} />
        )}
      </RightColumn>
    </ContributionLabelContainer>
  );
};

const MoreOptions: FC<{
  contributionRequestId: string;
  scenesCount: number;
  asset: VideoAsset | ImageAsset;
}> = ({ contributionRequestId, asset, scenesCount }) => {
  const { deleteAssetFromContributionRequest } = useDeleteAssetFromContributionRequest(contributionRequestId, asset.id);
  const openConfirmDialog = useConfirmDialog({
    title: 'i18n.ContributionRequestsDialog.deleteAssetConfirmation.title',
    content: (
      <Typography variant="m" i18nParams={{ scenesCount }}>
        {scenesCount === 0
          ? 'i18n.ContributionRequestsDialog.deleteAssetConfirmation.description.noScenes'
          : 'i18n.ContributionRequestsDialog.deleteAssetConfirmation.description'}
      </Typography>
    ),
    onConfirm: async () => {
      await deleteAssetFromContributionRequest();
    },
    confirm: 'i18n.global.delete',
  });

  const downloadUrl = asset?.downloadUrl;
  const tracking = useWebManagerTracking();
  const trackDownload = useCallback(() => {
    tracking.trackEvent({
      event: 'requestAssetDownloaded',
      details: {
        requestId: contributionRequestId,
        requestAssetId: asset.id,
      },
    });
  }, [tracking, contributionRequestId, asset]);

  const MoreOptionsButton = withPopoverMenu(IconButton, {
    children: [
      downloadUrl && (
        <PopoverMenuItem
          icon="DownloadIcon"
          href={downloadUrl}
          target="_blank"
          download
          data-testid="contribution-recording-download-asset"
          onClick={trackDownload}
          component="a"
          key="download-asset"
        >
          i18n.global.download
        </PopoverMenuItem>
      ),
      <PopoverMenuItem key="delete" icon="TrashIcon" color="negative" onClick={() => openConfirmDialog()}>
        i18n.global.delete
      </PopoverMenuItem>,
    ].filter(Boolean),
  });

  return <MoreOptionsButton icon="ThreeDotsIcon" label="" iconSize="m" onClick={() => {}} />;
};

const TwoLinesTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
}));
