import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, Spacing } from '@cofenster/web-components';

import { ProjectLayout } from '../../components/page/Layout/ProjectLayout';
import { useProject } from '../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import { useProjectEditingHeader } from '../../hooks/useProjectHeader';
import type { ProjectRouteParams } from '../../routes';
import { NoAccessContent, NoAccessLayout } from '../NoAccess';

import { ProjectTemplateForm } from './ProjectTemplateForm';
import { ProjectThemeForm } from './ProjectThemeForm';

export const ProjectDesignContent: FC = () => {
  const { projectId } = useParams() as ProjectRouteParams;
  const { project, loading } = useProject();
  const projectHeader = useProjectEditingHeader(projectId);

  return (
    <TeamPermissionRestriction has="ProjectUpdate" fallback={<NoAccessLayout />} loader={<LoadingSpinner />}>
      <ProjectLayout documentTitle={project?.name ?? ''} header={projectHeader}>
        {project ? (
          <>
            <Card>
              <ProjectThemeForm project={project} />
            </Card>
            <Spacing top={3}>
              <Card>
                <ProjectTemplateForm project={project} />
              </Card>
            </Spacing>
          </>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <NoAccessContent resource="Project" />
        )}
      </ProjectLayout>
    </TeamPermissionRestriction>
  );
};
