import { useField, useFormikContext } from 'formik';
import type { FC } from 'react';

import type { I18nParams } from '../../../services/i18n';
import { Spacing } from '../../layout/Spacing';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  name: string;
  i18nParams?: I18nParams;
};

export const FieldError: FC<Props> = ({ name, ...rest }) => {
  const [, meta] = useField(name);
  const { validateOnMount } = useFormikContext();
  if (meta.error === undefined) return null;
  if (!validateOnMount && !meta.touched) return null;

  return (
    <Spacing top={1}>
      <ErrorMessage {...rest}>{meta.error}</ErrorMessage>
    </Spacing>
  );
};
