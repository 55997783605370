import { useMultiSelect } from '@cofenster/web-components';
import type { FC, PropsWithChildren } from 'react';
import { useScenes } from '../scenes/useScenes';
import { SelectedScenesContext } from './SelectedScenesContext';

export const SelectedScenesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { scenes, currentScene } = useScenes();

  const context = useMultiSelect(scenes, currentScene ? [currentScene] : []);

  return <SelectedScenesContext.Provider value={context}>{children}</SelectedScenesContext.Provider>;
};
