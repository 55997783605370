import { type CSSProperties, type FC, type PropsWithChildren, useCallback } from 'react';

import { PlayerControls, styled, useKeypress, usePlayPauseKeyboardHandler } from '@cofenster/web-components';
import { useRemotionPlayerCurrentTime } from '@cofenster/web-remotion-player';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';
import type { SceneTimings } from '../../../../contexts/editorPlayer/usePlayerScenes';
import { useRemotionPlayerRef } from '../../../../contexts/editorPlayer/useRemotionPlayerRef';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';
import { usePreviewDetails } from '../../hooks/usePreviewDetails';

// Frames per second, usually get from the render description.
// This value is the same for all the projects
const FPS = 30;

const ControlsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  zIndex: theme.zIndex.above,
}));

export type RemotionPlayerControlsProps = {
  projectId: string;
  Wrapper?: FC<PropsWithChildren>;
};

type TrackMarkersOptions = {
  trackColor?: string;
  precision?: number;
};

const useTrackMarkers = (
  sceneTimings: SceneTimings[],
  { trackColor = '#fff', precision = 2 }: TrackMarkersOptions = {}
) => {
  const markers: string[] = [];
  const last = sceneTimings[sceneTimings.length - 1];
  const duration = last?.end;

  // Return nothing, and not `none`, as we want the default value from the CSS
  // custom property to be applied.
  if (!duration || sceneTimings.length === 1) {
    return;
  }

  sceneTimings
    .map((sceneTiming) => (sceneTiming.end / duration) * 100)
    // Discard scenes that are so short that they would basically not appear on
    // the timeline, and risk in fact causing the gradient approach to fail.
    .filter((percent) => percent >= 2.5)
    .forEach((percent) => {
      const delta = 0.5;

      // Marker start
      const start = (percent - delta).toFixed(precision);
      markers.push(`${trackColor} ${start}%`);
      markers.push('#0000 0');

      // Marker end
      const end = (percent + delta).toFixed(precision);
      markers.push(`#0000 ${end}%`);
      markers.push(`${trackColor} 0`);
    });

  return `linear-gradient(to right, ${markers.join(',')})`;
};

export const EditorPlayerControls: FC<RemotionPlayerControlsProps> = ({ projectId, Wrapper = ControlsWrapper }) => {
  const {
    play,
    pause,
    paused,
    setCurrentTime,
    duration,
    isVideoBuffering,
    isDraggingControls,
    setIsDraggingControls,
    sceneTimings,
    hasPreviewLoadingError,
  } = useEditorPlayer();
  const trackMarkers = useTrackMarkers(sceneTimings);

  const getPreviewDetails = usePreviewDetails();
  const tracking = useWebManagerTracking();

  const onClick = useCallback(() => {
    tracking.trackEvent({
      event: 'ScenePreviewTimelineSelected',
      details: getPreviewDetails(),
    });
  }, [tracking, getPreviewDetails]);

  // For the track handle specifically, we prefer a per-frame update so it looks
  // smooth even on short videos, as the `timeupdate` event is “only” fired
  // every 250ms.
  const { playerRef } = useRemotionPlayerRef();
  const preciseCurrentTime = useRemotionPlayerCurrentTime(playerRef, {
    eventType: 'frameupdate',
    frameUpdateThrottle: 0,
  });

  usePlayPauseKeyboardHandler(paused, play, pause);
  useGoFrameKeyboardHandler(preciseCurrentTime);

  return duration ? (
    <Wrapper onClick={onClick} style={{ '--preview-track-markers': trackMarkers } as CSSProperties}>
      <PlayerControls
        size="s"
        play={play}
        pause={pause}
        currentTime={preciseCurrentTime}
        duration={duration}
        // The paused prop is not really used in this case
        // Since we are hiding the play/pause actions
        paused={paused}
        hideActions
        goto={setCurrentTime}
        playButtonDisabled={hasPreviewLoadingError}
        projectId={projectId}
        isDragging={isDraggingControls}
        setIsDragging={setIsDraggingControls}
        disabled={hasPreviewLoadingError || isVideoBuffering}
      />
    </Wrapper>
  ) : null;
};

const useGoFrameKeyboardHandler = (currentTime: number) => {
  const { setCurrentTime, duration } = useEditorPlayer();

  const handleGoToNextFrame = useCallback(() => {
    setCurrentTime(Math.min(duration, currentTime + 1 / FPS));
  }, [setCurrentTime, currentTime, duration]);
  const handleGoToPrevFrame = useCallback(() => {
    setCurrentTime(Math.max(0, currentTime - 1 / FPS));
  }, [setCurrentTime, currentTime]);

  useKeypress(handleGoToNextFrame, 'ArrowRight');
  useKeypress(handleGoToPrevFrame, 'ArrowLeft');
};
