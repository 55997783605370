import { Dialog as MUIDialog, type DialogProps as MuiDialogProps, styled } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import type { I18nParams } from '../../../services/i18n';
import { IconButton } from '../../controls/Button';
import { Typography } from '../../typography/Typography';

import { DialogHeader } from './DialogHeader';

export type DialogProps = MuiDialogProps & {
  onClose: () => unknown;
  size?: 's' | 'm' | 'l';
  withCloseButton?: boolean;
  title: ReactNode;
  titleI18nParams?: I18nParams;
  withHeaderBottomBorder?: boolean;
  leftIcon?: ReactNode;
};

const sizes = {
  s: 480,
  m: 576,
  l: 832,
};

const StyledDialog = styled(MUIDialog)<{ size?: DialogProps['size'] }>(({ theme, size = 's', scroll }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgb(0 0 0 / 0.2)',
  },

  '& .MuiPaper-root': {
    overflow: scroll === 'paper' ? 'hidden' : undefined,
  },

  '& .MuiDialogContent-root': {
    overflow: scroll === 'paper' ? 'auto' : undefined,
  },

  '& > .MuiDialog-container > .MuiPaper-root': {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    minWidth: '90%',
    maxWidth: '100%',
    borderRadius: theme.shape['borderRadius-l'],
    boxShadow: theme.shadows[1],
    maxHeight: `calc(100vh - ${theme.spacing(2)} * 2)`,

    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
      width: sizes[size],
      margin: theme.spacing(3),
      padding: theme.spacing(4),
      maxHeight: `calc(100vh - ${theme.spacing(3)} * 2)`,
    },

    '& > form:only-child': {
      display: 'contents',
      // hacky: targets the FormError component of our Form
      '.MuiDialogActions-root + div': {
        margin: theme.spacing(1, 4, 0, 'auto'),
      },
    },
  },
}));

const CloseButtonContainer = styled('div')(() => ({
  display: 'flex',
  flex: '1 0 auto',
  justifyContent: 'flex-end',
}));

export const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  children,
  title,
  titleI18nParams,
  withCloseButton = true,
  onClose,
  withHeaderBottomBorder,
  leftIcon,
  ...rest
}) => {
  return (
    <StyledDialog scroll="body" {...rest} onClose={onClose}>
      <DialogHeader withHeaderBottomBorder={withHeaderBottomBorder}>
        {leftIcon}
        {typeof title === 'string' ? (
          <Typography variant="h3" component="h2" i18nParams={titleI18nParams}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {withCloseButton && (
          <CloseButtonContainer>
            <IconButton onClick={onClose} icon="CloseIcon" label="i18n.global.close" />
          </CloseButtonContainer>
        )}
      </DialogHeader>

      {children}
    </StyledDialog>
  );
};
