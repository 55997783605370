import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import {
  GridContainer,
  GridItem,
  Icon,
  PopoverMenuTriggerIcon,
  ProjectPageHeadline,
  Responsive,
} from '@cofenster/web-components';

import { RouterButton } from '../components/button/RouterButton';
import { RouterIconButton } from '../components/button/RouterIconButton';
import { BackToFolderButton } from '../components/project/BackToFolderButton';
import { ExportButton } from '../components/project/ExportButton';
import { ProjectEditSubNavigation } from '../components/project/ProjectEditSubNavigation';
import { ProjectMenu } from '../components/project/ProjectMenu';
import { ProjectVideoSubNavigation } from '../components/project/ProjectVideoSubNavigation';
import { RequestPermissionButton } from '../components/requestPermissions';
import { FromProjectTeamProvider } from '../contexts/currentTeam/FromProjectTeamProvider';
import { useProject } from '../contexts/project/useProject';
import { TeamPermissionRestriction } from '../contexts/user/TeamPermissionRestriction';
import { useUser } from '../contexts/user/useUser';
import { routes } from '../routes';

const EditorProjectButtons = () => {
  const { project } = useProject();

  return (
    <FromProjectTeamProvider>
      <GridContainer justifyContent="space-between" spacing={{ xs: 1, sm: 2 }} alignItems="center" flexWrap="nowrap">
        <GridItem sx={{ display: { xs: 'flex', sm: 'none' }, marginRight: 'auto' }} gap={1}>
          <BackToFolderButton project={project} />
          {project && <ProjectMenu project={project} />}
        </GridItem>

        {project?.hasContributionRequestListAvailable && (
          <TeamPermissionRestriction has="ProjectUpdate">
            <GridItem display={{ xs: 'none', md: 'block' }}>
              <RouterButton
                to="projectContributionsConfiguration"
                variant="tertiary"
                startIcon={<Icon type="ShareFatIcon" />}
                params={{ projectId: project?.id, type: 'initial' }}
              >
                i18n.projectContributionConfiguration.requestContentButton
              </RouterButton>
            </GridItem>
            <GridItem display={{ md: 'none' }}>
              <RouterIconButton
                to="projectContributionsConfiguration"
                icon="ShareFatIcon"
                label="i18n.projectContributionConfiguration.requestContentButton"
                params={{ projectId: project?.id, type: 'initial' }}
              />
            </GridItem>
          </TeamPermissionRestriction>
        )}

        <TeamPermissionRestriction has="ProjectExport">
          <GridItem>
            <ExportButton projectId={project?.id} withIcon={false} />
          </GridItem>
        </TeamPermissionRestriction>
      </GridContainer>
    </FromProjectTeamProvider>
  );
};

export const useProjectEditingHeader = (projectId: string) => {
  const { project } = useProject();

  return useMemo(
    () => ({
      left: (
        <Responsive up="sm">
          <BackToFolderButton project={project} />
        </Responsive>
      ),
      topLeft: (
        <Responsive up="sm">
          <ProjectPageHeadline
            title={project?.name ?? 'i18n.global.loading'}
            popover={project ? <ProjectMenu project={project} /> : <PopoverMenuTriggerIcon disabled />}
          />
        </Responsive>
      ),
      bottomLeft: <ProjectEditSubNavigation projectId={projectId} />,
      right: <EditorProjectButtons />,
    }),
    [project, projectId]
  );
};

const RequestToPublishButton = () => {
  const { user } = useUser();

  if (!user) return null;

  return (
    <TeamPermissionRestriction not="SharingLinkCreate">
      <GridItem>
        <RequestPermissionButton user={user} permission="SharingLinkCreate">
          i18n.roleRequest.button.requestSharingLinkPermissions
        </RequestPermissionButton>
      </GridItem>
    </TeamPermissionRestriction>
  );
};

export const useProjectPostEditingHeader = () => {
  const { project } = useProject();
  const { pathname } = useLocation();
  const isSharingLinkPage = matchPath(routes.projectVideo, pathname);

  return useMemo(
    () => ({
      left: (
        <Responsive up="sm">
          <BackToFolderButton project={project} />
        </Responsive>
      ),
      topLeft: project && (
        <Responsive up="sm">
          <ProjectPageHeadline title={project?.name} popover={<ProjectMenu project={project} />} />
        </Responsive>
      ),
      bottomLeft: <ProjectVideoSubNavigation />,
      right: (
        <GridContainer justifyContent="space-between" spacing={2} alignItems="center">
          {project && (
            <TeamPermissionRestriction has="ProjectUpdate">
              <GridItem>
                <RouterButton
                  to="projectEdit"
                  variant="secondary"
                  params={{ projectId: project.id }}
                  startIcon={<Icon type="PencilIcon" />}
                  data-testid="back-to-editing-button"
                >
                  i18n.subNavigation.backToEditing
                </RouterButton>
              </GridItem>
            </TeamPermissionRestriction>
          )}
          <GridItem sx={{ display: { sm: 'none' }, marginRight: 'auto' }}>
            <BackToFolderButton project={project} />
          </GridItem>
          {isSharingLinkPage && <RequestToPublishButton />}
        </GridContainer>
      ),
    }),
    [project, isSharingLinkPage]
  );
};
