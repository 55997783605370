import type { FC } from 'react';

import { Button, type ButtonProps, Icon } from '@cofenster/web-components';

import { useConditionalStartRender } from '../../hooks/project/useConditionalStartRender';

const RenderButton: FC<ButtonProps & { projectId: string; withIcon: boolean }> = ({ projectId, withIcon, ...rest }) => {
  const { goToProjectExport, isLoading } = useConditionalStartRender(projectId, null);

  return (
    <Button
      {...rest}
      loading={isLoading}
      variant="primary"
      onClick={goToProjectExport}
      startIcon={withIcon ? <Icon type="MonitorPlayIcon" size="s" weight="fill" /> : null}
    >
      i18n.common.exportVideo
    </Button>
  );
};

export const ExportButton: FC<{ projectId?: string; withIcon: boolean }> = ({ withIcon, projectId }) =>
  projectId ? (
    <RenderButton projectId={projectId} withIcon={withIcon} data-testid="export-button" />
  ) : (
    <Button
      disabled
      variant="primary"
      startIcon={withIcon ? <Icon type="MonitorPlayIcon" size="s" weight="fill" /> : null}
      data-testid="export-button"
    >
      i18n.common.exportVideo
    </Button>
  );
