import { type FC, useCallback } from 'react';

import { Icon, Select, type SelectChangeEvent, SelectOption, Translatable, styled } from '@cofenster/web-components';

import type { Team } from '../../api/hooks/team/useTeams';

const Value = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const TeamDisplay: FC<{ name: string }> = ({ name }) => (
  <Value>
    <Icon type="UsersIcon" /> {name}
  </Value>
);

const PrivateDisplay: FC = () => (
  <Value>
    <Icon type="LockIcon" /> <Translatable>i18n.projectFolder.personal</Translatable>
  </Value>
);

type RootFolderSelectProps = {
  teams: Team[];
  label: string;
  name: string;
  value: string;
  onChange: (projectFolderId: string, team?: Team) => void;
  privateRootFolderId?: string;
};

export const RootFolderSelect: FC<RootFolderSelectProps> = ({
  teams,
  label,
  name,
  value,
  onChange,
  privateRootFolderId,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string;
      const team = teams.find((team) => team.rootProjectFolder.id === value);
      onChange(value, team);
    },
    [onChange, teams]
  );

  return (
    <Select label={label} name={name} onChange={handleChange} value={value}>
      {teams.map((team) => (
        <SelectOption key={team.id} value={team.rootProjectFolder.id}>
          <TeamDisplay name={team.name} />
        </SelectOption>
      ))}

      {!!privateRootFolderId && (
        <SelectOption value={privateRootFolderId}>
          <PrivateDisplay />
        </SelectOption>
      )}
    </Select>
  );
};
