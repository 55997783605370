import { useField } from 'formik';
import type { FC, PropsWithChildren } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Icon, type IconType, Text, Typography, UpsellIcon, styled, useFeatureFlags } from '@cofenster/web-components';

import type { VideoFormatOptionConfiguration } from './VideoFormatSelector';

const Label = styled('label')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey500}`,
  height: '100%',

  '&:not(:has(input:disabled))': {
    cursor: 'pointer',
  },

  '&:has(input:disabled)': {
    cursor: 'not-allowed' as const,
  },

  '&:has(input:checked)': {
    color: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue50,
    borderColor: theme.palette.brand.blue,
  },

  '&:has(input:focus-visible)': theme.mixins.focusRing,
}));

const Input = styled('input')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  opacity: 0,
}));

const UseCases = styled('ul')(({ theme }) => ({
  margin: 0,
  marginTop: theme.spacing(1),
  paddingLeft: 0,
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const UseCaseItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const UseCase: FC<PropsWithChildren<{ iconType: IconType }>> = ({ iconType, children }) => {
  return (
    <UseCaseItem>
      <Icon type={iconType} size="s" color="grey600" />
      <Text color="grey700" variant="s">
        {children}
      </Text>
    </UseCaseItem>
  );
};

const StyledUpsellIcon = styled(UpsellIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const translations = {
  SocialMedia: 'i18n.projectCreate.videoFormat.SocialMedia.title',
  Horizontal: 'i18n.projectCreate.videoFormat.Horizontal.title',
  Square: 'i18n.projectCreate.videoFormat.Square.title',
  Vertical: 'i18n.projectCreate.videoFormat.Vertical.title',
};

export const VideoFormatOption: FC<{
  format: VideoFormat;
  configuration: VideoFormatOptionConfiguration;
}> = ({ format, configuration }) => {
  const [field] = useField({ name: 'videoFormat' });
  const { hasFeature } = useFeatureFlags();

  return (
    <Label htmlFor={`videoFormat-${format}`}>
      <Input
        {...field}
        type="radio"
        name="videoFormat"
        value={format}
        checked={field.value === format}
        id={`videoFormat-${format}`}
        disabled={configuration.flag ? !hasFeature(configuration.flag) : false}
        data-testid={`project-preset-${format}-input`}
      />
      {configuration.flag && !hasFeature(configuration.flag) && <StyledUpsellIcon />}
      <img src={`/assets/images/projectCreate/${format}.svg`} alt="" />
      <Typography variant="l">{translations[format]}</Typography>
      <UseCases>
        {configuration.useCases.map((useCase) => (
          <UseCase key={useCase.text} iconType={useCase.iconType as IconType}>
            {useCase.text}
          </UseCase>
        ))}
      </UseCases>
    </Label>
  );
};
