import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Typography, styled, useI18n } from '@cofenster/web-components';

import { RouterButton } from '../../components/button/RouterButton';
import { AppLayout } from '../../components/page/Layout';

const Center = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

export type NoAccessResource = 'Project' | 'ProjectFolder' | 'Team' | 'Resource' | 'User' | 'Page';

export const NoAccessContent: FC<{ resource?: NoAccessResource }> = ({ resource = 'Resource' }) => (
  <Center>
    <Typography variant="h1" component="h1" i18nParams={{ resource }} data-testid="no-access-headline">
      i18n.noAccess.title
    </Typography>
    <Typography align="center" variant="xl" component="p" i18nParams={{ resource }}>
      i18n.noAccess.message
    </Typography>
    <RouterButton to="home">i18n.noAccess.goHome</RouterButton>
  </Center>
);

export const NoAccessLayout: FC<{ documentTitle?: string; resource?: NoAccessResource }> = (props) => {
  const { state } = useLocation() as { state: { resource: NoAccessResource } };
  const resource = props.resource ?? state?.resource ?? 'Page';
  const { translate } = useI18n();

  return (
    <AppLayout documentTitle={props.documentTitle ?? translate('noAccess.title', { resource })}>
      <NoAccessContent resource={resource} />
    </AppLayout>
  );
};
