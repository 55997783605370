import { type FC, useCallback, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Dialog, DialogContent } from '@cofenster/web-components';

import { AddSceneForm } from '../../common/AddScene/AddSceneForm';
import type { SceneTypeProps } from '../../common/AddScene/SceneTypeCard';

export type CreateScenesDialogProps = SceneTypeProps & {
  isOpen: boolean;
  closeDialog: () => unknown;
  onCloseDialog?: (sceneId: string) => void;
  goToProjectContributionsPage: VoidFunction;
  videoFormat: VideoFormat;
  hasContributionRequestListAvailable: boolean;
  canUpdateProject: boolean;
};

export const CreateScenesDialog: FC<CreateScenesDialogProps> = ({
  isOpen,
  projectId,
  index,
  closeDialog,
  onCloseDialog,
  goToProjectContributionsPage,
  videoFormat,
  hasContributionRequestListAvailable,
  canUpdateProject,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onCreate = useCallback(
    (sceneId: string) => {
      closeDialog();
      onCloseDialog?.(sceneId);
    },
    [closeDialog, onCloseDialog]
  );

  const goToProjectContributionsPageAndClose = useCallback(() => {
    goToProjectContributionsPage();
    closeDialog();
  }, [goToProjectContributionsPage, closeDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      size="l"
      data-testid="create-scene-dialog"
      title={
        selectedFiles.length === 0
          ? 'i18n.dialogs.createScenesDialog.addScene'
          : 'i18n.dialogs.createScenesDialog.uploading'
      }
    >
      <DialogContent>
        <AddSceneForm
          projectId={projectId}
          videoFormat={videoFormat}
          index={index}
          onCreate={onCreate}
          onUploadStart={setSelectedFiles}
          onUploadEnd={closeDialog}
          bulkUploadInProgress={selectedFiles.length > 0}
          goToProjectContributionsPage={
            hasContributionRequestListAvailable ? goToProjectContributionsPageAndClose : undefined
          }
          hasContributionRequestListAvailable={hasContributionRequestListAvailable}
          canUpdateProject={canUpdateProject}
        />
      </DialogContent>
    </Dialog>
  );
};
