import { type FC, useCallback } from 'react';

import type { SelectChangeEvent } from '@cofenster/web-components';

import { CreatedBySelect } from '../../components/project/CreatedBySelect';
import type { ProjectsSearchFilters } from '../../contexts/searchFilter/useProjectsSearchFilters';
import { useSearchFilter } from '../../contexts/searchFilter/useSearchFilter';

import { HomeListTypeButtons } from './HomeListTypeButtons';

export const HomeSectionControls: FC = () => {
  const { filter, setCreatedBy } = useSearchFilter();
  const onCreatedByChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setCreatedBy(event.target.value as ProjectsSearchFilters['createdBy']),
    [setCreatedBy]
  );

  return (
    <>
      <CreatedBySelect value={filter.createdBy} onChange={onCreatedByChange} />
      <HomeListTypeButtons />
    </>
  );
};
