import { type FC, useEffect } from 'react';

import {
  GridContainer,
  GridItem,
  SubNavigation,
  SubNavigationItem,
  Typography,
  useTabNavigation,
} from '@cofenster/web-components';

import { IntroOutroUpload } from '../../../../components/branding/IntroOutroUpload';
import type { Project } from '../../../../contexts/project/useProject';

import { OutroTextInput } from './OutroTextInput';

export const OutroSection: FC<{
  project: Project;
  textOutroState: [boolean, (value: boolean) => unknown];
}> = ({ project, textOutroState }) => {
  const { theme, videoFormat, template } = project;
  const [useTextOutro, setUseTextOutro] = textOutroState;
  const { currentTab, getTablistProps, getTabProps, getTabSectionProps } = useTabNavigation(
    ['TEXT', 'VIDEO'],
    useTextOutro ? 'TEXT' : 'VIDEO'
  );

  useEffect(() => {
    setUseTextOutro(currentTab === 'TEXT');
  }, [setUseTextOutro, currentTab]);

  if (!theme) {
    return null;
  }

  if (!template.supportsTextOutro) {
    return (
      <GridContainer alignItems="center" spacing={2}>
        <GridItem xs={12}>
          <IntroOutroUpload
            videoType="Outro"
            videoFormat={videoFormat}
            theme={theme}
            size="small"
            data-testid="outro-upload-dropzone"
          />
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <>
      <SubNavigation {...getTablistProps()}>
        <SubNavigationItem iconType="TextIcon" active={currentTab === 'TEXT'} {...getTabProps('TEXT')}>
          i18n.projectDesign.themeForm.textOutro
        </SubNavigationItem>
        <SubNavigationItem iconType="MovieIcon" active={currentTab === 'VIDEO'} {...getTabProps('VIDEO')}>
          i18n.projectDesign.themeForm.videoOutro
        </SubNavigationItem>
      </SubNavigation>

      <GridContainer alignItems="center" spacing={2}>
        <GridItem xs={12} {...getTabSectionProps('TEXT')}>
          <GridContainer spacing={2} mt={1}>
            <GridItem xs={12}>
              <Typography variant="l" component="p">
                i18n.projectDesign.themeForm.textOutroHint
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <OutroTextInput name="outroText" videoFormat={videoFormat} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} {...getTabSectionProps('VIDEO')}>
          <GridContainer spacing={2} mt={1}>
            <GridItem xs={12}>
              <IntroOutroUpload
                videoType="Outro"
                videoFormat={videoFormat}
                theme={theme}
                size="small"
                data-testid="outro-upload-dropzone"
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};
