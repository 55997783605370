import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  GridContainer,
  GridItem,
  Spacing,
  Text,
  Typography,
} from '@cofenster/web-components';

import { type Role, useRoles } from '../../../api/hooks/role/useRoles';
import { useAddTeamMembers } from '../../../api/hooks/team/useAddTeamMembers';
import type { CurrentTeamContext } from '../../../contexts/currentTeam/CurrentTeamContext';
import { useUser } from '../../../contexts/user/useUser';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { MemberPopoverButton } from '../../team/TeamMembersList/RoleAndMembership/MemberPopoverButton';

import { UserAutocomplete } from './UserAutocomplete';

type Values = {
  userIds: string[];
};

const useValidationSchema = () => {
  const validations = {
    userIds: Yup.array()
      .of(Yup.string().required('i18n.form.error.generic.required'))
      .required('i18n.form.error.generic.required'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = () => {
  return useMemo<Values>(
    () => ({
      userIds: [],
    }),
    []
  );
};

const useSubmit = (
  team: AddTeamMembersDialogProps['team'],
  closeDialog: AddTeamMembersDialogProps['closeDialog'],
  selectedRoleId: string | undefined
) => {
  const addTeamMembers = useAddTeamMembers();
  const tracking = useWebManagerTracking(team.id);

  return useCallback(
    async (values: FormikValues) => {
      if (!selectedRoleId) return;
      const { userIds } = values as Values;
      const result = await addTeamMembers(team.id, { userIds, roleId: selectedRoleId });

      closeDialog();

      if (result.data?.addTeamMembers) {
        tracking.trackEvent({
          event: 'teamSpaceMembersAdded',
          details: {
            teamId: result.data.addTeamMembers.id,
            teamName: result.data.addTeamMembers.name,
            teamType: result.data.addTeamMembers.public ? 'public' : 'private',
            userIds,
          },
        });
      }
    },
    [team, addTeamMembers, closeDialog, tracking, selectedRoleId]
  );
};

export type AddTeamMembersDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  team: NonNullable<CurrentTeamContext['team']>;
};

export const AddTeamMembersDialog: FC<AddTeamMembersDialogProps> = ({ isOpen, closeDialog, team }) => {
  const { user } = useUser();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const { roles } = useRoles();
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(roles?.[0]);
  const onSubmit = useSubmit(team, closeDialog, selectedRole?.roleId);

  const updateCurrentRole = (roleId: string) => {
    const newRoleToSelect = roles.find((role) => role.roleId === roleId) ?? roles?.[0];
    setSelectedRole(newRoleToSelect);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      size="m"
      data-testid="add-member-dialog"
      title="i18n.dialogs.addTeamMembersDialog.headline"
      titleI18nParams={{ teamName: team.name }}
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" i18nParams={{ accountName: user?.account.name ?? '' }} component="p">
              i18n.dialogs.addTeamMembersDialog.description
            </Text>
          </Spacing>
          <UserAutocomplete team={team} name="userIds" />
          <GridContainer>
            <GridItem xs={1} alignSelf="center">
              <Typography component="p" variant="h6">
                i18n.userRole.role
              </Typography>
            </GridItem>
            <GridItem xs={11} md={11}>
              <MemberPopoverButton
                roles={roles}
                canTransfer={false}
                canManage={true}
                canLeave={false}
                canRemove={false}
                currentRole={selectedRole}
                changeRole={updateCurrentRole}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth type="submit" data-testid="add-member-dialog-save">
            i18n.team.addMembers
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
