import type { SelectChangeEvent } from '@cofenster/web-components';
import { useCallback, useMemo, useState } from 'react';

import type { ContributionRequestById } from '../../../../api/hooks/contributionRequest/useContributionRequestsById';
import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';

type VideoAsset = ContributionRequestById['videoAssets'][number];
type ImageAsset = ContributionRequestById['imageAssets'][number];
type Contributor = ContributionRequestById['contributions'][number]['contributors'][number];

const deduplicate = <T>(array: T[], field: keyof T) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[field];
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
};

export const useFilterContributionRequests = (requests: ContributionRequest[]) => {
  const [requestIds, setRequestIds] = useState<string[]>([]);
  const onRequestChange = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    if (!value?.[0] || value.includes('reset')) setRequestIds([]);
    else setRequestIds(value);
  }, []);

  const [contributorIds, setContributorIds] = useState<string[]>([]);
  const onContributorChange = useCallback((event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    if (!value?.[0] || value.includes('reset')) setContributorIds([]);
    else setContributorIds(value);
  }, []);

  const contributionItems: {
    asset: ImageAsset | VideoAsset;
    contributionRequest: ContributionRequest;
    contributors: Contributor[];
  }[] = requests.flatMap((cr) =>
    cr.contributions
      .map((contribution) => {
        if (contribution.__typename === 'ImageContribution') {
          return { asset: contribution.imageAsset, contributionRequest: cr, contributors: contribution.contributors };
        }

        if (contribution.__typename === 'VideoContribution') {
          return { asset: contribution.videoAsset, contributionRequest: cr, contributors: contribution.contributors };
        }
      })
      .filter((value) => value !== undefined)
  );

  const filteredContributionItems = useMemo(
    () =>
      contributionItems
        .filter(({ contributionRequest }) => requestIds.length === 0 || requestIds.includes(contributionRequest.id))
        .filter(
          ({ contributors }) =>
            contributorIds.length === 0 || contributors.some(({ id }) => contributorIds.includes(id))
        )
        .sort((a, b) => b.asset.createdAt.localeCompare(a.asset.createdAt)),
    [contributionItems, requestIds, contributorIds]
  );

  const contributors = useMemo(
    () =>
      deduplicate(
        contributionItems.flatMap(({ contributors }) => contributors),
        'id'
      ),
    [contributionItems]
  );

  return {
    requestIds,
    onRequestChange,
    contributorIds,
    onContributorChange,
    contributors,
    contributionItems,
    filteredContributionItems,
  };
};
