import { type FC, useCallback } from 'react';

import { CurrentTimeOverTime, styled } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';
import { PlayerActions } from '../../components/PlayerActions';
import { usePreviewDetails } from '../../hooks/usePreviewDetails';

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: 'max-content',
  margin: 'var(--project-editor-player-actions-spacing) auto 0',
}));

export const EditorPlayerActions: FC = () => {
  const {
    duration,
    currentTime,
    gotoNextScene,
    gotoPreviousScene,
    play,
    pause,
    paused,
    isVideoBuffering,
    hasPreviewLoadingError,
  } = useEditorPlayer();

  const getPreviewDetails = usePreviewDetails();
  const tracking = useWebManagerTracking();

  const onPlay = useCallback(() => {
    tracking.trackEvent({
      event: 'ScenePreviewPlayed',
      details: getPreviewDetails({ videoPlayerStatus: 'playing' }),
    });

    play();
  }, [tracking, getPreviewDetails, play]);

  const onPause = useCallback(() => {
    tracking.trackEvent({
      event: 'ScenePreviewPaused',
      details: getPreviewDetails({ videoPlayerStatus: 'paused' }),
    });

    pause();
  }, [tracking, getPreviewDetails, pause]);

  const onPreviousScene = useCallback(() => {
    if (!gotoPreviousScene) return;

    tracking.trackEvent({
      event: 'ScenePreviewPrevious',
      details: getPreviewDetails(),
    });

    gotoPreviousScene();
  }, [tracking, getPreviewDetails, gotoPreviousScene]);

  const onNextScene = useCallback(() => {
    if (!gotoNextScene) return;

    tracking.trackEvent({
      event: 'ScenePreviewNext',
      details: getPreviewDetails(),
    });

    gotoNextScene();
  }, [tracking, getPreviewDetails, gotoNextScene]);

  return (
    <ActionsContainer>
      <PlayerActions
        gotoPreviousScene={gotoPreviousScene ? onPreviousScene : undefined}
        gotoNextScene={gotoNextScene ? onNextScene : undefined}
        play={onPlay}
        pause={onPause}
        paused={paused}
        disabled={hasPreviewLoadingError || isVideoBuffering}
      />
      <CurrentTimeOverTime
        currentTime={currentTime}
        duration={duration}
        timeFormat={duration > 60 * 60 ? 'hh:mm:ss' : 'mm:ss'}
      />
    </ActionsContainer>
  );
};
