import { styled } from '@mui/material';
import { format } from 'date-fns';
import type { FC } from 'react';

import { Tooltip } from '../../feedback/Tooltip';
import { Text } from '../../typography/Text';

const StyledText = styled(Text)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const CurrentTimeOverTime: FC<{
  currentTime: number;
  duration: number;
  timeFormat?: Parameters<typeof format>[1];
  tooltipTitle?: string;
}> = ({ currentTime, duration, timeFormat = 'mm:ss.S', tooltipTitle = 'i18n.global.duration.label.total' }) => {
  return (
    <StyledText component="p">
      <Text variant="l" color="carbon" tabularNums>
        {format(currentTime * 1000, timeFormat)}
      </Text>
      <Tooltip title={tooltipTitle}>
        <Text variant="l" color="grey600" tabularNums>
          ({format(duration * 1000, timeFormat)})
        </Text>
      </Tooltip>
    </StyledText>
  );
};
