import type { GlobalEN } from './EN';

export const GlobalDE: Record<keyof typeof GlobalEN, string> = {
  'global.all': 'Alle',
  'global.back': 'Zurück',
  'global.backgroundEffect.blur': 'Unschärfe',
  'global.backgroundEffect.image.homeoffice': 'Gemütliches Zuhause',
  'global.backgroundEffect.image.office': 'Professioneller Hintergrund',
  'global.backgroundEffect.none': 'Kein Filter',
  'global.backgroundEffect.title': 'Effekte',
  'global.beforeUnload.cancel': 'Bleib hier',
  'global.beforeUnload.confirm': 'Navigieren Sie trotzdem',
  'global.beforeUnload.content': 'Du hast nicht gespeicherte Änderungen. Bist du sicher, dass du die Seite verlassen möchtest, ohne zu speichern? Dadurch werden alle ausstehenden Änderungen verworfen.',
  'global.beforeUnload.title': 'Nicht gespeicherte Änderungen',
  'global.camera': 'Kamera',
  'global.camera.configure': 'Konfiguriere deine Kamera',
  'global.camera.none': 'Keine Kamera',
  'global.camera.smileHint': 'Lächle in die Kamera',
  'global.cancel': 'Abbrechen',
  'global.capture.start': 'Aufnahme starten',
  'global.characterCounter.remaining': '{remaining} von {max} Zeichen übrig',
  'global.close': 'Schließen',
  'global.colorPicker.button': 'Farbwähler öffnen',
  'global.colorPicker.placeholder': 'Wähle eine Farbe',
  'global.continue': 'Weiter',
  'global.cookieBanner.accept': 'Okay',
  'global.cookieBanner.decline': 'Ablehnen',
  'global.cookieBanner.text': 'Hey! Wir nutzen Cookies, um unser Produkt zu verbessern. Wenn du fortfährst, gehen wir davon aus, dass du damit einverstanden bist. Siehe <link>Datenschutz</link>.',
  'global.copyToClipboard': 'In die Zwischenablage kopieren',
  'global.createdAt': 'Angelegt am',
  'global.dataProtection': 'Datenschutz',
  'global.delete': 'Löschen',
  'global.desktop.select': 'Desktop auswählen',
  'global.dialog.UploadFailureDialog.button.delete': 'Datei löschen',
  'global.dialog.UploadFailureDialog.button.retry': 'Erneut hochladen',
  'global.dialog.UploadFailureDialog.description': 'Dies könnte mit einem Konnektivitätsproblem zusammenhängen oder ein Problem auf unserer Seite sein. Versuche den Beitrag erneut hochzuladen oder lösche ihn um eine neue Aufnahme zu starten.',
  'global.dialog.UploadFailureDialog.previewImageAlt': 'Vorschau des aufgenommenen Bildes',
  'global.dialog.UploadFailureDialog.title': 'Hochladen gescheitert',
  'global.download': 'Herunterladen',
  'global.drag.hint': 'Dieses Element verschieben (LEERTASTE → AUF/AB-Pfeiltasten → ESC)',
  'global.duration.decrease': 'Dauer verringern',
  'global.duration.increase': 'Dauer erhöhen',
  'global.duration.label': 'Dauer',
  'global.duration.label.total': 'Gesamtdauer',
  'global.error.form.pristine': 'Das Formular weist keine ausstehenden Änderungen auf und kann nicht gesendet werden.',
  'global.error.form.submitting': 'Das Formular wird derzeit eingereicht; Geduld.',
  'global.error.generic.unknown': 'Es ist ein unerwarteter Fehler aufgetreten',
  'global.error.generic.unknown.retry': 'Erneut versuchen',
  'global.error.upload.file-invalid-type': 'Die Datei wird nicht unterstützt.',
  'global.error.upload.file-too-large': 'Diese Datei ist zu groß (>{maxSize})',
  'global.error.upload.file-too-small': 'Diese Datei ist zu klein',
  'global.error.upload.too-many-files': 'Es gibt zu viele Dateien',
  'global.error.upload.upload-failed': 'Der Upload der Datei ist fehlgeschlagen',
  'global.errorPage.contactLink': 'Kontaktiere uns',
  'global.errorPage.content': 'Mach dir keine Sorgen! Wir wurden über das Problem informiert. Bitte aktualisiere die Seite und versuche es erneut oder kontaktiere uns, falls das Problem weiterhin besteht.',
  'global.errorPage.headline': 'Ein unerwarteter Fehler ist aufgetreten',
  'global.flipCamera': 'Kamera wechseln',
  'global.form.error.client': 'Ein Client-Fehler ist aufgetreten.',
  'global.form.error.network': 'Ein Netzwerkfehler ist aufgetreten.',
  'global.form.error.server': 'Ein Server-Fehler ist aufgetreten.',
  'global.form.error.unknown': 'Ein unbekannter Fehler ist aufgetreten.',
  'global.framing.disable': 'Rahmen deaktivieren',
  'global.framing.enable': 'Rahmen aktivieren',
  'global.framing.toggle': 'Rahmen ein-/ausblenden',
  'global.fullscreen': 'Vollbild',
  'global.history.redo': 'Wiederholen',
  'global.history.reset': 'Zurücksetzen',
  'global.history.undo': 'Rückgängig machen',
  'global.imprint': 'Impressum',
  'global.loading': 'Wird geladen…',
  'global.loop': 'Wiederholen',
  'global.microphone': 'Mikrofon',
  'global.microphone.configure': 'Konfiguriere dein Mikrofon',
  'global.microphone.none': 'Kein Mikrofon',
  'global.more': 'Mehr',
  'global.mute': 'Stummschalten',
  'global.muted': 'Stummgeschaltet',
  'global.navigation.toggle': 'Navigation ein-/ausblenden',
  'global.next': 'Weiter',
  'global.noAudioTooltipText': 'Versuchst du zu sprechen? Klicke auf das Mikrofon-Symbol, um es einzuschalten.',
  'global.okay': 'Okay',
  'global.password.toggle': 'Sichtbarkeit des Passworts ändern',
  'global.pasteFromClipboard': 'Aus der Zwischenablage einfügen',
  'global.pause': 'Pausieren',
  'global.pip.dialog.camera': 'Kamera',
  'global.pip.dialog.camera.size.big': 'Groß',
  'global.pip.dialog.camera.size.medium': 'Medium',
  'global.pip.dialog.camera.size.small': 'Klein',
  'global.pip.dialog.close': 'Zurück zur Aufnahme',
  'global.pip.dialog.no-camera': 'Keine Kamera',
  'global.pip.dialog.position': 'Position',
  'global.pip.dialog.position.bottom-left': 'Links unten',
  'global.pip.dialog.position.bottom-right': 'Rechts unten',
  'global.pip.dialog.position.top-left': 'Links oben',
  'global.pip.dialog.position.top-right': 'Rechts oben',
  'global.pip.dialog.size': 'Größe',
  'global.pip.dialog.title': 'Nehme dich selbst auf',
  'global.play': 'Abspielen',
  'global.popover.toggle': 'Menü öffnen',
  'global.preview': 'Vorschau',
  'global.previous': 'Zurück',
  'global.private': 'Privat',
  'global.processingIndicator': 'Wird optimiert',
  'global.record': 'Aufnehmen',
  'global.remotion.error': 'Beim Laden der Vorschau ist ein Problem aufgetreten.',
  'global.renderingProgress.error': 'Möglicherweise ist beim Export ein Problem aufgetreten. Erwäge einen Neustart.',
  'global.renderingProgress.restart': 'Erneut versuchen',
  'global.renderingProgress.start': 'Video exportieren',
  'global.renderingProgress.subtitle.0': 'Bereite Export vor…',
  'global.renderingProgress.subtitle.1': 'Dein Projekt-Design wird abgerufen…',
  'global.renderingProgress.subtitle.2': 'Deine Inhalte werden abgerufen…',
  'global.renderingProgress.subtitle.3': 'Deine Szenen werden zusammengefügt…',
  'global.renderingProgress.subtitle.4': 'Die Audioqualität wird verbessert…',
  'global.renderingProgress.subtitle.5': 'Die Sprache und die Lautstärke werden aneinander angeglichen…',
  'global.renderingProgress.subtitle.6': 'Die Markenelemente und die Übergänge werden hinzugefügt…',
  'global.renderingProgress.subtitle.7': 'Die Dateigröße und die Videoqualität werden optimiert…',
  'global.renderingProgress.subtitle.8': 'Fertigstellung des Videos…',
  'global.renderingProgress.title': 'Exportiere dein Video',
  'global.resume': 'Fortsetzen',
  'global.retake': 'Neu starten',
  'global.save': 'Speichern',
  'global.saved': 'Gespeichert',
  'global.search': 'Suchen',
  'global.search.clear': 'Suche löschen',
  'global.search.close': 'Suche schließen',
  'global.search.field.info': 'Die Ergebnisse werden während der Eingabe aktualisiert.',
  'global.search.open': 'Suche öffnen',
  'global.search.results.empty': 'Für „{search}“ wurden keine Ergebnisse gefunden.',
  'global.search.results.header': '{count, plural, =0 {Keine Ergebnisse} one {# Ergebnis} other {# Ergebnisse}} für {search} gefunden',
  'global.search.term': '{term} suchen…',
  'global.seatsLeft.description': 'Kontaktiere uns wenn du deinen Plan anpassen möchtest',
  'global.seatsLeft.title': '{seatsLeft} Plätze übrig mit deinem Plan',
  'global.selectAudioDevice.permissionsDenied': 'Deine aktuellen Browser-Einstellungen verhindern den Zugriff auf dein Mikrofon.',
  'global.shared.SharingPanel.copyEmbed': 'Code Einbetten',
  'global.shared.SharingPanel.copyLink': 'Link kopieren',
  'global.shared.SharingPanel.email': 'Email',
  'global.shared.SharingPanel.email.body': 'Schau dir das folgende Video an: {url}',
  'global.shared.SharingPanel.email.subject': 'Ich möchte dieses cofenster Video mit dir teilen: {title}',
  'global.shared.SharingPanel.embed': 'Einbetten',
  'global.shared.SharingPanel.link': 'Link',
  'global.shared.SharingPanel.link.title': 'Ich möchte dieses cofenster Video mit euch teilen.',
  'global.shared.SharingPanel.openPage': 'Seite öffnen',
  'global.skipNavigation': 'Menü überspringen',
  'global.systemAudioMutedTooltipText': 'Dein Mikrofon ist auf Systemebene stummgeschaltet. Gehe zu deinen <link>Systemeinstellungen</link> und schalte es ein.',
  'global.termsAndConditions': 'ABG',
  'global.unmute': 'Stummschaltung aufheben',
  'global.updatedAt': 'Aktualisiert am',
  'global.upload.hint': '{fileFormat} optimal in {dimensions}',
  'global.upload.image.state.error': 'Ups. Bei der Verarbeitung deines Bild ist ein Fehler aufgetreten. Wahrscheinlich ist dein Video fehlerhaft.',
  'global.upload.image.state.processing': 'Einen Moment, dein Bild ist gleich fertig!',
  'global.upload.video.state.processing': 'Einen Moment, dein Video ist gleich fertig!',
  'global.user.role.admin': 'Admin',
  'global.user.role.member': 'Member',
  'global.user.status.active': 'Aktiv',
  'global.user.status.deactivated': 'Deaktiviert',
  'global.user.status.deactivated.tooltip': 'Deaktivierte Benutzer können nicht mehr auf cofenster zugreifen. Admins können sie jederzeit wieder aktivieren',
  'global.user.status.pending': 'Ausstehend',
  'global.user.status.pending.tooltip': 'Eingeladen am {invitedAt}, aber hat die Einladung noch nicht angenommen',
} as const;
