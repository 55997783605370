import { type FC, memo } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { type ButtonProps, styled } from '@cofenster/web-components';

import type { Scene } from '../../../contexts/scenes/ScenesContext';
import { CollectAssetButton } from '../CollectAssetButton';
import { StockAssetButton } from '../StockAssetButton';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const OpenSceneAssetLifecyclePageButton: FC<
  Omit<ButtonProps, 'type'> & {
    projectId: string;
    scene: Scene;
    type: 'image' | 'video' | 'screen';
    videoFormat: VideoFormat;
  }
> = memo(function OpenSceneAssetLifecyclePageButton({ projectId, scene, type, disabled, videoFormat }) {
  return (
    <Container>
      <CollectAssetButton
        assetType={type}
        disabled={disabled}
        projectId={projectId}
        sceneId={scene.id}
        // Make sure not to open the file picker that surrounds it
        onClick={(event) => event.stopPropagation()}
      />
      <StockAssetButton
        assetType={type}
        disabled={disabled}
        projectId={projectId}
        scene={scene}
        videoFormat={videoFormat}
        variant="secondary"
      />
    </Container>
  );
});
