import { useMemo } from 'react';

import {
  type StoriesByAccountQueryResult,
  type StoriesByAccountQueryVariables,
  useStoriesByAccountQuery,
} from '../../generated';

export type Story = NonNullable<StoriesByAccountQueryResult['data']>['storiesByAccount'][number];

export const useStoriesByAccount = (filter: StoriesByAccountQueryVariables['filter']) => {
  const { loading, error, data } = useStoriesByAccountQuery({
    variables: { filter },
    fetchPolicy: 'cache-and-network',
  });
  const storiesByAccount = data?.storiesByAccount;
  return useMemo(
    () => ({
      storiesByAccount: storiesByAccount || [],
      loading,
      error,
    }),
    [storiesByAccount, loading, error]
  );
};
