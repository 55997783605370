import { type FC, type PropsWithChildren, useRef } from 'react';

import { SearchFilterContext } from './SearchFilterContext';
import { useProjectsSearchFilters, useProjectsSearchFiltersWithoutQueryParams } from './useProjectsSearchFilters';

export const SearchFilterProvider: FC<
  PropsWithChildren<{
    resultsPerPage?: number;
    // This field is taken into account only on the first render
    withQueryParams?: boolean;
  }>
> = ({ children, resultsPerPage = 10, withQueryParams = true }) => {
  const withQueryParamsRef = useRef(withQueryParams);
  const state = withQueryParamsRef.current
    ? // biome-ignore lint/correctness/useHookAtTopLevel: safe
      useProjectsSearchFilters(resultsPerPage)
    : // biome-ignore lint/correctness/useHookAtTopLevel: safe
      useProjectsSearchFiltersWithoutQueryParams(resultsPerPage);

  return <SearchFilterContext.Provider value={state}>{children}</SearchFilterContext.Provider>;
};
