import { type FC, useCallback, useMemo } from 'react';

import { PopoverMenuItem, Translatable } from '@cofenster/web-components';

import { useDeleteThemeVideoAsset } from '../../../../../api/hooks/theme/useDeleteThemeVideoAsset';
import type { ThemeVideoType } from '../../../../../api/hooks/user/useMe';
import { RouterLink } from '../../../../../components/button/RouterLink';
import type { Project } from '../../../../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useFindVideoAssetOnTheme } from '../../../../../hooks/useFindVideoOnTheme';
import {
  InsertScenePopoverMenuItem,
  InspectPopoverMenuItem,
  ToggleIntroOutroFromVideo,
  useCanInspectAsset,
} from '../components/ScenePopoverActions';

import { useAddPopoverDividers } from './useAddPopoverDividers';

const ProjectThemeLink: FC = (props) => <RouterLink to="projectDesign" {...props} />;

export const useIntroOutroActionsPopoverContent = ({
  project,
  themeVideoType,
}: {
  project: Project;
  themeVideoType: ThemeVideoType;
}) => {
  const themeVideoAsset = useFindVideoAssetOnTheme(project.theme, themeVideoType, project.videoFormat);
  const videoAsset = themeVideoAsset?.videoAsset;
  const downloadUrl = videoAsset?.downloadUrl;
  const isAllowedToUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const addPopoverDividers = useAddPopoverDividers();
  const canInspectAsset = useCanInspectAsset(videoAsset?.id);
  const themeVideoAssetId = themeVideoAsset?.id;
  const deleteThemeVideoAsset = useDeleteThemeVideoAsset();
  const onDeleteAsset = useCallback(() => {
    themeVideoAssetId && deleteThemeVideoAsset(themeVideoAssetId);
  }, [deleteThemeVideoAsset, themeVideoAssetId]);

  return useMemo(() => {
    const isProjectArchived = Boolean(project.archivedAt);
    const canUpdateProject = !isProjectArchived && isAllowedToUpdateProject;
    const top = [
      canUpdateProject && (
        <InsertScenePopoverMenuItem
          key="insert-scene"
          hasContributionRequestListAvailable={project.hasContributionRequestListAvailable}
          sceneId={themeVideoType}
        />
      ),
    ].filter(Boolean);

    const middle = [
      canUpdateProject && (
        <ToggleIntroOutroFromVideo key="toggle-intro-outro-from-video" themeVideoType={themeVideoType} />
      ),
      canUpdateProject && (
        <PopoverMenuItem
          key="edit-asset"
          icon="PencilIcon"
          component={ProjectThemeLink}
          params={{ projectId: project.id }}
          data-testid="scene-edit-asset"
        >
          <Translatable i18nParams={{ type: themeVideoType }}>i18n.projectEditor.tools.introOutro</Translatable>
        </PopoverMenuItem>
      ),
      __INTEGRATED_INTRO_OUTRO_EDITOR__ && canUpdateProject && downloadUrl && (
        <PopoverMenuItem
          key="delete-asset"
          icon="CloseIcon"
          onClick={onDeleteAsset}
          data-testid="scene-delete-asset"
          color="negative"
        >
          <Translatable i18nParams={{ type: themeVideoType }}>
            i18n.projectEditor.sceneAssetThumbs.popover.actions.deleteAsset
          </Translatable>
        </PopoverMenuItem>
      ),
    ].filter(Boolean);

    const bottom = [
      downloadUrl && (
        <PopoverMenuItem
          icon="DownloadIcon"
          href={downloadUrl}
          target="_blank"
          download
          data-testid="scene-download-asset"
          component="a"
          key="download-asset"
        >
          i18n.projectEditor.sceneAssetThumbs.popover.actions.download
        </PopoverMenuItem>
      ),
    ].filter(Boolean);

    const inspect = [
      canInspectAsset && <InspectPopoverMenuItem key="inspect-asset" videoAssetId={videoAsset?.id} />,
    ].filter(Boolean);

    return addPopoverDividers([top, middle, bottom, inspect]);
  }, [
    addPopoverDividers,
    canInspectAsset,
    downloadUrl,
    isAllowedToUpdateProject,
    onDeleteAsset,
    project.archivedAt,
    project.id,
    themeVideoType,
    videoAsset?.id,
    project.hasContributionRequestListAvailable,
  ]);
};
