import { styled } from '@mui/material';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { Text, type TextProps } from '../../typography/Text';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  gap: theme.spacing(0.5),
}));

export const ErrorMessage: FC<TextProps> = (props) => (
  <Container>
    <Icon color="negative" type="WarningCircleIcon" />
    <Text color="negative" {...props} />
  </Container>
);
