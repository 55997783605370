import type { FormikProps, FormikValues } from 'formik';
import { type FC, useCallback } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  SpinningIcon,
} from '@cofenster/web-components';

import { useRecoverPassword } from '../../api/hooks/user/useRecoverPassword';
import { RouterButton } from '../../components/button/RouterButton';
import { useGotoDeactivatedUser } from '../../hooks/navigation/useGotoDeactivatedUser';
import { useGotoResetSent } from '../../hooks/navigation/useGotoResetSent';
import { useCheckSso } from '../../hooks/useCheckSso';

const initialValues = { email: '' };

type InitialValues = typeof initialValues;

const validationSchema: Yup.ObjectSchema<InitialValues> = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('i18n.Form.error.email.format')
    .matches(/@[\w-.]+\.[a-z]{2,}$/, 'i18n.Form.error.email.format')
    .required('i18n.form.error.email.required'),
});

const useOnSubmit = () => {
  const recoverPassword = useRecoverPassword();
  const gotoResetSent = useGotoResetSent();
  const gotoDeactivatedUser = useGotoDeactivatedUser();

  return useCallback(
    async (email: string) => {
      if (!email) return;

      const result = await recoverPassword(email);
      if (result.data?.recoverPassword.__typename === 'DeactivatedUserError') return gotoDeactivatedUser();
      if (result.data?.recoverPassword.__typename === 'UserNotFoundError') return gotoResetSent();
      gotoResetSent();
    },
    [recoverPassword, gotoResetSent, gotoDeactivatedUser]
  );
};

export const ForgotPasswordForm: FC = () => {
  const { getAuthProvider } = useCheckSso();
  const onSubmit = useOnSubmit();

  const handleSubmit = useCallback(
    async ({ email }: FormikValues) => {
      const authProvider = await getAuthProvider(email);
      if (!authProvider) onSubmit(email);
    },
    [onSubmit, getAuthProvider]
  );

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }: FormikProps<FormikValues>) => (
        <GridContainer spacing={0}>
          <GridItem xs={12}>
            <FormTextField
              id="email"
              name="email"
              label="i18n.signin.form.email.placeholder"
              placeholder="i18n.signin.form.email.placeholder"
              data-testid="email-input"
              autoCapitalize="none"
            />
          </GridItem>
          <GridItem>
            <RouterButton to="signin" variant="tertiary">
              i18n.auth.button.backToLogin
            </RouterButton>
          </GridItem>
          <GridItem ml="auto" minWidth={150}>
            <FormSubmitButton
              fullWidth
              data-testid="forgot-password-continue-button"
              startIcon={isSubmitting ? <SpinningIcon type="GearIcon" size="s" /> : null}
            >
              i18n.forgot.form.submit
            </FormSubmitButton>
          </GridItem>
        </GridContainer>
      )}
    </Form>
  );
};
