import { IconButton, PopoverMenuItem, Responsive, Spacing, withPopoverMenu } from '@cofenster/web-components';
import { type FC, memo, useMemo } from 'react';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';
import { useGotoProjectEditScene } from '../../../hooks/navigation/useGotoProjectEditScene';
import { useProjectDownloadWithRerender } from '../../../hooks/project/useProjectDownloadWithRerender';
import { getRenderJobStatus } from '../../../hooks/project/useProjectExportStatus';
import { useDownloadSubtitle } from '../../../hooks/subtitles/useDownloadSubtitle';
import { useI18n } from '../../../i18n';
import { subtitlesToSrt } from '../../ProjectSceneSubtitleEdit/ProjectSceneSubtitleEditDialog/SRTEditor/utils';
import { useGoToSceneSubtitleEdit } from '../../ProjectSceneSubtitleEdit/useGoToSceneSubtitleEdit';
import type { Project } from '../ProjectVideoContent';

export const MoreActionsButton: FC<{
  project: Project;
  subtitlesLanguage: string | null;
  renderJob: RenderJob | null | undefined;
  firstSceneId: string | null | undefined;
}> = memo(({ renderJob, subtitlesLanguage, firstSceneId, project }) => {
  const projectId = project.id;
  const goToSceneSubtitleEdit = useGoToSceneSubtitleEdit();
  const goToProjectEdit = useGotoProjectEditScene(projectId);
  const downloadVideo = useProjectDownloadWithRerender(project, renderJob ?? undefined);
  const subtitleNodes = renderJob?.renderDescription?.subtitles || [];
  const status = getRenderJobStatus(renderJob);

  const srt = useMemo(() => subtitlesToSrt(subtitleNodes), [subtitleNodes]);
  const downloadSubtitle = useDownloadSubtitle({
    id: `${project.name}-${renderJob?.subtitlesLanguage}`,
    srt,
    subtitleType: 'project',
  });
  const { translate } = useI18n();

  const children = useMemo(
    () =>
      [
        !subtitlesLanguage && status !== 'RENDERING' && (
          <PopoverMenuItem
            key="rerender"
            onClick={() => goToProjectEdit()}
            icon="PencilIcon"
            data-testid="rerender-button"
          >
            i18n.projectVideo.exportListCard.moreMenu.editVideo
          </PopoverMenuItem>
        ),
        subtitlesLanguage && firstSceneId && (
          <PopoverMenuItem
            key="edit-subtitles"
            onClick={() => goToSceneSubtitleEdit(projectId, firstSceneId, subtitlesLanguage)}
            icon="SubtitlesIcon"
          >
            i18n.projectVideo.exportListCard.moreMenu.editSubtitles
          </PopoverMenuItem>
        ),
        subtitlesLanguage && renderJob && (
          <PopoverMenuItem
            key="download-subtitles-vtt"
            onClick={() => downloadSubtitle('VTT')}
            data-testid="download-vtt-button"
            icon="DownloadIcon"
          >
            {translate('projectVideo.renderedVideo.downloadSubtitles', { format: 'VTT' })}
          </PopoverMenuItem>
        ),
        subtitlesLanguage && renderJob && (
          <PopoverMenuItem
            key="download-subtitles-srt"
            onClick={() => downloadSubtitle('SRT')}
            data-testid="download-srt-button"
            icon="DownloadIcon"
          >
            {translate('projectVideo.renderedVideo.downloadSubtitles', { format: 'SRT' })}
          </PopoverMenuItem>
        ),
        renderJob?.videoAsset?.downloadUrl && (
          <Responsive down="sm" key="download">
            <PopoverMenuItem icon="DownloadIcon" onClick={downloadVideo}>
              i18n.global.download
            </PopoverMenuItem>
          </Responsive>
        ),
      ].filter(Boolean),
    [
      status,
      projectId,
      renderJob,
      subtitlesLanguage,
      firstSceneId,
      goToSceneSubtitleEdit,
      downloadVideo,
      downloadSubtitle,
      translate,
      goToProjectEdit,
    ]
  );

  const MoreActionsPopoverButton = useMemo(
    () =>
      withPopoverMenu(IconButton, {
        children: <Spacing bottom={2}>{children}</Spacing>,
      }),
    [children]
  );

  if (status === 'RENDERING' || children.length === 0) return null;

  return (
    <MoreActionsPopoverButton
      icon="ThreeDotsIcon"
      label="i18n.global.more"
      data-testid="rendered-video-card-more-actions-button"
    />
  );
});
