import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
} from '@cofenster/web-components';
import type { FormikValues } from 'formik';
import { useCreateTemplateFromProject } from '../../../api/hooks/projectTemplate/useCreateTemplateFromProject';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export type CreateProjectTemplateFromProjectDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectId: string;
  projectName: string;
  goToProjectEdit: (projectId: string) => void;
  goToProjectTemplates: VoidFunction;
};

type Values = {
  name: string;
  description?: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().notRequired(),
});

const useInitialValues = (name: string) => {
  return useMemo<Values>(
    () => ({
      name,
    }),
    [name]
  );
};

const useSubmit = (projectId: string, moveToSuccessState: (templateProjectId: string) => void) => {
  const [createProjectTemplateFromProject] = useCreateTemplateFromProject();
  const tracking = useWebManagerTracking();

  const trackCreation = useCallback(
    (name: string) =>
      tracking.trackEvent({
        event: 'projectTemplateCreated',
        details: {
          name,
        },
      }),
    [tracking]
  );

  return useCallback(
    async (values: FormikValues) => {
      const formValues = values as Values;
      const result = await createProjectTemplateFromProject(projectId, {
        name: formValues.name,
        description: formValues.description,
      });
      trackCreation(formValues.name);
      const templateProjectId = result.data?.createProjectTemplateFromProject.project.id;
      if (!templateProjectId) throw new Error('Unexpected error while creating project template');
      moveToSuccessState(templateProjectId);
    },
    [createProjectTemplateFromProject, trackCreation, projectId, moveToSuccessState]
  );
};

export const CreateProjectTemplateFromProjectDialog: FC<CreateProjectTemplateFromProjectDialogProps> = ({
  isOpen,
  closeDialog,
  projectId,
  projectName,
  goToProjectEdit,
  goToProjectTemplates,
}) => {
  const [step, setStep] = useState<'FORM' | 'SUCCESS'>('FORM');
  const [templateProjectId, setTemplateProjectId] = useState<string | null>(null);
  const moveToSuccessState = useCallback((templateProjectId: string) => {
    setTemplateProjectId(templateProjectId);
    setStep('SUCCESS');
  }, []);

  const initialValues = useInitialValues(projectName);
  const onSubmit = useSubmit(projectId, moveToSuccessState);

  const onGoToProjectEdit = useCallback(() => {
    if (templateProjectId) {
      goToProjectEdit(templateProjectId);
    }
    closeDialog();
  }, [templateProjectId, goToProjectEdit, closeDialog]);

  const onViewTemplates = useCallback(() => {
    goToProjectTemplates();
    closeDialog();
  }, [goToProjectTemplates, closeDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="create-project-template-from-project-dialog"
      title={
        step === 'FORM'
          ? 'i18n.projectTemplates.createProjectTemplateFromProject.dialog.headline'
          : 'i18n.projectTemplates.createProjectTemplateFromProject.dialog.success.headline'
      }
    >
      {step === 'FORM' ? (
        <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <DialogContent>
            <Spacing bottom={3}>
              <Text variant="l" color="grey600" component="p">
                i18n.projectTemplates.createProjectTemplateFromProject.dialog.subheadline
              </Text>
            </Spacing>
            <FormTextField
              id="name"
              name="name"
              label="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.name"
              fullWidth
            />
            <FormTextField
              id="description"
              name="description"
              label="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.description"
              placeholder="i18n.projectTemplates.createProjectTemplateFromProject.dialog.form.descriptionPlaceholder"
              fullWidth
              multiline
              rows={5}
            />
          </DialogContent>

          <DialogActions>
            <Button variant="tertiary" fullWidth onClick={closeDialog}>
              i18n.global.cancel
            </Button>
            <FormSubmitButton fullWidth data-testid="create-project-template-from-project-dialog-confirm">
              i18n.projectTemplates.createProjectTemplateFromProject.dialog.submitButton
            </FormSubmitButton>
          </DialogActions>
        </Form>
      ) : (
        <>
          <DialogContent>
            <Spacing bottom={3}>
              <Text variant="l" color="grey600" component="p">
                i18n.projectTemplates.createProjectTemplateFromProject.dialog.success.subheadline
              </Text>
            </Spacing>
          </DialogContent>
          <DialogActions>
            <Button variant="secondary" fullWidth onClick={onViewTemplates}>
              i18n.projectTemplates.createProjectTemplateFromProject.dialog.success.goToTemplates
            </Button>
            <Button variant="primary" fullWidth onClick={onGoToProjectEdit}>
              i18n.projectTemplates.createProjectTemplateFromProject.dialog.success.goToEditTemplate
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
