import type { ChangeEventHandler, FC } from 'react';

import { GridContainer, GridItem, IconButton, Switch, Tooltip, Typography } from '@cofenster/web-components';

import type { Project } from '../../../api/hooks/project/useProject';
import { useDialogs } from '../../../contexts/dialogs';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';

export const SharingLinkSwitch: FC<{
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  project: Project;
}> = ({ checked, onChange, project }) => {
  const hasSharingLinkCreatePermissions = useTeamPermissionStatus({ has: 'SharingLinkCreate' }) === 'ALLOWED';
  const { openDialog } = useDialogs();

  return (
    <GridContainer justifyContent="space-between" alignItems="center">
      <GridItem>
        <Switch
          id="sharing-link"
          checked={checked}
          onChange={onChange}
          data-testid="sharing-link-switch"
          disabled={Boolean(project.archivedAt) || !hasSharingLinkCreatePermissions}
        >
          {hasSharingLinkCreatePermissions ? (
            <Typography>i18n.videoAnalytics.enableLink</Typography>
          ) : (
            <Tooltip title="i18n.videoAnalytics.sharingLinkDisabledTooltip">
              <Typography>i18n.videoAnalytics.enableLink</Typography>
            </Tooltip>
          )}
        </Switch>
      </GridItem>
      <GridItem>
        <IconButton
          icon="GearIcon"
          label="i18n.videoAnalytics.sharingLinkSettingsButtonLabel"
          onClick={() => openDialog('SharingLinkSettingsDialog', { projectId: project.id })}
        />
      </GridItem>
    </GridContainer>
  );
};
