import type { FC } from 'react';

import { LogoIcon, VisuallyHidden, styled } from '@cofenster/web-components';

import type { Project } from '../../contexts/project/useProject';
import { RouterButton } from '../button/RouterButton';

const Wrapper = styled(RouterButton)(() => ({
  padding: 0,
  minWidth: 0,
  '&:hover': { backgroundColor: 'transparent' },
}));

const Logo = styled(LogoIcon)(({ theme }) => ({
  backgroundColor: theme.palette.brand.carbon,
  borderRadius: theme.shape['borderRadius-l'],
  width: theme.spacing(7),
  height: theme.spacing(7),

  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.shape.borderRadius,
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

export const BackToFolderButton: FC<{ project?: Project }> = ({ project }) => {
  return (
    <Wrapper
      to={!project ? 'home' : 'projectFolder'}
      params={{ folderId: project?.projectFolders[0]?.id }}
      withViewTransition
      variant="tertiary"
      data-testid="page-back-button"
    >
      <Logo color="white" size={41} />
      <VisuallyHidden>i18n.ScenePage.GoBackButton.label</VisuallyHidden>
    </Wrapper>
  );
};
