import type { ComponentProps, FC } from 'react';

import { MainNavigation, VisuallyHidden, withPopoverMenu } from '@cofenster/web-components';

import type { User } from '../../../../../../contexts/user/useUser';

import { UserProfilePopover } from './UserProfilePopover';

export type UserProfileButtonProps = Omit<ComponentProps<typeof MainNavigation.ProfileButton>, 'children' | 'name'> & {
  user: User;
};

export const UserProfileButton: FC<UserProfileButtonProps> = ({ user, ...rest }) => {
  const userName = user.firstname || user.lastname || '';

  const ProfileButton = withPopoverMenu(MainNavigation.ProfileButton, {
    children: <UserProfilePopover user={user} />,
    anchorOriginX: 'left',
    anchorOriginY: 'top',
    transformOriginY: 'bottom',
  });

  return (
    <ProfileButton {...rest} name={userName}>
      <span aria-hidden="true">{userName}</span>
      <VisuallyHidden>i18n.mainNavigation.userMenu</VisuallyHidden>
    </ProfileButton>
  );
};
