const STAGE = process.env.STAGE;
const ENVIRONMENT_DOMAIN = process.env.ENVIRONMENT_DOMAIN ?? 'cofenster.com';

export const CAPTURE_URL = STAGE === 'local' ? 'http://localhost:8090' : `https://capture.${ENVIRONMENT_DOMAIN}`;
export const MANAGER_URL = STAGE === 'local' ? 'http://localhost:8080' : `https://app.${ENVIRONMENT_DOMAIN}`;
export const ADMIN_URL = STAGE === 'local' ? 'http://localhost:8060' : `https://admin.${ENVIRONMENT_DOMAIN}`;
export const VIDEO_URL = STAGE === 'local' ? 'http://localhost:8070' : `https://video.${ENVIRONMENT_DOMAIN}`;
export const MAINTENANCE_URL =
  STAGE === 'local' ? 'http://localhost:8050' : `https://maintenance.${ENVIRONMENT_DOMAIN}`;
export const ASSET_URL = `https://assets.${ENVIRONMENT_DOMAIN}`;

export const API_URL =
  STAGE === 'local' ? 'http://localhost:5000/graphql' : `https://api.${ENVIRONMENT_DOMAIN}/graphql`;
export const ADMIN_API_URL =
  STAGE === 'local' ? 'http://localhost:5010/graphql' : `https://admin.api.${ENVIRONMENT_DOMAIN}/graphql`;
export const SERVICES_API_URL =
  STAGE === 'local' ? 'http://localhost:5020/graphql' : `https://services.api.${ENVIRONMENT_DOMAIN}/graphql`;
