import type { FC } from 'react';

import { GridContainer, GridItem, ResultPagination } from '@cofenster/web-components';

import type { PaginatedProjects } from '../../api/hooks/project/useSearchProjects';
import { useSearchFilter } from '../../contexts/searchFilter/useSearchFilter';

import { HomeProjects } from './HomeProjects';

export const HomeProjectSearchResults: FC<{
  paginatedProjects?: PaginatedProjects;
}> = ({ paginatedProjects }) => {
  const { page, setPage } = useSearchFilter();

  return (
    <>
      <HomeProjects projects={paginatedProjects?.items ?? []} refetchQueries={['SearchProjects']} />

      {paginatedProjects && paginatedProjects?.total > 0 && (
        <GridContainer justifyContent="center" pt={3}>
          <GridItem>
            <ResultPagination
              total={paginatedProjects.total}
              limit={paginatedProjects.limit || paginatedProjects.total}
              page={page}
              onChange={setPage}
            />
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};
