import { GridContainer, GridItem, LoadingSpinner, styled } from '@cofenster/web-components';

import { useStoriesByAccount } from '../../api/hooks/story/useStoriesByAccount';
import { RouterButton } from '../../components/button/RouterButton';
import { InspirationsCard } from '../Inspirations/InspirationsCard';

import { HomeSection } from './HomeSection';

const MoreButton = styled(RouterButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.linen50,
  '&:hover, &:focus': { backgroundColor: theme.palette.brand.white },
}));

export const HomeInspirations = () => {
  const { loading, storiesByAccount } = useStoriesByAccount({});

  if (loading) {
    return (
      <HomeSection title="i18n.home.inspirations.title">
        <LoadingSpinner spacing={10} />
      </HomeSection>
    );
  }

  return (
    <HomeSection title="i18n.home.inspirations.title">
      <GridContainer mt={0}>
        {storiesByAccount.slice(0, 3).map((story) => (
          <GridItem xs={12} md={6} lg={4} key={story.id}>
            <InspirationsCard story={story} />
          </GridItem>
        ))}

        <GridItem xs={12} display="flex" justifyContent="center" mt={2}>
          <MoreButton variant="tertiary" to="inspirations">
            i18n.home.inspirations.more
          </MoreButton>
        </GridItem>
      </GridContainer>
    </HomeSection>
  );
};
