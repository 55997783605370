import { PopoverMenuItem } from '@cofenster/web-components';
import { useMemo } from 'react';
import type { ColorSceneAsset } from '../../../../../api/hooks/scene/useScenes';
import { useProject } from '../../../../../contexts/project/useProject';
import type { Scene } from '../../../../../contexts/scenes/useScenes';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { DeleteSceneAssetPopoverMenuItem } from '../components/DeleteSceneAssetPopoverMenuItem';
import {
  AddTransitionPopoverMenuItem,
  CopyPopoverMenuItem,
  DeletePopoverMenuItem,
  DuplicatePopoverMenuItem,
  InsertScenePopoverMenuItem,
  PastePopoverMenuItem,
  ToggleSceneFromVideo,
} from '../components/ScenePopoverActions';
import { useAddPopoverDividers } from './useAddPopoverDividers';
import { useOnMoveToProject } from './useOnMoveToProject';

type Props = {
  scene: Scene;
  colorSceneAsset: ColorSceneAsset | undefined;
};

export const useSceneColorActionsPopoverContent = ({ scene, colorSceneAsset }: Props) => {
  const { project } = useProject();
  const isAllowedToUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const addPopoverDividers = useAddPopoverDividers();
  const onMoveToProject = useOnMoveToProject(project?.id ?? '', scene.id);

  return useMemo(() => {
    const isProjectArchived = Boolean(project?.archivedAt);
    const canUpdateProject = !isProjectArchived && isAllowedToUpdateProject;
    const hasAsset = Boolean(colorSceneAsset?.asset?.colorAsset?.color);

    const add = [
      canUpdateProject && (
        <InsertScenePopoverMenuItem
          key="insert-scene"
          hasContributionRequestListAvailable={project?.hasContributionRequestListAvailable ?? false}
          sceneId={scene.id}
        />
      ),
    ];

    const top = [
      canUpdateProject && <AddTransitionPopoverMenuItem scene={scene} key="add-transition" />,
      canUpdateProject && <ToggleSceneFromVideo key="toggle-scene-visibility" scene={scene} />,
    ].filter(Boolean);

    const middle = [
      <CopyPopoverMenuItem key="copy-scene" scene={scene} />,
      <PastePopoverMenuItem key="paste-scene" projectId={project?.id} scene={scene} />,
      canUpdateProject && <DuplicatePopoverMenuItem key="duplicate-scene" scene={scene} />,
      canUpdateProject && <DeletePopoverMenuItem key="delete-scene" scene={scene} />,
    ].filter(Boolean);

    const bottom = [
      isAllowedToUpdateProject && (
        <PopoverMenuItem
          key="scene-move-to-project"
          icon="FolderPlusIcon"
          data-testid="move-scenes-to-project"
          onClick={onMoveToProject}
        >
          i18n.projectEditor.sceneAssetThumbs.popover.actions.moveToProject
        </PopoverMenuItem>
      ),
      hasAsset && canUpdateProject && <DeleteSceneAssetPopoverMenuItem key="delete-asset" scene={scene} />,
    ].filter(Boolean);

    return addPopoverDividers([add, top, middle, bottom]);
  }, [
    project?.id,
    project?.archivedAt,
    isAllowedToUpdateProject,
    colorSceneAsset?.asset?.colorAsset?.color,
    scene,
    addPopoverDividers,
    project?.hasContributionRequestListAvailable,
    onMoveToProject,
  ]);
};
