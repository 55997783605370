import { type FC, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { EmptyState, GridContainer, GridItem, Icon, Typography, useSnackbars } from '@cofenster/web-components';

import type { ContributionRequestById } from '../../../../api/hooks/contributionRequest/useContributionRequestsById';
import { useI18n } from '../../../../i18n';
import { ContributionAssetListItem } from './ContributionAssetListItem';

export const ContributionRequestDialogContent: FC<{
  contributionRequest: ContributionRequestById;
  videoFormat: VideoFormat;
  totalContributions: number;
  canUpdateProject: boolean;
}> = ({ contributionRequest, videoFormat, totalContributions, canUpdateProject }) => {
  const { openSnackbar } = useSnackbars();
  const { imageAssets, videoAssets } = contributionRequest;
  const assets = useMemo(
    () =>
      [...imageAssets, ...videoAssets]
        .filter((asset) => asset.status === 'Ready')
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
    [imageAssets, videoAssets]
  );
  const { translate } = useI18n();

  return (
    <GridContainer>
      {!assets.length ? (
        <GridItem xs={12}>
          <EmptyState
            title="i18n.ContributionRequestsDialog.emptyState.title"
            description="i18n.ContributionRequestsDialog.emptyState.description"
            backgroundColor="white"
            icon={<Icon type="SearchIcon" size="m" background={{ size: 'xl', color: 'grey100' }} />}
          />
        </GridItem>
      ) : (
        <>
          <GridItem xs={12}>
            <Typography variant="h5" i18nParams={{ count: totalContributions }} component="p">
              i18n.projectContributions.ContributionRequestCard.contributionsCount
            </Typography>
          </GridItem>
          {assets?.map((asset, index) => (
            <GridItem key={asset.id ?? index} xs={12} sm={6} md={4}>
              <ContributionAssetListItem
                asset={asset}
                videoFormat={videoFormat}
                contributionRequestId={contributionRequest.id}
                contributionRequestTitle={
                  contributionRequest.title
                    ? contributionRequest.title
                    : translate('projectContributionConfiguration.filter.byRequests.emptyCRTitle', {
                        index: contributionRequest.index + 1,
                      })
                }
                scenesCount={asset.scenes?.length ?? 0}
                onSceneCreated={() => {
                  openSnackbar({
                    variant: 'info',
                    children: 'i18n.ContributionRequestsDialog.snackbar.contributionAdded',
                  });
                }}
                canUpdateProject={canUpdateProject}
              />
            </GridItem>
          ))}
        </>
      )}
    </GridContainer>
  );
};
