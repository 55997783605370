import { type FC, useCallback } from 'react';

import { Responsive, SearchField, type SelectChangeEvent, useI18n } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolder';
import type { SearchFilterContext } from '../../../contexts/searchFilter/SearchFilterContext';
import { useSearchFilter } from '../../../contexts/searchFilter/useSearchFilter';
import { CreatedBySelect } from '../CreatedBySelect';
import { StatusSelect } from '../StatusSelect';

import { ProjectFolderIcons } from './ProjectFolderIcons';

type ProjectFolderIconsProps = {
  projectFolder?: ProjectFolder;
};

export const ProjectFolderIconsWithSearch: FC<ProjectFolderIconsProps> = ({ projectFolder }) => {
  const { translate } = useI18n();
  const { filter, setSearch, setCreatedBy, setStatus } = useSearchFilter();

  const searchLabel = translate('global.search.term', { term: translate('common.projects') });
  const onCreatedByChange = useCallback(
    (event: SelectChangeEvent<unknown>) =>
      setCreatedBy(event.target.value as SearchFilterContext['filter']['createdBy']),
    [setCreatedBy]
  );
  const onStatusChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setStatus(event.target.value as SearchFilterContext['filter']['status']);
    },
    [setStatus]
  );

  return (
    <ProjectFolderIcons>
      <SearchField label={searchLabel} id="projects" name="project" search={filter.search ?? ''} onSearch={setSearch} />

      {projectFolder?.team && (
        <Responsive up="md">
          <CreatedBySelect value={filter.createdBy} onChange={onCreatedByChange} />
        </Responsive>
      )}

      <Responsive up="sm">
        <StatusSelect value={filter.status} onChange={onStatusChange} />
      </Responsive>
    </ProjectFolderIcons>
  );
};
