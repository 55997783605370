import { type FC, useMemo } from 'react';

import { GridContainer, GridItem, LoadingSpinner } from '@cofenster/web-components';

import { ProjectLayout } from '../../components/page/Layout/ProjectLayout';
import { type Project as ProjectType, type RenderJob, useProject } from '../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../contexts/user/TeamPermissionRestriction';
import { type RenderJobStatus, getRenderJobStatus } from '../../hooks/project/useProjectExportStatus';
import { useProjectPostEditingHeader } from '../../hooks/useProjectHeader';
import { NoAccessContent } from '../NoAccess';

import { useRenderJobsByProject } from '../../api/hooks/renderJob/useRenderJobsByProject';
import { ExportsList } from './ExportsList';
import { RenderProgress } from './RenderProgress';
import { AnalyticsAndSharingLinkSettings } from './SharingLink/Analytics/AnalyticsAndSharingLinkSettings';
import { useSubtitlesLanguage } from './SubtitlesLanguageContext';

export type Project = NonNullable<ProjectType>;

export const ProjectVideoContent: FC<{ projectId: string }> = ({ projectId }) => {
  const { project, loading, refetch } = useProject();
  const { subtitlesLanguage } = useSubtitlesLanguage();
  const { renderJobs } = useRenderJobsByProject(projectId, refetch);
  const currentTabRenderJob = useMemo(
    () =>
      renderJobs?.find((job) => {
        const isMainVideoRenderJob = !subtitlesLanguage && !job.subtitlesLanguage;
        const isCurrentLanguageRenderJob = job.subtitlesLanguage === subtitlesLanguage;
        return isCurrentLanguageRenderJob || isMainVideoRenderJob;
      }),
    [renderJobs, subtitlesLanguage]
  );
  const status = getRenderJobStatus(currentTabRenderJob);

  const projectHeader = useProjectPostEditingHeader();

  const withAccessToSharingLink = useTeamPermissionStatus({ has: 'SharingLinkRead' }) === 'ALLOWED';

  return (
    <ProjectLayout documentTitle={project?.name ?? ''} header={projectHeader} contentContainerMaxWidth={'1380px'}>
      {project ? (
        <ProjectVideoContentWrapper
          project={project}
          renderJob={currentTabRenderJob}
          status={status}
          loadingRenderJob={!renderJobs}
          withAccessToSharingLink={withAccessToSharingLink}
          refetchProject={refetch}
        />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <NoAccessContent resource="Project" />
      )}
    </ProjectLayout>
  );
};

const ProjectVideoContentWrapper: FC<{
  project: Project;
  withAccessToSharingLink: boolean;
  renderJob: RenderJob | undefined;
  loadingRenderJob: boolean;
  status: RenderJobStatus;
  refetchProject: VoidFunction;
}> = ({ project, renderJob, withAccessToSharingLink, loadingRenderJob, status, refetchProject }) => {
  if (loadingRenderJob && !renderJob) {
    return <LoadingSpinner />;
  }

  return (
    <GridContainer mt={2}>
      <GridItem xs={12} lg={7}>
        <GridContainer>
          <GridItem xs={12}>
            <RenderProgress project={project} status={status} renderJob={renderJob} />
          </GridItem>
          <GridItem xs={12}>
            <ExportsList project={project} refetchProject={refetchProject} />
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12} lg={5}>
        {withAccessToSharingLink && <AnalyticsAndSharingLinkSettings project={project} />}
      </GridItem>
    </GridContainer>
  );
};
