import { useCallback } from 'react';

import { useProjectNeedsRerender } from '../../api/hooks/project/useProjectNeedsRerender';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import type { Project, RenderJob } from '../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../contexts/user/TeamPermissionRestriction';
import { useI18n } from '../../i18n/useI18n';

import { useStartSafeRender } from './useConditionalStartRender';
import { useDownloadExportedVideo } from './useDownloadExportedVideo';
import { useIsAllowedToRenderProject } from './useIsAllowedToRenderProject';

export const useProjectDownloadWithRerender = (project: Project, renderJob: RenderJob | undefined) => {
  const { projectNeedsRerender } = useProjectNeedsRerender(project.id, renderJob?.subtitlesLanguage);
  const hasDownloadPermissions = useTeamPermissionStatus({ has: 'VideoDownload' }) === 'ALLOWED';
  const isAllowedToRenderProject = useIsAllowedToRenderProject(project.id, renderJob?.subtitlesLanguage ?? null);
  const download = useDownloadExportedVideo(project, 'projectFinalise', renderJob?.subtitlesLanguage ?? null);
  const { translate } = useI18n();
  const { openDialog } = useDialogs();
  const { startSafeRender, loading: hasInitiatedRender } = useStartSafeRender(
    project.id,
    renderJob?.subtitlesLanguage ?? null
  );

  const downloadUrl = renderJob?.videoAsset?.downloadUrl;
  const disabled = !downloadUrl || !hasDownloadPermissions;
  const hasPendingChanges = projectNeedsRerender?.status === 'NEEDS_RERENDER';

  const handleDownload = useCallback(async () => {
    if (disabled) return;

    if (hasPendingChanges) {
      openDialog('ChoiceDialog', {
        title: translate('dialogs.projectVideoPendingChangesDownloadDialog.headline'),
        content: translate('dialogs.projectVideoPendingChangesDownloadDialog.subline'),
        left: translate('dialogs.projectVideoPendingChangesDownloadDialog.download'),
        right: translate('dialogs.projectVideoPendingChangesDownloadDialog.update'),
        onLeft: download,
        onRight: startSafeRender,
        rightProps: { disabled: !isAllowedToRenderProject, loading: hasInitiatedRender },
      });
    } else {
      await download();
    }
  }, [
    disabled,
    hasPendingChanges,
    openDialog,
    translate,
    download,
    startSafeRender,
    isAllowedToRenderProject,
    hasInitiatedRender,
  ]);

  return disabled ? undefined : handleDownload;
};
