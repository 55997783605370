import type { FC } from 'react';
import removeMarkdown from 'remove-markdown';

import { Card, Headline, Text, styled } from '@cofenster/web-components';

import type { Story } from '../../api/hooks/story/useStoriesByAccount';
import { ExpandedRouterLink } from '../../components/button/ExpandedRouterLink';
import { useI18n } from '../../i18n';

const ImgWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  borderTopLeftRadius: theme.shape['borderRadius-l'],
  borderTopRightRadius: theme.shape['borderRadius-l'],
}));

const Image = styled('img')(() => ({
  width: '100%',
  maxHeight: 140,
  objectFit: 'cover',
}));

const Description = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

type InspirationCardProps = {
  story: Story;
};

const Ellipsis = styled(Text)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'block',

  [theme.breakpoints.up('md')]: {
    whiteSpace: 'nowrap',
  },
}));

export const InspirationsCard: FC<InspirationCardProps> = ({ story }) => {
  const { locale } = useI18n();

  return (
    <Card data-testid="inspiration-card" spacing={{ all: 0 }}>
      {story.previewImage.imageUrl && (
        <ImgWrapper>
          <Image
            src={story.previewImage.imageUrl}
            alt={locale === 'DE' ? story.name : story.nameEN}
            width={352}
            height={140}
          />
        </ImgWrapper>
      )}
      <Description>
        <Headline variant="h5" component="p">
          <ExpandedRouterLink to="inspiration" params={{ inspirationId: story.id }}>
            {locale === 'DE' ? story.name : story.nameEN}
          </ExpandedRouterLink>
        </Headline>
        <Ellipsis variant="s" color="grey700" component="p" aria-hidden="true">
          {removeMarkdown(locale === 'DE' ? story.description : story.descriptionEN, {
            gfm: false,
            useImgAltText: false,
          })}
        </Ellipsis>
      </Description>
    </Card>
  );
};
