import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoProjectHandler = (projectId?: string) => {
  const goto = useGoto();
  return useCallback(
    (_projectId?: string, state?: unknown) => {
      goto(routes.project, { projectId: _projectId ?? projectId }, { state });
    },
    [goto, projectId]
  );
};
