import { useField } from 'formik';
import { type FC, useMemo, useState } from 'react';

import { Autocomplete, TextField, UserChip, styled } from '@cofenster/web-components';

import type { CurrentTeamContext } from '../../../../contexts/currentTeam/CurrentTeamContext';
import { useUser } from '../../../../contexts/user/useUser';

import { NoOptions } from './NoOptions';
import { Option } from './Option';

const UserChipsContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiAutocomplete-input': {
    paddingTop: `${theme.spacing(1.5)} !important`,
    paddingBottom: `${theme.spacing(1.5)} !important`,
  },
}));

export type OptionType = {
  user: NonNullable<ReturnType<typeof useUser>['user']>['account']['users'][number];
  teamRole: 'owner' | 'member' | undefined;
};

const useOptions = (team: UserAutocompleteProps['team'], selectedOptions: OptionType[]) => {
  const { user } = useUser();
  return useMemo(() => {
    if (!user) return [];
    const options: OptionType[] = user.account.users.map((user) => ({
      user,
      teamRole:
        team.creator?.id === user.id
          ? 'owner'
          : team.members.find((member) => user.id === member.id)
            ? 'member'
            : undefined,
    }));
    return options.filter((option) => {
      return !selectedOptions.find((selectedOption) => selectedOption.user.id === option.user.id);
    });
  }, [user, team, selectedOptions]);
};

const getName = (option: OptionType) => `${option.user.firstname} ${option.user.lastname}`;

export type UserAutocompleteProps = {
  team: NonNullable<CurrentTeamContext['team']>;
  name: string;
};

export const UserAutocomplete: FC<UserAutocompleteProps> = ({ team, name }) => {
  const [field, , helpers] = useField(name);

  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

  const options = useOptions(team, selectedOptions);

  return (
    <Autocomplete<OptionType, true>
      multiple
      id="add-members-select"
      clearIcon={null}
      forcePopupIcon={false}
      noOptionsText={<NoOptions />}
      options={options}
      defaultValue={[]}
      onBlur={field.onBlur}
      onChange={(_event, values) => {
        setSelectedOptions(values);
        helpers.setValue(values.map((value) => value.user.id));
      }}
      renderInput={(props) => {
        return (
          <StyledTextField
            {...props}
            variant="outlined"
            label="i18n.dialogs.addTeamMembersDialog.label"
            placeholder={selectedOptions.length ? undefined : 'i18n.dialogs.addTeamMembersDialog.placeholder'}
            inputProps={{ ...props.inputProps, 'data-testid': 'add-members-input' }}
            autoFocus
          />
        );
      }}
      getOptionLabel={getName}
      getOptionDisabled={(option) => option.teamRole !== undefined}
      renderOption={(props, option) => (
        <li {...props}>
          <Option name={getName(option)} email={option.user.email} teamRole={option.teamRole} />
        </li>
      )}
      renderTags={(options, getTagProps) => {
        return (
          <UserChipsContainer data-testid="member-chip">
            {options.map((option, index) => {
              const tagProps = getTagProps({ index });
              return (
                <UserChip key={tagProps.key} onDelete={{ handler: tagProps.onDelete, label: 'i18n.common.remove' }}>
                  {getName(option)}
                </UserChip>
              );
            })}
          </UserChipsContainer>
        );
      }}
    />
  );
};
