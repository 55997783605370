///<reference types="webpack-env" />

import type { ComponentType } from 'react';
import { type Root, createRoot } from 'react-dom/client';

import { App } from './App';

let root: Root | null = null;

const render = (Component: ComponentType) => {
  if (!root) {
    const container = document.getElementById('root');

    if (!container) {
      throw new Error('Cannot find root container for React');
    }

    root = createRoot(container);
  }

  root.render(<Component />);
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').App;
    render(NextApp);
  });
}
