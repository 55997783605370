import { styled } from '@mui/material';
import type { FC, FunctionComponent, PropsWithChildren } from 'react';

import { Container } from '../Container';
import { AppHeader, type AppHeaderProps } from './AppHeader';
import { DocumentTitleAnnouncer } from './DocumentTitleAnnouncer';

const OverallContainer = styled('div')(({ theme }) => ({
  minHeight: '100%',
  backgroundColor: theme.palette.brand.linen,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

// 1. Prevent flex children with _nowrap_ content from overflowing out of this
//    container.
const PageContentArea = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 0, // 1

  [theme.breakpoints.up('sm')]: {
    paddingBottom: `var(--page-padding-bottom, ${theme.spacing(6)})`,
  },
}));

export const FlexContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  width: '100%',
}));

const PageContent = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

export const PageContentContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <FlexContainer>
      <PageContent>{children}</PageContent>
    </FlexContainer>
  );
};

export type BaseLayoutProps = PropsWithChildren<{
  header?: AppHeaderProps;
  navigation?: FunctionComponent | null;
  documentTitle: string;
  OverallContainerComponent?: FunctionComponent;
  MainContentAreaComponent?: FunctionComponent;
}>;

export const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  header,
  navigation: Navigation,
  documentTitle,
  OverallContainerComponent = OverallContainer,
  MainContentAreaComponent = PageContentContainer,
}) => (
  <>
    <DocumentTitleAnnouncer documentTitle={documentTitle} />

    <OverallContainerComponent>
      {Navigation && <Navigation />}
      <PageContentArea id="content">
        {header && <AppHeader {...header} />}
        <MainContentAreaComponent>{children}</MainContentAreaComponent>
      </PageContentArea>
    </OverallContainerComponent>
  </>
);
