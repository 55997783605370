import { useCallback, useMemo, useState } from 'react';

import { useSnackbars } from '@cofenster/web-components';

import { useCanRenderProject } from '../../api/hooks/project/useCanRenderProject';
import { useRenderProject } from '../../api/hooks/project/useRenderProject';
import type { MaybeSubtitlesLanguage } from '../../api/hooks/renderDescription/types';
import { useProject } from '../../contexts/project/useProject';
import { useGotoProjectVideo } from '../navigation/useGotoProjectVideo';

import { useIsAllowedToRenderProject } from './useIsAllowedToRenderProject';
import { useProjectExportStatus } from './useProjectExportStatus';

export const useStartSafeRender = (
  projectId: string,
  subtitlesLanguage: MaybeSubtitlesLanguage,
  { withNavigation }: { withNavigation: boolean } = { withNavigation: false }
) => {
  const [requestingRendering, setRequestingRendering] = useState(false);
  const { openSnackbar } = useSnackbars();
  const { renderProject, loading } = useRenderProject(projectId, { subtitlesLanguage });
  const { refetch: getCanRenderProject } = useCanRenderProject(projectId);

  const startRender = useCallback(() => renderProject(), [renderProject]);
  const gotoProjectVideo = useGotoProjectVideo(projectId);

  const ensureReadyToRender = useCallback(async () => {
    const { data } = await getCanRenderProject();
    return !!data.canRenderProject;
  }, [getCanRenderProject]);

  const startSafeRender = useCallback(async () => {
    try {
      setRequestingRendering(true);
      if (await ensureReadyToRender()) {
        await startRender();
        if (withNavigation) gotoProjectVideo();
      } else openSnackbar({ children: 'i18n.common.exportVideo.error.general' });
    } finally {
      setRequestingRendering(false);
    }
  }, [ensureReadyToRender, openSnackbar, startRender, withNavigation, gotoProjectVideo]);

  return useMemo(
    () => ({ startSafeRender, loading: loading || requestingRendering }),
    [startSafeRender, loading, requestingRendering]
  );
};

export const useConditionalStartRender = (projectId: string, subtitlesLanguage: MaybeSubtitlesLanguage) => {
  const { project } = useProject();
  // See: https://x.com/DavidKPiano/status/1701594811692994698?s=20
  // biome-ignore lint/correctness/useHookAtTopLevel: safe
  const status = project ? useProjectExportStatus(project, subtitlesLanguage) : null;
  const gotoProjectVideo = useGotoProjectVideo(projectId);
  const isAllowedToRenderProject = useIsAllowedToRenderProject(projectId, subtitlesLanguage);
  const { startSafeRender, loading: hasJustInitiatedRender } = useStartSafeRender(projectId, subtitlesLanguage, {
    withNavigation: true,
  });

  const shouldStartRender = status === 'NOT_RENDERED' && isAllowedToRenderProject;
  const goToProjectExport = useMemo(
    () => (shouldStartRender ? startSafeRender : gotoProjectVideo),
    [shouldStartRender, gotoProjectVideo, startSafeRender]
  );

  return {
    goToProjectExport,
    // We consider the button to be in a loading state (and lock it from further
    // action) if it was just interacted with to start a rendering, or if we
    // do not have a known status from the render job (the query is loading).
    isLoading: hasJustInitiatedRender || status === 'UNKNOWN',
  };
};
