import type { FC } from 'react';

import { GridContainer, GridItem, Typography } from '@cofenster/web-components';

import type { VideoFormat } from '@cofenster/constants';
import { AddSceneForm } from '../../../components/common/AddScene/AddSceneForm';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useGoToProjectContributionsPage } from '../../../hooks/navigation/useGoToProjectContributionsPage';

export const ScenesWizard: FC<{
  projectId: string;
  videoFormat: VideoFormat;
  onUploadStart?: VoidFunction;
  onUploadEnd?: VoidFunction;
  bulkUploadInProgress: boolean;
  hasContributionRequestListAvailable: boolean;
}> = ({
  projectId,
  onUploadStart,
  onUploadEnd,
  bulkUploadInProgress,
  videoFormat,
  hasContributionRequestListAvailable,
}) => {
  const goToProjectContributions = useGoToProjectContributionsPage(projectId);
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  return (
    <GridContainer flexDirection="row" textAlign="center">
      <GridItem xs={12}>
        <Typography component="p" variant="h5">
          i18n.projectEdit.empty.addScene
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <AddSceneForm
          projectId={projectId}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          index={0}
          goToProjectContributionsPage={hasContributionRequestListAvailable ? goToProjectContributions : undefined}
          bulkUploadInProgress={bulkUploadInProgress}
          videoFormat={videoFormat}
          hasContributionRequestListAvailable={hasContributionRequestListAvailable}
          canUpdateProject={canUpdateProject}
        />
      </GridItem>
    </GridContainer>
  );
};
