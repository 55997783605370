import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { SearchFilterProvider } from '../../../contexts/searchFilter/SearchFilterProvider';
import { ExistingProjectSubForm } from './ExistingProjectSubForm';
import { NewProjectSubForm } from './NewProjectSubForm';
import type { MoveScenesToProjectValues } from './types';

export const SearchOrCreateProjectStep: FC<{ currentProjectId: string }> = ({ currentProjectId }) => {
  const { values } = useFormikContext<MoveScenesToProjectValues>();

  if (values.destination === 'NEW_PROJECT') return <NewProjectSubForm />;

  return (
    <SearchFilterProvider resultsPerPage={9} withQueryParams={false}>
      <ExistingProjectSubForm currentProjectId={currentProjectId} />
    </SearchFilterProvider>
  );
};
