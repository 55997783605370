import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import type { VideoFormat } from '@cofenster/constants';
import {
  Card,
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Spacing,
  useFeatureFlags,
  useSnackbars,
} from '@cofenster/web-components';

import { useCreateProject } from '../../../api/hooks/project/useCreateProject';
import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolder';
import { CardHeadline } from '../../../components/card';
import { useGotoProjectHandler } from '../../../hooks/navigation/useGotoProjectHandler';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n';

import { VideoFormatSelector } from './VideoFormatSelector';

const useInitialValues = () => {
  const { translate } = useI18n();

  return useMemo(
    () => ({
      // To speed up project creation and minimize friction having to come up
      // with a name, we prefill the name field with a default generic name
      name: translate('projectCreate.form.name.defaultValue'),
      // The overwhelming majority of projects are horizontal, so we prefill the
      // field with this format
      videoFormat: 'Horizontal',
    }),
    [translate]
  );
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const withSpecialFormatsFlag = useFeatureFlags().hasFeature('SPECIAL_FORMATS');

  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(250, translate('form.error.generic.maxLength', { max: 250 }))
      .required('i18n.form.error.projectCreate.name.required'),
    videoFormat: Yup.mixed<VideoFormat>()
      .oneOf(withSpecialFormatsFlag ? ['Horizontal', 'Vertical', 'Square', 'SocialMedia'] : ['Horizontal', 'Vertical'])
      .required('i18n.form.error.projectCreate.videoFormat.required'),
  });
};

const useProjectCreatedTracking = (projectFolder?: ProjectFolder) => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (projectId: string, name: string, videoFormat: VideoFormat) => {
      if (!projectFolder) return;
      tracking.trackEvent({
        event: 'projectCreated',
        details: {
          projectId,
          projectName: name,
          videoFormat,
          source: 'new project',
          teamId: projectFolder.team?.id,
          teamName: projectFolder.team?.name,
          teamType: projectFolder.team?.public ? 'public' : 'private',
          folderId: projectFolder.id,
          folderName: projectFolder.name,
          folderType: projectFolder.team ? 'team' : 'private',
        },
      });
    },
    [projectFolder, tracking]
  );
};

const useProjectCreationSubmit = (projectFolder?: ProjectFolder) => {
  const createProject = useCreateProject();
  const gotoProject = useGotoProjectHandler();
  const { openSnackbar } = useSnackbars();
  const trackProjectCreated = useProjectCreatedTracking(projectFolder);

  return useCallback(
    async (values: FormikValues) => {
      if (!projectFolder) return;

      const { name, videoFormat } = values;
      const result = await createProject(projectFolder.id, { name, videoFormat });
      const projectId = result.data?.createProject?.id;

      if (projectId) {
        trackProjectCreated(projectId, name, videoFormat);
        gotoProject(projectId);
        openSnackbar({
          variant: 'success',
          children: 'i18n.snackbars.projectCreatedSnackbar.copy',
        });
      }
    },
    [createProject, gotoProject, openSnackbar, projectFolder, trackProjectCreated]
  );
};

export const NewProjectForm: FC<{ projectFolder?: ProjectFolder }> = ({ projectFolder }) => {
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const onSubmit = useProjectCreationSubmit(projectFolder);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Spacing bottom={2}>
        <Card>
          <CardHeadline variant="h3" component="h2">
            i18n.projectCreate.form.name.label
          </CardHeadline>

          <FormTextField
            name="name"
            id="name"
            label="i18n.projectCreate.form.name.label"
            placeholder="i18n.projectCreate.form.name.placeholder"
            maxLength={250}
            autoComplete="off"
            data-testid="project-name-input"
          />
        </Card>
      </Spacing>

      <Card>
        <CardHeadline variant="h3" component="h2">
          i18n.projectCreate.videoFormat.header
        </CardHeadline>
        <VideoFormatSelector />
      </Card>

      <GridContainer mt={4} spacing={0}>
        <GridItem xs={12} display="flex" justifyContent="flex-end">
          <FormSubmitButton>i18n.projectCreate.form.button.submit</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
