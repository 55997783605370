import type { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text } from '@cofenster/web-components';

type DeleteContributionRequestDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  onAction: () => unknown;
};

export const DeleteContributionRequestDialog: FC<DeleteContributionRequestDialogProps> = ({
  onAction,
  closeDialog,
  isOpen,
}) => {
  const deleteContributionRequest = () => {
    onAction();
    closeDialog();
  };
  return (
    <Dialog open={isOpen} onClose={closeDialog} size="m" title="i18n.dialogs.deleteContributionRequestDialog.title">
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.deleteContributionRequestDialog.paragraph1
        </Text>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.deleteContributionRequestDialog.paragraph2
        </Text>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} variant="tertiary" fullWidth>
          i18n.global.cancel
        </Button>
        <Button
          onClick={deleteContributionRequest}
          variant="destructive"
          fullWidth
          data-testid="contribution-request-delete-modal-delete-button"
        >
          i18n.dialogs.deleteContributionRequestDialog.deleteButton
        </Button>
      </DialogActions>
    </Dialog>
  );
};
