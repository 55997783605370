import { type FC, useCallback, useMemo } from 'react';

import {
  AppHeader,
  EmptyState,
  GridContainer,
  GridItem,
  Headline,
  PageContentContainer,
  PageHeadline,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  withPopoverMenu,
} from '@cofenster/web-components';

import { useProjects } from '../../api/hooks/project/useProjects';
import type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { ProjectFolderIconsWithSearch } from '../../components/project/ProjectSpaceBar/ProjectFolderIconsWithSearch';
import { ProjectSpaceSubNavigation } from '../../components/project/ProjectSpaceBar/ProjectSpaceSubNavigation';
import { CreateProject, RecordScreen, RecordYourself } from '../../components/quickActions';
import { RequestPermissionButton } from '../../components/requestPermissions';
import { Folders as FoldersList } from '../../components/team/Folders';
import { BulkSelectionProvider } from '../../contexts/bulkSelection/BulkSelectionProvider';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { ProjectFolderProvider } from '../../contexts/projectFolder/ProjectFolderProvider';
import { useSetProjectSpace } from '../../contexts/projectSpace/useProjectSpace';
import { useSearchFilter } from '../../contexts/searchFilter/useSearchFilter';
import { TeamPermissionRestriction, useTeamPermissionStatus } from '../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../contexts/user/useUser';
import { useGotoProjectFolder } from '../../hooks/navigation/useGotoProjectFolder';
import { useDeleteFolderWithConfirmation } from '../../hooks/projectFolder/useDeleteFolderWithConfirmation';
import { ProjectFolderView } from './ProjectFolderView';

export type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';

const FolderMenu: FC<{ projectFolder: ProjectFolder; isArchive: boolean }> = ({ projectFolder, isArchive }) => {
  const { openDialog } = useDialogs();
  const gotoProjectFolder = useGotoProjectFolder(projectFolder.parentFolder?.id, isArchive);
  const onDelete = useDeleteFolderWithConfirmation(projectFolder, 'folderDetail', gotoProjectFolder);

  const isAllowedToEditFolder = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const isAllowedToDeleteFolder = useTeamPermissionStatus({ has: 'ProjectDelete' }) === 'ALLOWED';

  const onRename = useCallback(
    () => openDialog('RenameProjectFolderDialog', { projectFolder, trackingSource: 'folderDetail' }),
    [openDialog, projectFolder]
  );

  const onMove = useCallback(
    () => openDialog('MoveProjectFolderDialog', { projectFolder }),
    [openDialog, projectFolder]
  );

  const actions = useMemo(
    () => [
      isAllowedToEditFolder && (
        <PopoverMenuItem icon="PencilIcon" onClick={onRename}>
          i18n.common.rename
        </PopoverMenuItem>
      ),
      isAllowedToEditFolder && (
        <PopoverMenuItem icon="ArrowBoxedRightIcon" onClick={onMove}>
          i18n.common.move
        </PopoverMenuItem>
      ),
      isAllowedToDeleteFolder && (
        <PopoverMenuItem
          icon="TrashIcon"
          color="negative"
          onClick={onDelete}
          data-testid="folder-detail-popover-delete-button"
        >
          i18n.global.delete
        </PopoverMenuItem>
      ),
    ],
    [isAllowedToDeleteFolder, isAllowedToEditFolder, onDelete, onMove, onRename]
  );

  if (!actions.length) return null;

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, { children: actions });

  return <PopoverMenuIcon data-testid="folder-detail-popover" />;
};

export type ProjectsFolderContentProps = {
  projectFolder: ProjectFolder;
  title: string;
  isArchive: boolean;
};

const QuickActions: FC<{ projectFolder: ProjectFolder }> = ({ projectFolder }) => {
  return (
    <GridContainer mb={5} spacing={2}>
      <TeamPermissionRestriction has="ProjectCreate">
        <GridItem xs={12} sm="auto">
          <CreateProject folderId={projectFolder.id} />
        </GridItem>
        <GridItem xs={12} sm="auto">
          <RecordScreen folderId={projectFolder.id} />
        </GridItem>
        <GridItem xs={12} sm="auto">
          <RecordYourself folderId={projectFolder.id} />
        </GridItem>
      </TeamPermissionRestriction>
    </GridContainer>
  );
};

export const ProjectFolderContent: FC<ProjectsFolderContentProps> = ({ projectFolder, title, isArchive }) => {
  const { user } = useUser();
  const parentFolderId = projectFolder.parentFolder?.id;
  const canCreateProject = useTeamPermissionStatus({ has: 'ProjectCreate' }) === 'ALLOWED';
  const { filter } = useSearchFilter();
  const { loading, error, paginatedProjects } = useProjects(projectFolder.id, filter);
  const noProjectsAndNoCreatePermission = paginatedProjects?.total === 0 && !canCreateProject;

  const gotoProjectFolder = useGotoProjectFolder(parentFolderId, isArchive);
  const onBack = useMemo(
    () => (parentFolderId ? () => gotoProjectFolder(parentFolderId) : undefined),
    [parentFolderId, gotoProjectFolder]
  );

  useSetProjectSpace(projectFolder.team?.id);

  return (
    <ProjectFolderProvider projectFolder={projectFolder}>
      <AppHeader
        topLeft={
          <PageHeadline
            mb={0}
            title={title}
            isPrivate={projectFolder.team ? !projectFolder.team.public : false}
            onBack={onBack}
            popover={parentFolderId && <FolderMenu projectFolder={projectFolder} isArchive={isArchive} />}
            rightAction={
              <TeamPermissionRestriction
                has="ProjectUpdate"
                fallback={user && <RequestPermissionButton user={user} permission="ProjectUpdate" />}
              />
            }
          />
        }
        bottomLeft={
          projectFolder.parentFolder ? undefined : (
            <ProjectSpaceSubNavigation team={projectFolder.team ?? undefined} folderId={projectFolder.id} />
          )
        }
        bottomRight={<ProjectFolderIconsWithSearch />}
      />

      <PageContentContainer>
        {noProjectsAndNoCreatePermission ? (
          <EmptyState
            iconType="FilmIcon"
            title="i18n.projectsFolder.noProjectsAndPermission.headline"
            description="i18n.projectsFolder.noProjectsAndPermission.subline"
          />
        ) : (
          <>
            {!isArchive && <QuickActions projectFolder={projectFolder} />}

            <GridContainer spacing={5}>
              {!projectFolder.parentFolder && (
                <GridItem xs={12} sx={{ '&:empty': { display: 'none' } }}>
                  <FoldersList rootFolderId={projectFolder.id} isArchive={isArchive} />
                </GridItem>
              )}

              <GridItem xs={12}>
                <Headline variant="h3" component="h2">
                  i18n.projects.headline.projects
                </Headline>
                <BulkSelectionProvider>
                  <ProjectFolderView
                    projectFolder={projectFolder}
                    isArchive={isArchive}
                    withOwner={Boolean(projectFolder.team?.name)}
                    loading={loading}
                    paginatedProjects={paginatedProjects}
                    error={Boolean(error)}
                  />
                </BulkSelectionProvider>
              </GridItem>
            </GridContainer>
          </>
        )}
      </PageContentContainer>
    </ProjectFolderProvider>
  );
};
