import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
} from '@cofenster/web-components';

import type { Project as UseProjectsProject } from '../../../api/hooks/project/useProjects';
import { useUpdateProject } from '../../../api/hooks/project/useUpdateProject';
import type { Project as UseProjectProject } from '../../../contexts/project/useProject';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n/useI18n';

type Project = UseProjectProject | UseProjectsProject;

type Values = {
  name: string;
};

const useValidationSchema = () => {
  const { translate } = useI18n();
  const validations = {
    name: Yup.string()
      .trim()
      .max(250, translate('form.error.generic.maxLength', { max: 250 }))
      .required('i18n.form.error.generic.required'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = (name: string) => {
  return useMemo<Values>(
    () => ({
      name,
    }),
    [name]
  );
};

const useSubmit = (
  project: Project,
  closeDialog: () => unknown,
  source: string,
  teamId?: string,
  refetchQueries: string[] = []
) => {
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', ...refetchQueries] });
  const tracking = useWebManagerTracking(teamId);

  const trackProjectRenamed = useCallback(
    (projectName: string) => {
      tracking.trackEvent({
        event: 'projectRenamed',
        details: {
          projectId: project.id,
          projectName,
          source,
        },
      });
    },
    [project, tracking, source]
  );

  return useCallback(
    async (values: FormikValues) => {
      const data = values as Values;
      if (!project) return;
      await updateProject(project.id, data);
      closeDialog();
      trackProjectRenamed(data.name);
    },
    [project, updateProject, closeDialog, trackProjectRenamed]
  );
};

export type RenameProjectDialogProps = {
  project: Project;
  isOpen: boolean;
  closeDialog: () => unknown;
  trackingSource: 'projectCard' | 'projectDetail';
  teamId?: string;
  refetchQueries?: string[];
};

export const RenameProjectDialog: FC<RenameProjectDialogProps> = ({
  isOpen,
  closeDialog,
  project,
  trackingSource,
  teamId,
  refetchQueries = [],
}) => {
  const initialValues = useInitialValues(project.name);
  const validationSchema = useValidationSchema();
  const onSubmit = useSubmit(project, closeDialog, trackingSource, teamId, refetchQueries);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-rename-dialog"
      title="i18n.dialogs.renameProjectDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.dialogs.renameProjectDialog.description
            </Text>
          </Spacing>

          <FormTextField
            id="projectNewName"
            name="name"
            label="i18n.common.name"
            placeholder="i18n.common.name"
            autoFocus
            data-testid="project-rename-input"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth data-testid="project-rename-dialog-button">
            i18n.dialogs.renameProjectDialog.button
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
