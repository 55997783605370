import { Button, Icon, styled } from '@cofenster/web-components';

import { useScenes } from '../../../../contexts/scenes/useScenes';

import { useSceneEditorNotesState } from './SceneEditorNotesStateContext';

// 1. The bubble being added on top of the icon with a flat outline around it to
//    partially cover the icon, we need to adjust the outline color when the
//    button is interacted with. It’s a bit hacky as this is derived from the
//    transparent interaction color from tertiary buttons, but that does the job.
// 2. Use the same transition as the Material UI button so the button background
//    and the bubble outline change color at the same time.
const StyledButton = styled(Button)(() => ({
  '&:hover .bubble, &:focus .bubble': {
    outlineColor: '#f1f2f3', // 1
    transition: 'outline-color 250ms cubic-bezier(0.4, 0, 0.2, 1)', // 2
  },
}));

const IconContainer = styled('span')(() => ({
  position: 'relative',
}));

const Bubble = styled('span')(({ theme }) => ({
  width: theme.spacing(0.75),
  height: theme.spacing(0.75),
  position: 'absolute',
  backgroundColor: theme.palette.brand.blue,
  borderRadius: '50%',
  outline: '2px solid white',
  top: theme.spacing(0.15),
  right: theme.spacing(0),
}));

export const SceneEditorNotesButton = () => {
  const { currentScene } = useScenes();
  const [notesOpen, setNotesOpen] = useSceneEditorNotesState();

  if (!currentScene) return null;

  return (
    <StyledButton
      variant="tertiary"
      startIcon={
        <IconContainer>
          <Icon type={currentScene.editorNotes ? 'NoteIcon' : 'NoteBlankIcon'} size="s" />
          {currentScene.editorNotes && <Bubble className="bubble" data-testid="filled-notes-bubble" />}
        </IconContainer>
      }
      onClick={() => setNotesOpen((prev) => !prev)}
      aria-expanded={notesOpen}
      data-testid="notes-button"
    >
      i18n.projectEdit.SceneEditorNotes.button.text
    </StyledButton>
  );
};
