import type { FC } from 'react';

import { Box, Headline, LoadingSpinner, Spacing, Text, styled } from '@cofenster/web-components';

import { useLatestProjects } from '../../api/hooks/project/useLatestProjects';
import { useSearchFilter } from '../../contexts/searchFilter/useSearchFilter';

import { HomeProjects } from './HomeProjects';
import { HomeSection } from './HomeSection';
import { HomeSectionControls } from './HomeSectionControls';

const StyledBox = styled(Box)(() => ({ textAlign: 'center' }));

const EmptyState = () => (
  <Spacing top={2}>
    <StyledBox padding={4}>
      <Headline variant="h4" component="p">
        i18n.home.latestProjects.empty.headline
      </Headline>
      <Text variant="l" component="p">
        i18n.home.latestProjects.empty.subline
      </Text>
    </StyledBox>
  </Spacing>
);

export const HomeLatestProjects: FC = () => {
  const { filter } = useSearchFilter();

  // Using the ‘network-only’ fetch policy is not ideal, but the alternative is
  // to refetch that query after essentially every operation that touches a
  // project, which is not only error-prone but also not better from a data
  // perspective, hency the cache bypass for this query.
  const { projects, loading } = useLatestProjects({ limit: 6, createdBy: filter.createdBy }, 'network-only');

  return (
    <HomeSection title="i18n.home.latestProjects.title" controls={<HomeSectionControls />}>
      {loading ? (
        <LoadingSpinner spacing={10} />
      ) : projects?.length ? (
        <HomeProjects projects={projects} refetchQueries={['LatestProjects']} />
      ) : (
        <EmptyState />
      )}
    </HomeSection>
  );
};
