import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { ProjectLayout } from '../../components/page/Layout/ProjectLayout';
import { useProject } from '../../contexts/project/useProject';
import { useProjectEditingHeader } from '../../hooks/useProjectHeader';
import type { ProjectRouteParams } from '../../routes';
import { NoAccessContent } from '../NoAccess';

import { ProjectMusicForm } from './ProjectMusicForm';

export const ProjectMusicContent: FC = () => {
  const { projectId } = useParams() as ProjectRouteParams;
  const { project, loading } = useProject();
  const projectHeader = useProjectEditingHeader(projectId);

  return (
    <ProjectLayout documentTitle={project?.name ?? ''} header={projectHeader}>
      {project ? (
        <ProjectMusicForm project={project} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <NoAccessContent resource="Project" />
      )}
    </ProjectLayout>
  );
};
