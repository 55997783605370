import { type CSSProperties, type FC, useContext } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import { NativeVideoPlayer, styled } from '@cofenster/web-components';

import type { StockImage, StockVideo } from '../../../api/generated';

import { SearchContext } from './SearchContext';

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
  display: 'block',
  width: '100%',
  maxWidth: '100%',
}));

const ImageAssetPreview: FC<{ asset: StockImage }> = ({ asset }) => (
  <Image src={asset.imageUrl} alt={asset.alt ?? ''} />
);

const VideoAssetPreview: FC<{ asset: StockVideo }> = ({ asset }) => (
  <NativeVideoPlayer
    src={asset.videoUrl}
    poster={asset.posterUrl}
    actions={['DOWNLOAD', 'FULLSCREEN', 'PLAYBACK_RATE']}
    autoPlay
  />
);

// 1. Weird hack for Safari to honor the rounded corner on that element.
//    See: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
const Container = styled('div')(({ theme }) => ({
  aspectRatio: 'var(--aspect-ratio)',
  maxHeight: 'min(50vh, var(--video-height))',
  objectFit: 'contain',
  margin: 'auto',
  borderRadius: theme.shape['borderRadius-l'],
  WebkitMaskImage: '-webkit-radial-gradient(white, black)', // 1

  [theme.breakpoints.up('md')]: {
    minHeight: 350,
  },
}));

export const AssetPreview: FC<{ asset: StockImage | StockVideo }> = ({ asset }) => {
  const { type, videoFormat } = useContext(SearchContext);
  const { aspectRatio, height } = VIDEO_FORMATS[videoFormat];

  return (
    <Container
      style={
        {
          '--aspect-ratio': String(aspectRatio),
          '--video-height': `${height}px`,
        } as CSSProperties
      }
    >
      {type === 'image' ? (
        <ImageAssetPreview asset={asset as StockImage} />
      ) : (
        <VideoAssetPreview asset={asset as StockVideo} />
      )}
    </Container>
  );
};
