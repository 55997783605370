import { Spacing, Typography, styled, useFormatTimeDistance } from '@cofenster/web-components';
import { useField } from 'formik';
import { type CSSProperties, type FC, useMemo } from 'react';
import { useProjectThumbnail } from '../../../api/hooks/project/useProjectThumbnail';
import type { ExtendedProject } from '../../../api/hooks/project/useSearchProjects';
import { useI18n } from '../../../i18n';
import { Masthead } from '../../team/Projects/Masthead';
import { ProjectFormatInformation } from '../../team/Projects/ProjectFormatInformation';

const HiddenInput = styled('input')(() => ({
  appearance: 'none',
  position: 'absolute',
  inset: 0,
  opacity: 0,
}));

// 1. Apply focus ring only when navigating via keyboard
const Label = styled('label')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  padding: theme.spacing(1),

  border: '2px solid transparent',
  borderRadius: theme.shape['borderRadius-l'],

  '&:has(input:focus-visible)': theme.mixins.focusRing, // 1

  '&:hover': { backgroundColor: theme.palette.brand.grey50 },

  '&:has(input:checked)': {
    borderColor: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue100,
  },

  cursor: 'pointer',
  '&:has(input:disabled)': {
    cursor: 'not-allowed',
    color: theme.palette.brand.grey600,
  },
}));

// 1. Clear blur effect from the `Masthead` element
const StyledMasthead = styled(Masthead)(() => ({
  '::before': { filter: 'none' }, // 1
}));

const Title = styled(Typography)(() => ({
  display: 'block',
}));

const ProjectTileTexts: FC<{ project: ExtendedProject['project'] }> = ({ project }) => {
  const { translate } = useI18n();
  const formatTimeDistance = useFormatTimeDistance();

  const descriptionText = useMemo(() => {
    return [
      project.creator &&
        translate('dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.creator', {
          creator: `${project.creator.firstname} ${project.creator.lastname}`,
        }),
      translate('dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.lastEdited', {
        lastEdited: formatTimeDistance(new Date(project.updatedAt ?? project.createdAt)),
      }),
    ]
      .filter(Boolean)
      .join(' • ');
  }, [project, translate, formatTimeDistance]);

  return (
    <>
      <Title variant="h6" component="span">
        {project.name}
      </Title>
      <Typography variant="m">{descriptionText}</Typography>
    </>
  );
};

export const ProjectTile: FC<{ extendedProject: ExtendedProject }> = ({ extendedProject }) => {
  const { project } = extendedProject;
  const [field] = useField({ name: 'targetProjectId', type: 'radio', value: project.id });
  const id = `target-project-id-${project.id}`;
  const thumbnailUrl = useProjectThumbnail(project);

  return (
    <Label htmlFor={id} data-testid="project-tile">
      <HiddenInput id={id} {...field} type="radio" />
      <StyledMasthead style={{ '--image': thumbnailUrl ? `url(${thumbnailUrl})` : undefined } as CSSProperties}>
        <ProjectFormatInformation project={project} sizing="s" />
      </StyledMasthead>
      <Spacing top={2}>
        <ProjectTileTexts project={project} />
      </Spacing>
    </Label>
  );
};
