import { type FC, Fragment, type PropsWithChildren, type ReactNode } from 'react';

import { GridContainer, type GridContainerProps, GridItem, Headline, styled } from '@cofenster/web-components';

import { useAutomaticTracking } from '../../../hooks/useAutomaticTracking';
import { AppLayout } from '../Layout';

const FlexContainer = styled('div')(() => ({
  flex: '1 1 100%',
  justifyContent: 'center',

  display: 'flex',
}));

const Title = styled(Headline)({
  overflow: 'hidden',

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Header = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridAutoRows: '1fr',
  padding: theme.spacing(2, 3),
  backgroundColor: theme.palette.brand.white,

  '> *': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),

    ':first-of-type': { justifyContent: 'flex-start' },
    ':last-of-type': { justifyContent: 'flex-end' },
  },
}));

type FullPageWithTitleAndActionsProps = {
  title: string;
  leftActions?: ReactNode[];
  rightActions?: ReactNode[];
} & Pick<GridContainerProps, 'gap' | 'height'>;

export const FullPageWithTitleAndActions: FC<PropsWithChildren<FullPageWithTitleAndActionsProps>> = ({
  title,
  children,
  leftActions,
  rightActions,
  ...rest
}) => {
  useAutomaticTracking();

  return (
    <AppLayout documentTitle={title} navigation={null} MainContentAreaComponent={Fragment}>
      <FlexContainer>
        <GridContainer flexDirection="column" alignItems="center" {...rest}>
          <GridItem width="100%" zIndex={200}>
            <Header>
              <div>{leftActions}</div>
              <div>
                <Title variant="h3" component="h1" align="center">
                  {title}
                </Title>
              </div>
              <div>{rightActions}</div>
            </Header>
          </GridItem>

          {children}
        </GridContainer>
      </FlexContainer>
    </AppLayout>
  );
};
