import { BlankButton, Typography, styled } from '@cofenster/web-components';
import type { FC } from 'react';
import { useIncludeScene } from '../EditorSceneAssetThumbs/hooks/useIncludeScene';

const IncludeSceneButtonLink = styled(BlankButton)(({ theme }) => ({
  textDecoration: 'underline',

  '&:hover': { color: theme.palette.brand.blue },
  '&:focus-visible': theme.mixins.focusRing,
}));

export const SceneExcludedHint: FC<{ sceneId?: string }> = ({ sceneId }) => {
  const includeScene = useIncludeScene();

  return (
    <Typography
      variant="l"
      component="p"
      i18nParams={{
        link: (chunk) => (
          <IncludeSceneButtonLink onClick={sceneId ? () => includeScene(sceneId) : undefined}>
            {chunk}
          </IncludeSceneButtonLink>
        ),
      }}
    >
      i18n.projectEditor.hint.hidden
    </Typography>
  );
};
