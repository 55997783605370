import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useKeypress } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../contexts/editorPlayer/useEditorPlayer';
import { useIntroOutro } from '../../../contexts/editorPlayer/useIntroOutro';
import { useScenes } from '../../../contexts/scenes/useScenes';
import { useGotoEditorScene } from '../../../hooks/navigation/useGotoEditorScene';

export const useMoveSceneKeyboardHandler = () => {
  const { projectId, sceneId } = useParams();
  const { scenes } = useScenes();
  const { isUploaded } = useIntroOutro();

  // We cannot use the `goToNextScene` and `goToPreviousScene` from the context
  // because they discard the excluded and empty scenes on purposes (since they
  // are intended for the player navigation). In this case, we want to indiscri-
  // minately move up and down the scene list without care for the state of the
  // scenes.
  const { setCurrentScene } = useEditorPlayer();
  const gotoEditorScene = useGotoEditorScene(projectId as string);
  const selectScene = __PREVIEW_EXCLUDED_SCENES__ ? gotoEditorScene : setCurrentScene;

  const includedScenes = scenes.filter((scene) => (__PREVIEW_EXCLUDED_SCENES__ ? true : !scene.excluded));
  const firstIncludedScene = includedScenes[0];
  const lastIncludedScene = includedScenes[includedScenes.length - 1];

  const currentlyOnIntro = sceneId === 'intro';
  const currentlyOnOutro = sceneId === 'outro';

  // If we are currently on the intro, move to the first included scene
  // (provided there is one). If we are on any scene but the outro, check the
  // current scene ID: if we’re on the last included scene, move to the outro
  // (if present); if we’re on any other scene, move down to the next included
  // scene.
  const goToNextScene = useCallback(() => {
    if (currentlyOnIntro) {
      if (firstIncludedScene) selectScene(firstIncludedScene.id);
    } else if (currentlyOnOutro) {
      // Nothing to do
    } else {
      const currentlyOnLastIncludedScene = sceneId === lastIncludedScene?.id;
      if (currentlyOnLastIncludedScene && isUploaded('outro')) {
        selectScene('outro');
      } else {
        const currentIncludedIndex = includedScenes.findIndex((scene) => scene.id === sceneId);
        const nextIncludedScene = includedScenes[currentIncludedIndex + 1];
        if (nextIncludedScene) selectScene(nextIncludedScene.id);
      }
    }
  }, [
    isUploaded,
    sceneId,
    includedScenes,
    selectScene,
    firstIncludedScene,
    lastIncludedScene,
    currentlyOnIntro,
    currentlyOnOutro,
  ]);

  // If we are currently on the outro, move to the last included scene (provided
  // there is one). If we are on any scene but the intro, check the current
  // scene ID: if we’re on the first included scene, move to the intro (if
  // present); if we’re on any other scene, move up to the previous included
  // scene.
  const goToPrevScene = useCallback(() => {
    if (currentlyOnOutro) {
      if (lastIncludedScene) selectScene(lastIncludedScene.id);
    } else if (currentlyOnIntro) {
      // Nothing to do
    } else {
      const currentlyOnFirstIncludedScene = sceneId === firstIncludedScene?.id;
      if (currentlyOnFirstIncludedScene && isUploaded('intro')) {
        selectScene('intro');
      } else {
        const currentIncludedIndex = includedScenes.findIndex((scene) => scene.id === sceneId);
        const prevIncludedScene = includedScenes[currentIncludedIndex - 1];
        if (prevIncludedScene) selectScene(prevIncludedScene.id);
      }
    }
  }, [
    isUploaded,
    sceneId,
    includedScenes,
    selectScene,
    firstIncludedScene,
    lastIncludedScene,
    currentlyOnIntro,
    currentlyOnOutro,
  ]);

  useKeypress(goToNextScene, 'ArrowDown');
  useKeypress(goToPrevScene, 'ArrowUp');
};
