import { useField } from 'formik';
import { type ChangeEventHandler, type FC, type FocusEventHandler, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { TextField } from '@cofenster/web-components';

import { useI18n } from '../../../../i18n/useI18n';
import { getConstraints } from './getConstraints';

type OutroTextInputProps = {
  name: string;
  videoFormat: VideoFormat;
};

export const OutroTextInput: FC<OutroTextInputProps> = ({ name, videoFormat }) => {
  const [field, meta] = useField(name);
  const { maxLines, maxLineLength } = getConstraints(videoFormat);
  const { onChange: formikOnChange, onBlur: formikOnBlur, value, ...rest } = field;
  const { translate } = useI18n();
  const [currentValue, setCurrentValue] = useState(value);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setCurrentValue(event.currentTarget.value);
  };

  const onBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    formikOnChange(event);
    formikOnBlur(event);
  };

  const hasError = meta.touched && !!meta.error;
  return (
    <TextField
      {...rest}
      value={currentValue}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      error={hasError}
      placeholder={translate('projectDesign.OutroTextInput.placeholder', {
        lines: maxLines,
        lineLength: maxLineLength,
      })}
      rows={maxLines}
      label="i18n.projectDesign.OutroTextInput.label"
      helperText={hasError ? meta.error : undefined}
      multiline
    />
  );
};
