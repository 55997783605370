import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Form,
  GridContainer,
  GridItem,
  Headline,
  VisuallyHidden,
  useBooleanState,
} from '@cofenster/web-components';

import { useUpdateProject } from '../../../api/hooks/project/useUpdateProject';
import { CardSeparator } from '../../../components/card';
import type { Project } from '../../../contexts/project/useProject';

import { TemplatePanel } from './TemplatePanel';
import { TemplatePreview } from './TemplatePreview';

type Values = {
  templateId: string;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  templateId: Yup.string().trim().required('i18n.form.error.generic.required'),
});

const useInitialValues = (project: Project) =>
  useMemo<Values>(
    () => ({
      templateId: project.template.id ?? '',
    }),
    [project.template.id]
  );

const useSubmit = (projectId: string, closePanel: () => unknown) => {
  // Updating the template has an impact on the preview and therefore should
  // cause a refetch of the render description.
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', 'Scenes', 'ProjectRenderDescription'] });

  return useCallback(
    async (values: FormikValues) => {
      await updateProject(projectId, { templateId: values.templateId });
      closePanel();
    },
    [updateProject, projectId, closePanel]
  );
};

type Props = {
  project: Project;
};

export const ProjectTemplateForm: FC<Props> = ({ project }) => {
  const [panelIsOpen, openPanel, closePanel] = useBooleanState(false);
  const initialValues = useInitialValues(project);
  const onSubmit = useSubmit(project.id, closePanel);

  return (
    <Form autoSubmit initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer alignItems="center">
        <GridItem>
          <Headline variant="h3" component="h2">
            i18n.projectDesign.templateForm.headline
          </Headline>
        </GridItem>
        <GridItem flex={1} textAlign="right">
          <Button
            onClick={openPanel}
            variant="tertiary"
            i18nParams={{ hidden: (chunks) => <VisuallyHidden>{chunks}</VisuallyHidden> }}
          >
            i18n.projectDesign.templateForm.changeButton
          </Button>
        </GridItem>
      </GridContainer>

      <CardSeparator />

      <TemplatePreview project={project} />
      <TemplatePanel isOpen={panelIsOpen} project={project} close={closePanel} />
    </Form>
  );
};
