import { useMemo } from 'react';

import {
  type CreateProjectTemplateFromProjectMutationVariables,
  useCreateProjectTemplateFromProjectMutation,
} from '../../generated';

export const useCreateTemplateFromProject = () => {
  const [createProjectTemplateFromProject, metadata] = useCreateProjectTemplateFromProjectMutation({
    update: (cache) => {
      cache.evict({ fieldName: 'ProjectTemplates' });
    },
  });

  return useMemo(
    () =>
      [
        async (
          projectId: CreateProjectTemplateFromProjectMutationVariables['projectId'],
          input: CreateProjectTemplateFromProjectMutationVariables['input']
        ) => {
          return createProjectTemplateFromProject({ variables: { projectId, input } });
        },
        metadata,
      ] as const,
    [createProjectTemplateFromProject, metadata]
  );
};
