import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { useSceneTimings } from '../../contexts/editorPlayer/usePlayerScenes';
import { useRemotionPlayerRef } from '../../contexts/editorPlayer/useRemotionPlayerRef';
import { routes } from '../../routes';

export const useGotoProjectEditScene = (
  projectId?: string,
  { replace = false, withTimeTravel = false }: { replace?: boolean; withTimeTravel?: boolean } = {}
) => {
  const goto = useGoto({ replace });
  const sceneTimings = useSceneTimings({ withExcludedScenes: false });
  const { playerRef } = useRemotionPlayerRef(false);

  return useCallback(
    (sceneId?: string, search?: Record<string, string>) => {
      const options = search ? { search: new URLSearchParams(search).toString() } : undefined;

      if (projectId) {
        if (sceneId) {
          goto(routes.projectEditScene, { projectId, sceneId }, options);

          if (withTimeTravel) {
            const sceneTiming = sceneTimings.find((sceneTiming) => sceneTiming.id === sceneId);
            if (sceneTiming && playerRef) {
              if (playerRef.currentTime < sceneTiming.start || playerRef.currentTime > sceneTiming.end) {
                playerRef.currentTime = sceneTiming.start;
              }
            }
          }
        } else {
          goto(routes.projectEdit, { projectId }, options);
        }
      }
    },
    [goto, playerRef, projectId, sceneTimings, withTimeTravel]
  );
};
