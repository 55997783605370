import { type FC, type PropsWithChildren, useEffect, useMemo } from 'react';

import { useScenes } from '../../api/hooks/scene/useScenes';
import { Redirect } from '../../components/common/Redirect';

import { ScenesContext } from './ScenesContext';

type Props = {
  projectId: string;
  sceneId?: string;
  pollingInterval?: number;
};

export const ScenesProvider: FC<PropsWithChildren<Props>> = ({ children, projectId, sceneId, pollingInterval }) => {
  const { loading, error, scenes, startPolling, stopPolling } = useScenes(projectId);

  const currentScene = useMemo(() => scenes.find((scene) => scene.id === sceneId) ?? scenes[0], [scenes, sceneId]);

  useEffect(() => {
    pollingInterval ? startPolling(pollingInterval) : stopPolling();
    return () => stopPolling();
  }, [pollingInterval, startPolling, stopPolling]);

  const context = useMemo(
    () => ({
      scenes: scenes || [],
      currentScene,
      loading,
    }),
    [scenes, currentScene, loading]
  );

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) return <Redirect to="home" />;

  return <ScenesContext.Provider value={context}>{children}</ScenesContext.Provider>;
};
