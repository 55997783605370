import * as Sentry from '@sentry/react';
import type { FC, PropsWithChildren } from 'react';

import { ErrorPage } from './ErrorPage';

export const ErrorBoundary: FC<PropsWithChildren<Sentry.ErrorBoundaryProps>> = ({
  children,
  fallback = <ErrorPage />,
  ...rest
}) => {
  return (
    <Sentry.ErrorBoundary {...rest} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
