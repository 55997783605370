import { type FC, useCallback } from 'react';

import {
  PopoverMenuDivider,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  useFeatureFlags,
  useGoto,
  useSnackbars,
  withPopoverMenu,
} from '@cofenster/web-components';

import { useArchiveProjects } from '../../../api/hooks/project/useArchiveProjects';
import type { Project } from '../../../api/hooks/project/useProjects';
import { useUnarchiveProjects } from '../../../api/hooks/project/useUnarchiveProjects';
import { useCurrentTeam } from '../../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProjectFolder } from '../../../contexts/projectFolder/useProjectFolder';
import { useAccountPermissionStatus } from '../../../contexts/user/AccountPermissionRestriction';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useDownloadExportedVideo } from '../../../hooks/project/useDownloadExportedVideo';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { routes } from '../../../routes';
import { RouterLink } from '../../button/RouterLink';

const useRename = (project: Project, teamId?: string, refetchQueries?: string[]) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('RenameProjectDialog', { project, trackingSource: 'projectCard', teamId, refetchQueries });
  }, [openDialog, project, teamId, refetchQueries]);
};

const useMove = (project: Project, fromProjectFolderId?: string, refetchQueries?: string[]) => {
  const { openDialog } = useDialogs();
  const { projectFolder } = useProjectFolder();
  const rootProjectFolderId = projectFolder.parentFolder?.id ?? projectFolder.id;
  const privateRootFolderId = projectFolder.team ? undefined : rootProjectFolderId;

  const move = useCallback(() => {
    if (!fromProjectFolderId) return;
    openDialog('MoveProjectsDialog', {
      projectIds: [project.id],
      fromProjectFolderId,
      rootProjectFolderId,
      privateRootFolderId,
      status: project.archivedAt ? 'ARCHIVED' : 'ACTIVE',
      trackingSource: 'projectCard',
      refetchQueries,
    });
  }, [
    openDialog,
    project.id,
    fromProjectFolderId,
    privateRootFolderId,
    project.archivedAt,
    rootProjectFolderId,
    refetchQueries,
  ]);

  return fromProjectFolderId ? move : undefined;
};

const useDuplicate = (project: Project, teamId?: string, refetchQueries?: string[]) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('DuplicateProjectDialog', { project, teamId, refetchQueries });
  }, [openDialog, project, teamId, refetchQueries]);
};

const useDelete = (project: Project, teamId?: string, refetchQueries?: string[]) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('DeleteProjectDialog', {
      project,
      trackingSource: 'projectCard',
      teamId,
      refetchQueries,
    });
  }, [openDialog, project, teamId, refetchQueries]);
};

/**
 * Complete the project.
 */
const useArchive = (project: Project, refetchQueries?: string[]) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const archiveProjects = useArchiveProjects(refetchQueries);

  const trackProjectsArchived = useCallback(
    () =>
      tracking.trackEvent({
        event: 'projectsArchived',
        details: {
          source: 'projectCard',
          projectIds: [project.id],
        },
      }),
    [tracking, project.id]
  );

  const giveFeedback = useCallback(
    () =>
      openSnackbar({
        children: 'i18n.snackbars.projectsArchivedSnackbar.copy',
        i18nParams: { count: 1 },
      }),
    [openSnackbar]
  );

  const archive = useCallback(async () => {
    await archiveProjects([project.id]);
    giveFeedback();
    trackProjectsArchived();
  }, [archiveProjects, project.id, giveFeedback, trackProjectsArchived]);
  const confirmArchiveProject = useConfirmDialog({
    title: 'i18n.dialogs.ConfirmArchiveDialog.headline',
    content: 'i18n.dialogs.ConfirmArchiveDialog.text',
    confirm: 'i18n.dialogs.ConfirmArchiveDialog.button',
  });

  return useCallback(async () => {
    if (project.sharingLink) {
      if (await confirmArchiveProject()) {
        await archive();
      }
    } else {
      await archive();
    }
  }, [project.sharingLink, confirmArchiveProject, archive]);
};

/**
 * Restore the project.
 */
const useUnarchive = (project: Project, refetchQueries?: string[]) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const unarchiveProjects = useUnarchiveProjects(refetchQueries);

  return useCallback(async () => {
    await unarchiveProjects([project.id]);
    openSnackbar({
      children: 'i18n.snackbars.projectsUnarchivedSnackbar.copy',
      i18nParams: { count: 1 },
    });
    tracking.trackEvent({
      event: 'projectsUnarchived',
      details: {
        source: 'projectCard',
        projectIds: [project.id],
      },
    });
  }, [project.id, unarchiveProjects, tracking, openSnackbar]);
};

const useCopySharingLink = (project: Project) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const trackVideoLinkCopied = useCallback(
    () =>
      tracking.trackEvent({
        event: 'videoLinkCopied',
        details: {
          projectName: project.name,
          projectId: project.id,
          source: 'popover',
        },
      }),
    [tracking, project.id, project.name]
  );

  const handleCopy = useCallback(async () => {
    if (!project.sharingLink) return;
    await navigator.clipboard.writeText(project.sharingLink.url);
    openSnackbar({
      variant: 'success',
      children: 'i18n.common.copySharingLink.success',
    });
    trackVideoLinkCopied();
  }, [project.sharingLink, trackVideoLinkCopied, openSnackbar]);

  return project.sharingLink ? handleCopy : undefined;
};

export const useSaveAsProjectTemplate = (project: Project) => {
  const goto = useGoto();
  const { openDialog } = useDialogs();
  return useCallback(() => {
    const goToProjectEdit = (projectId: string) => goto(routes.projectEdit, { projectId });
    const goToProjectTemplates = () => goto(routes.projectTemplates);

    openDialog('CreateProjectTemplateFromProjectDialog', {
      projectId: project.id,
      projectName: project.name,
      goToProjectEdit,
      goToProjectTemplates,
    });
  }, [openDialog, project.id, project.name, goto]);
};

type Props = {
  project: Project;
  projectFolderId?: string;
  refetchQueries?: string[];
};

export const PopoverMenu: FC<Props> = ({ project, projectFolderId, refetchQueries = [] }) => {
  const { team } = useCurrentTeam();
  const renameProject = useRename(project, team?.id, refetchQueries);
  const moveProject = useMove(project, projectFolderId, refetchQueries);
  const duplicateProject = useDuplicate(project, team?.id, refetchQueries);
  const downloadExportedVideo = useDownloadExportedVideo(project, 'projectCard');
  const archiveProject = useArchive(project, refetchQueries);
  const unarchiveProject = useUnarchive(project, refetchQueries);
  const deleteProject = useDelete(project, team?.id, refetchQueries);
  const copySharingLink = useCopySharingLink(project);
  const withConsentFlag = useFeatureFlags().hasFeature('CONSENT');
  const onCreateTemplate = useSaveAsProjectTemplate(project);

  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const canDeleteProject = useTeamPermissionStatus({ has: 'ProjectDelete' }) === 'ALLOWED';
  const canDownloadVideo = useTeamPermissionStatus({ has: 'VideoDownload' }) === 'ALLOWED';
  const canShareProject = useTeamPermissionStatus({ has: 'SharingLinkCreate' }) === 'ALLOWED';
  const canViewConsents = useAccountPermissionStatus({ has: 'ConsentRead' }) === 'ALLOWED';

  const top = [
    canUpdateProject && moveProject && (
      <PopoverMenuItem
        key="move"
        icon="ArrowBoxedRightIcon"
        onClick={moveProject}
        data-testid="project-menu-move-button"
      >
        i18n.common.move
      </PopoverMenuItem>
    ),
    canUpdateProject && (
      <PopoverMenuItem key="rename" icon="PencilIcon" onClick={renameProject} data-testid="project-menu-rename-button">
        i18n.common.rename
      </PopoverMenuItem>
    ),
    canUpdateProject && !project.archivedAt && (
      <PopoverMenuItem
        key="duplicate"
        icon="CopyIcon"
        onClick={duplicateProject}
        data-testid="project-menu-duplicate-button"
      >
        i18n.common.duplicate
      </PopoverMenuItem>
    ),
  ].filter(Boolean);

  const middle = [
    canShareProject && copySharingLink && (
      <PopoverMenuItem
        key="share"
        icon="LinkIcon"
        onClick={copySharingLink}
        data-testid="project-menu-copy-sharing-link-button"
      >
        i18n.common.copySharingLink
      </PopoverMenuItem>
    ),
    canDownloadVideo && project.videoAsset?.downloadUrl && (
      <PopoverMenuItem key="download" icon="DownloadIcon" onClick={downloadExportedVideo}>
        i18n.global.download
      </PopoverMenuItem>
    ),
  ].filter(Boolean);

  const bottom = [
    canViewConsents && withConsentFlag && (
      <PopoverMenuItem
        key="consent"
        icon="FilePdfIcon"
        component={RouterLink}
        to="settingsAccountProjectConsents"
        params={{ projectId: project.id }}
      >
        i18n.common.viewConsents
      </PopoverMenuItem>
    ),
    canUpdateProject && (
      <PopoverMenuItem
        key="archive"
        icon={project.archivedAt ? 'UndoIcon' : 'ArchiveIcon'}
        onClick={project.archivedAt ? unarchiveProject : archiveProject}
        data-testid="project-menu-archive-button"
      >
        {project.archivedAt ? 'i18n.common.unarchive' : 'i18n.common.archive'}
      </PopoverMenuItem>
    ),
    __PROJECT_TEMPLATES__ && (
      <PopoverMenuItem
        key="create-project-template"
        icon="LayoutIcon"
        onClick={onCreateTemplate}
        data-testid="create-project-template-button"
      >
        i18n.projectTemplates.createPopoverText
      </PopoverMenuItem>
    ),
    canDeleteProject && (
      <PopoverMenuItem
        key="delete"
        icon="TrashIcon"
        color="negative"
        onClick={deleteProject}
        data-testid="project-menu-delete-button"
      >
        i18n.global.delete
      </PopoverMenuItem>
    ),
  ].filter(Boolean);

  const children = [
    ...top,
    top.length && middle.length ? <PopoverMenuDivider key="divider-1" /> : null,
    ...middle,
    (top.length || middle.length) && middle.length ? <PopoverMenuDivider key="divider-2" /> : null,
    ...bottom,
  ].filter(Boolean);

  if (children.length === 0) {
    return null;
  }

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, { children });

  return <PopoverMenuIcon data-testid="project-menu-button" />;
};
