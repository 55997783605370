import { useFeatureFlags } from '@cofenster/web-components';

import type { MaybeSubtitlesLanguage } from '../../api/hooks/renderDescription/types';
import { useProjectRenderDescription } from '../../api/hooks/renderDescription/useProjectRenderDescription';
import type { MySubtitle } from '../../api/hooks/subtitle/useMySubtitle';
import { useTeamPermissionStatus } from '../../contexts/user/TeamPermissionRestriction';

import { useIsTooLongToExport } from './useIsTooLongToExport';

// We need to pass `subtitlesLanguage` here because `renderDescription` has an ID, but at the same time,
// it is generated dynamically based on the language of the subtitle. Without this, the Apollo cache will
// be overridden by the same ID but with a different language (or no language in this case), and there will be
// no subtitles.
export const useIsAllowedToRenderProject = (projectId: string, subtitlesLanguage: MaybeSubtitlesLanguage) => {
  const { projectRenderDescription } = useProjectRenderDescription(projectId, subtitlesLanguage);
  const isAllowedToRenderProject = useTeamPermissionStatus({ has: 'ProjectRender' }) === 'ALLOWED';
  const { isTooLongToExport } = useIsTooLongToExport(projectRenderDescription?.totalDurationInSeconds ?? 0);
  const withEmergencyDisableRenderFlag = useFeatureFlags().hasFeature('EMERGENCY_DISABLE_RENDER');

  return isAllowedToRenderProject && !isTooLongToExport && !withEmergencyDisableRenderFlag;
};

export const useIsAllowedToRenderSubtitle = (subtitle: MySubtitle) => {
  const isAllowedToRenderSubtitle = useTeamPermissionStatus({ has: 'SubtitlesUpdate' }) === 'ALLOWED';
  const { isTooLongToExport } = useIsTooLongToExport(subtitle.renderDescription?.totalDurationInSeconds ?? 0);
  const withEmergencyDisableRenderFlag = useFeatureFlags().hasFeature('EMERGENCY_DISABLE_RENDER');

  return isAllowedToRenderSubtitle && !isTooLongToExport && !withEmergencyDisableRenderFlag;
};
