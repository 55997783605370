import { useCallback } from 'react';

import { useScenes } from '../../../../../contexts/scenes/useScenes';
import { useGotoProjectEditScene } from '../../../../../hooks/navigation/useGotoProjectEditScene';

import { useExcludedSceneSnackbar } from './useExcludedSceneSnackbar';
import { useTrackSceneSelected } from './useTrackSceneSelected';

export const useSetCurrentScene = (projectId: string) => {
  const { scenes } = useScenes();
  const trackSceneSelected = useTrackSceneSelected();
  const gotoProjectEditScene = useGotoProjectEditScene(projectId, { withTimeTravel: true });
  const goToProjectEdit = useGotoProjectEditScene(projectId);
  const openExcludedSceneSnackbar = useExcludedSceneSnackbar(goToProjectEdit);

  return useCallback(
    (sceneId: string) => {
      trackSceneSelected(sceneId);

      if (__PREVIEW_EXCLUDED_SCENES__) {
        gotoProjectEditScene(sceneId);
      } else {
        if (scenes.find((scene) => scene.id === sceneId)?.excluded) {
          openExcludedSceneSnackbar(sceneId);
        } else {
          gotoProjectEditScene(sceneId);
        }
      }
    },
    [scenes, openExcludedSceneSnackbar, gotoProjectEditScene, trackSceneSelected]
  );
};
