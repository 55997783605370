import { createContext } from 'react';

import type { ProjectsSearchFilters } from './useProjectsSearchFilters';

export type SearchFilterContext = {
  filter: ProjectsSearchFilters;
  page: number;
  setPage: (page: number) => void;
  setSearch: (search: ProjectsSearchFilters['search']) => void;
  setStatus: (status: ProjectsSearchFilters['status']) => void;
  setCreatedBy: (createdBy: ProjectsSearchFilters['createdBy']) => void;
};

export const SearchFilterContext = createContext<SearchFilterContext | undefined>(undefined);
