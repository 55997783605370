import { type FC, useCallback } from 'react';

import { BlankButton, Translatable, styled, useSnackbars } from '@cofenster/web-components';

import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';

import { useIncludeScene } from './useIncludeScene';

const IncludeSceneButtonLink = styled(BlankButton)(({ theme }) => ({
  color: theme.palette.brand.white,
  textDecoration: 'underline',

  '&:hover': { color: theme.palette.brand.warning },
  '&:focus-visible': theme.mixins.focusRing,
}));

const SnackbarContent: FC<{ onClick?: VoidFunction }> = ({ onClick }) =>
  onClick ? (
    <Translatable
      i18nParams={{
        button: (chunks) => (
          <IncludeSceneButtonLink type="button" onClick={onClick}>
            {chunks}
          </IncludeSceneButtonLink>
        ),
      }}
    >
      i18n.snackbars.ShowSceneSnackbar.title.interactive
    </Translatable>
  ) : (
    <Translatable>i18n.snackbars.ShowSceneSnackbar.title</Translatable>
  );

export const useExcludedSceneSnackbar = (onIncludeScene: (sceneId: string) => unknown) => {
  const { openSnackbar, closeSnackbar } = useSnackbars();
  const includeScene = useIncludeScene(onIncludeScene);
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  return useCallback(
    (sceneId: string) => {
      const snackbarId = openSnackbar({
        children: (
          <SnackbarContent
            onClick={
              canUpdateProject
                ? () => {
                    includeScene(sceneId);
                    closeSnackbar(snackbarId);
                  }
                : undefined
            }
          />
        ),
      });
    },
    [canUpdateProject, closeSnackbar, includeScene, openSnackbar]
  );
};
