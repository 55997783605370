import { useCallback } from 'react';

import { isCancellationError, uploadFile } from '../../utilities/uploadFile';

export const useFileUpload = () => {
  return useCallback(
    async (
      uploadUrl: string,
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal']
    ) => {
      onProgress(uploadId, 0);

      try {
        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => onProgress(uploadId, progress),
          signal: abortSignal,
        });

        onProgress(uploadId, 1);
      } catch (error) {
        if (isCancellationError(error)) {
          onProgress(uploadId, 0);
          return undefined;
        }
        throw error;
      }

      return uploadId;
    },
    []
  );
};
