import { useEffect, useState } from 'react';

import { captureException } from '@cofenster/web-components';

type VideoAsset = {
  thumbnailUrl?: null | undefined | string;
  videoUrl?: null | undefined | string;
};

export const useVideoAssetTumbnail = (videoAsset: VideoAsset | null | undefined, time = 1) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(videoAsset?.thumbnailUrl);

  useEffect(() => {
    if (videoAsset?.thumbnailUrl) {
      return setThumbnailUrl(videoAsset.thumbnailUrl);
    }

    if (!videoAsset?.videoUrl) {
      return setThumbnailUrl(null);
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return;

    // Mark the video as cross-origin so the canvas can be safely exported
    const video = document.createElement('video');
    video.setAttribute('src', videoAsset.videoUrl);
    video.setAttribute('crossOrigin', 'anonymous');
    video.load();

    function onVideoLoad() {
      // Delay applying the time to work around an issue with Safari
      // See: https://stackoverflow.com/a/63474748
      setTimeout(() => {
        video.currentTime = time;
      }, 200);

      // Only resize the canvas to the video dimensions once the video metadata
      // has been loaded to ensure they are correct
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }

    function onVideoSeeked() {
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setThumbnailUrl(context.canvas.toDataURL());
    }

    video.addEventListener('error', captureException);
    video.addEventListener('loadedmetadata', onVideoLoad);
    video.addEventListener('seeked', onVideoSeeked);

    return () => {
      video.removeEventListener('error', captureException);
      video.removeEventListener('loadedmetadata', onVideoLoad);
      video.removeEventListener('seeked', onVideoSeeked);
    };
  }, [videoAsset?.thumbnailUrl, videoAsset?.videoUrl, time]);

  return thumbnailUrl;
};
