import { type FC, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  GridContainer,
  GridItem,
  SubNavigation,
  Translatable,
  Typography,
  styled,
  useSnackbars,
} from '@cofenster/web-components';

import { useContributionRequestsByProject } from '../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { SubNavigationItem } from '../../../components/button/SubNavigationItem';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { ContributionRequestList } from '../ContributionRequestList';
import { ContributionConfigurationSettings } from './ContributionConfigurationSettings';
import { ContributionRecordings } from './ContributionRecordings';
import type { ContributionConfigurationTab } from './types';

const Container = styled('div')(() => ({
  maxWidth: 768,
  alignSelf: 'center',
  width: '100%',
}));

const ContainerWithBottomBorder = styled(Container)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.brand.linen50}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const RecordingsTabHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const TotalContributionsBadge = styled('div')(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.blue,
  color: theme.palette.brand.white,
  padding: theme.spacing(0.25, 0.5),
}));

const SubNavigationCenterContainer = styled('div')(({ theme }) => ({
  maxWidth: `calc(100vw - ${theme.spacing(4)})`,
}));

export const ContributionConfigurationTabs: FC<{
  projectId: string;
  currentTab: ContributionConfigurationTab;
  videoFormat: VideoFormat;
}> = ({ projectId, currentTab, videoFormat }) => {
  const { data } = useContributionRequestsByProject(projectId, 10_000);
  const requests = data?.contributionRequestsByProject ?? [];
  const totalContributionsCount = useMemo(
    () => requests.reduce((acc, next) => acc + next.contributions.length, 0),
    [requests]
  );
  const { openSnackbar } = useSnackbars();
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  return (
    <GridContainer spacing={4}>
      <GridItem xs={12} display="flex" justifyContent="center">
        <ContainerWithBottomBorder>
          <SubNavigationCenterContainer>
            <SubNavigation>
              <SubNavigationItem
                to="projectContributionsConfiguration"
                params={{ projectId, type: 'requests' }}
                iconType="ClipboardIcon"
                active={currentTab === 'requests'}
                data-testid="contribution-requests-tab"
              >
                i18n.projectContributionConfiguration.tabs.requests
              </SubNavigationItem>
              <SubNavigationItem
                to="projectContributionsConfiguration"
                params={{ projectId, type: 'recordings' }}
                iconType="ShareFatIcon"
                active={currentTab === 'recordings'}
                data-testid="contribution-recordings-tab"
              >
                <RecordingsTabHeaderContainer>
                  <Translatable>i18n.projectContributionConfiguration.tabs.recordings</Translatable>
                  {totalContributionsCount > 0 && (
                    <TotalContributionsBadge>
                      <Typography variant="s">{totalContributionsCount}</Typography>
                    </TotalContributionsBadge>
                  )}
                </RecordingsTabHeaderContainer>
              </SubNavigationItem>
              <SubNavigationItem
                to="projectContributionsConfiguration"
                params={{ projectId, type: 'settings' }}
                iconType="GearIcon"
                active={currentTab === 'settings'}
                data-testid="contribution-settings-tab"
              >
                i18n.projectContributionConfiguration.tabs.settings
              </SubNavigationItem>
            </SubNavigation>
          </SubNavigationCenterContainer>
        </ContainerWithBottomBorder>
      </GridItem>
      <GridItem xs={12} display="flex" justifyContent="center">
        <Container>
          {currentTab === 'requests' && <ContributionRequestList projectId={projectId} />}
          {currentTab === 'recordings' && (
            <ContributionRecordings
              projectId={projectId}
              videoFormat={videoFormat}
              onSceneCreated={() => {
                openSnackbar({
                  variant: 'info',
                  children: 'i18n.ContributionRequestsDialog.snackbar.contributionAdded',
                });
              }}
              canUpdateProject={canUpdateProject}
            />
          )}
          {currentTab === 'settings' && <ContributionConfigurationSettings projectId={projectId} />}
        </Container>
      </GridItem>
    </GridContainer>
  );
};
