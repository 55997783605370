import { type FormikValues, useFormikContext } from 'formik';
import { type FC, useEffect } from 'react';

export type FormObserverProps = {
  onChange: (values: FormikValues) => unknown;
};

export const FormObserver: FC<FormObserverProps> = ({ onChange }) => {
  const { values } = useFormikContext<FormikValues>();
  useEffect(() => {
    onChange(values);
  }, [onChange, values]);
  return null;
};
