import { type MouseEventHandler, type PropsWithChildren, type Ref, forwardRef, useCallback } from 'react';
import { Link, type LinkProps, type Params, useLocation, useNavigate } from 'react-router-dom';

import { areViewTransitionsSupported, generatePath, transitionView } from '@cofenster/web-components';

import { type RouteName, routes } from '../../routes';

export type RouterLinkProps = Omit<LinkProps, 'to' | 'type'> & {
  to: RouteName;
  params?: Params;
  query?: string;
  withViewTransition?: boolean;
  forwardQueryParams?: boolean;
};

export const RouterLink = forwardRef<typeof Link, PropsWithChildren<RouterLinkProps>>(
  (
    { children, to, params, state, onClick, withViewTransition, reloadDocument, query, forwardQueryParams, ...rest },
    ref
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const destination = generatePath(routes[to], params) + (query ?? (forwardQueryParams ? location.search : ''));
    const navigateToDestination = useCallback(() => navigate(destination, { state }), [navigate, destination, state]);
    const needsWrappedOnClick = !reloadDocument && withViewTransition && areViewTransitionsSupported();
    const handleTransitionClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
      (event) => {
        event.preventDefault();
        onClick?.(event);

        if (withViewTransition) transitionView(navigateToDestination);
        else navigateToDestination();
      },
      [onClick, withViewTransition, navigateToDestination]
    );

    return (
      <Link
        to={destination}
        {...rest}
        ref={ref as Ref<HTMLAnchorElement>}
        rel={rest.target === '_blank' ? 'noopener noreferrer' : undefined}
        onClick={needsWrappedOnClick ? handleTransitionClick : onClick}
      >
        {children}
      </Link>
    );
  }
);

RouterLink.displayName = 'RouterLink';
