import { styled } from '@mui/material';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';

import type { AssetDropzoneSize } from './DropzoneContent';

export const DropzoneContainer = styled(
  'div',
  preventForwardProps(['size'])
)<{ size: AssetDropzoneSize }>(({ size = 'small' }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: size === 'small' ? 160 : 220,
}));
