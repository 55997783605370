import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Dialog, DialogContent, LoadingSpinner } from '@cofenster/web-components';

import { useContributionRequestsById } from '../../../api/hooks/contributionRequest/useContributionRequestsById';
import { ContributionRequestDialogContent } from './ContributionRequestDialogContent';

type ContributionRequestsDialogProps = {
  closeDialog: () => unknown;
  isOpen: boolean;
  title: string;
  contributionRequestId: string;
  totalContributions: number;
  videoFormat: VideoFormat;
  canUpdateProject: boolean;
};

export const ContributionRequestsDialog: FC<ContributionRequestsDialogProps> = ({
  closeDialog,
  isOpen,
  title,
  totalContributions,
  videoFormat,
  contributionRequestId,
  canUpdateProject,
}) => {
  // Fetch each time modal is opened
  const { data } = useContributionRequestsById(contributionRequestId, 'network-only');

  return (
    <Dialog open={isOpen} onClose={closeDialog} title={title} size="l" withHeaderBottomBorder>
      <DialogContent>
        {!data?.contributionRequestById ? (
          <LoadingSpinner />
        ) : (
          <ContributionRequestDialogContent
            totalContributions={totalContributions}
            contributionRequest={data.contributionRequestById}
            videoFormat={videoFormat}
            canUpdateProject={canUpdateProject}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
