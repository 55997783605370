import { useCallback } from 'react';
import { useDialogs } from '../../../../../contexts/dialogs';
import { useActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';

export const useOnMoveToProject = (projectId: string, sceneId: string) => {
  const { openDialog } = useDialogs();
  const actionSceneIds = useActionSceneIds(sceneId);

  return useCallback(() => {
    openDialog('MoveScenesToProjectDialog', {
      sceneIds: actionSceneIds,
      currentProjectId: projectId,
    });
  }, [openDialog, projectId, actionSceneIds]);
};
