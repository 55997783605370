import type { FormikValues } from 'formik';
import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { IntercomButton } from '@cofenster/intercom';
import { Form, FormPasswordField, FormSubmitButton, GridContainer, GridItem } from '@cofenster/web-components';

import { useResetPassword } from '../../api/hooks/user/useResetPassword';
import { useGotoDeactivatedUser } from '../../hooks/navigation/useGotoDeactivatedUser';
import { useGotoHomeHandler } from '../../hooks/navigation/useGotoHomeHandler';
import type { ResetPasswordRouteParams } from '../../routes';

const initialValues = { password: '', passwordConfirmation: '' };

type InitialValues = typeof initialValues;

const validationSchema: Yup.ObjectSchema<InitialValues> = Yup.object().shape({
  password: Yup.string().trim().required('i18n.form.error.password.required'),
  passwordConfirmation: Yup.string()
    .trim()
    .required('i18n.form.error.passwordRepeat.required')
    .oneOf([Yup.ref('password')], 'i18n.form.error.passwordRepeat.match'),
});

const useOnSubmit = () => {
  const { token } = useParams() as ResetPasswordRouteParams;
  const resetPassword = useResetPassword();
  const gotoDeactivatedUser = useGotoDeactivatedUser();
  const gotoHome = useGotoHomeHandler();

  return useCallback(
    async (values: FormikValues) => {
      if (!token) {
        return;
      }

      const { password } = values as InitialValues;
      const result = await resetPassword(token, password);
      const resultTypename = result.data?.resetPassword.__typename;
      if (
        resultTypename === 'DeactivatedUserError' ||
        resultTypename === 'DeletedUserError' ||
        resultTypename === 'UserNotFoundError'
      ) {
        return gotoDeactivatedUser();
      }
      if (resultTypename === 'InvalidTokenError') {
        throw new Error('i18n.form.error.token.resetPassword');
      }
      if (resultTypename === 'WeakPasswordError') {
        throw new Error('i18n.form.error.password.weak');
      }

      if (result.data?.resetPassword.__typename === 'PasswordTokenResponse') gotoHome();
    },
    [resetPassword, token, gotoDeactivatedUser, gotoHome]
  );
};

export const ResetPasswordFrom: FC = () => {
  const onSubmit = useOnSubmit();

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange>
      <GridContainer spacing={0} alignItems="flex-end">
        <GridItem xs={12}>
          <FormPasswordField
            id="password"
            name="password"
            label="i18n.reset.form.password.placeholder"
            placeholder="i18n.reset.form.password.placeholder"
            data-testid="password-input"
          />
        </GridItem>
        <GridItem xs={12}>
          <FormPasswordField
            id="passwordConfirmation"
            name="passwordConfirmation"
            label="i18n.reset.form.passwordConfirmation.placeholder"
            placeholder="i18n.reset.form.passwordConfirmation.placeholder"
            data-testid="password-confirm-input"
          />
        </GridItem>
        <GridItem>
          <IntercomButton variant="tertiary" fullWidth>
            i18n.auth.button.needHelp
          </IntercomButton>
        </GridItem>
        <GridItem ml="auto">
          <FormSubmitButton autoDisable data-testid="password-save-button">
            i18n.forgot.form.submit
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
