import { type MouseEventHandler, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useGoToProjectContributionsPage } from '../../../hooks/navigation/useGoToProjectContributionsPage';
import { useGotoProjectEditScene } from '../../../hooks/navigation/useGotoProjectEditScene';

type Props = {
  projectId: string;
  videoFormat: VideoFormat;
  index: number;
  disabled?: boolean;
  hasContributionRequestListAvailable: boolean;
};

export const useAddNewSceneCard = ({
  index,
  projectId,
  videoFormat,
  hasContributionRequestListAvailable,
}: Omit<Props, 'disabled'>) => {
  const { openDialog } = useDialogs();
  const gotoProjectEdit = useGotoProjectEditScene(projectId);
  const goToProjectContributionsPage = useGoToProjectContributionsPage(projectId);
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  const closeAndNavigate = useCallback((sceneId: string) => gotoProjectEdit(sceneId), [gotoProjectEdit]);

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      openDialog('CreateScenesDialog', {
        projectId,
        videoFormat,
        index,
        onCloseDialog: closeAndNavigate,
        goToProjectContributionsPage,
        hasContributionRequestListAvailable,
        canUpdateProject,
      });
    },
    [
      projectId,
      index,
      openDialog,
      closeAndNavigate,
      goToProjectContributionsPage,
      videoFormat,
      hasContributionRequestListAvailable,
      canUpdateProject,
    ]
  );

  return onClick;
};
