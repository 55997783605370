import { type FC, memo } from 'react';

import { Card, GridContainer, GridItem, Headline, Text, styled, useBooleanState } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { ExpandedRouterLink } from '../../button/ExpandedRouterLink';

import { FolderCircleIcon } from './FolderCircleIcon';
import { PopoverMenu } from './PopoverMenu';

const Container = styled('div')(() => ({
  position: 'relative',
  isolation: 'isolate',
}));

const PopoverMenuContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.above,
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const Name = styled(Headline)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}));

type Props = {
  projectFolder: ProjectFolder;
};

// The `onMouserEnter` and `onMouseLeave` event handlers on the container cause this component to be aggressively re-
// rendered, which causes Cypress tests to be flaky.
const MemoizedPopover = memo(function MemoizedPopover({ projectFolder }: { projectFolder: ProjectFolder }) {
  return <PopoverMenu projectFolder={projectFolder} />;
});

export const FolderCard: FC<Props> = ({ projectFolder }) => {
  const [hovered, onMouseEnter, onMouseLeave] = useBooleanState(false);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="folder-card"
      id={`folder-${projectFolder.id}`}
    >
      <Card spacing={{ all: 3 }}>
        <PopoverMenuContainer>
          <MemoizedPopover projectFolder={projectFolder} />
        </PopoverMenuContainer>

        <GridContainer alignItems="center" spacing={1}>
          <GridItem display="flex" alignSelf="flex-start">
            <FolderCircleIcon open={hovered} />
          </GridItem>
          <GridItem xs={true} zeroMinWidth>
            <Name variant="h5" component="p">
              <ExpandedRouterLink to="projectFolder" params={{ folderId: projectFolder.id }}>
                {projectFolder.name}
              </ExpandedRouterLink>
            </Name>
            <Text variant="s" color="grey700" i18nParams={{ count: projectFolder.projectCount ?? 0 }}>
              i18n.projects.folderCard.videos
            </Text>
          </GridItem>
          <GridItem xs={1} />
        </GridContainer>
      </Card>
    </Container>
  );
};
