import { type KeyboardEventHandler, useEffect } from 'react';

// See: https://github.com/jamiebuilds/tinykeys/pull/193
// @ts-expect-error
import { tinykeys } from 'tinykeys';
type KeyBindingMap = { [keybinding: string]: KeyboardEventHandler };
type TinyKeysPatch = (element: Window | HTMLElement, keyBindingMap: KeyBindingMap) => () => void;

export const useKeypress = (
  action: KeyboardEventHandler,
  shortcut: string,
  options?: {
    element?: HTMLElement | Window;
    ignoreOnFocusedInput?: boolean;
    ignoreOnFocusedButton?: boolean;
  }
) => {
  useEffect(() => {
    const element = options?.element ?? window;
    const ignoreOnFocusedInput = options?.ignoreOnFocusedInput ?? true;
    const ignoreOnFocusedButton = options?.ignoreOnFocusedButton ?? false;

    const unsubscribe = (tinykeys as TinyKeysPatch)(element, {
      [shortcut]: (event) => {
        const target = event.target as HTMLElement;

        const fieldInFocus = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
        const buttonInFocus = target.tagName === 'BUTTON' || target.getAttribute('role') === 'button';
        const labelInFocus = target.tagName === 'LABEL' && target.tabIndex !== undefined;

        if (fieldInFocus && ignoreOnFocusedInput) return;
        if ((buttonInFocus || labelInFocus) && ignoreOnFocusedButton) return;

        event.preventDefault();
        action(event);
      },
    });

    return () => unsubscribe();
  }, [action, shortcut, options]);
};
