import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { GridContainer, GridItem, LoadingSpinner, Typography } from '@cofenster/web-components';

import { useContributionRequestsByProject } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { ContributionAssetListItem } from '../../../../components/dialog/ContributionRequestsDialog/ContributionRequestDialogContent/ContributionAssetListItem';
import { useI18n } from '../../../../i18n';

import { ContributionRecordingsEmptyState } from './ContributionRecordingsEmptyState';
import { ContributionRequestsSelect } from './ContributionRequestsSelect';
import { ContributorSelect } from './ContributorSelect';
import { useFilterContributionRequests } from './useFilterContributionRequests';

export const ContributionRecordings: FC<{
  projectId: string;
  videoFormat: VideoFormat;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
  goToProjectContributionsPage?: () => unknown;
  canUpdateProject: boolean;
}> = ({ projectId, videoFormat, onSceneCreated, newSceneIndex, goToProjectContributionsPage, canUpdateProject }) => {
  const { translate } = useI18n();
  const { data, loading: loadingContributionRequests } = useContributionRequestsByProject(projectId);
  const requests = data?.contributionRequestsByProject ?? [];
  const {
    requestIds,
    onRequestChange,
    contributorIds,
    onContributorChange,
    contributors,
    filteredContributionItems,
    contributionItems,
  } = useFilterContributionRequests(requests);

  if (loadingContributionRequests) return <LoadingSpinner />;

  if (!contributionItems.length) {
    return <ContributionRecordingsEmptyState goToProjectContributionsPage={goToProjectContributionsPage} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} md={4} alignItems="center" display="flex">
        <Typography variant="h5" i18nParams={{ count: filteredContributionItems.length }} component="p">
          i18n.projectContributions.ContributionRequestCard.contributionsCount
        </Typography>
      </GridItem>
      <GridItem xs={12} md={4}>
        <ContributorSelect contributors={contributors} value={contributorIds} onChange={onContributorChange} />
      </GridItem>
      <GridItem xs={12} md={4}>
        <ContributionRequestsSelect contributionRequests={requests} value={requestIds} onChange={onRequestChange} />
      </GridItem>
      {filteredContributionItems?.map(({ asset, contributionRequest, contributors }) => (
        <GridItem key={asset.id} xs={12} sm={6} md={4}>
          <ContributionAssetListItem
            asset={asset}
            videoFormat={videoFormat}
            contributionRequestId={contributionRequest.id}
            contributionRequestTitle={
              contributionRequest.title
                ? contributionRequest.title
                : translate('projectContributionConfiguration.filter.byRequests.emptyCRTitle', {
                    index: contributionRequest.index + 1,
                  })
            }
            scenesCount={asset.scenes?.length ?? 0}
            contributors={contributors}
            onSceneCreated={onSceneCreated}
            newSceneIndex={newSceneIndex}
            canUpdateProject={canUpdateProject}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
};
