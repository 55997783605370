import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type EventTrack, useTracking } from '@cofenster/web-components';

import { useProject } from '../contexts/project/useProject';
import { useUser } from '../contexts/user/useUser';
import { useUserContextRole } from '../contexts/user/useUserContextRole';

export const useWebManagerDefaultTrackingDetails = (explicitTeamId?: string) => {
  const { sceneId, teamId: teamIdFromUrl, projectId: projectIdFromUrl } = useParams();
  const teamId = explicitTeamId ?? teamIdFromUrl;
  const userContextRole = useUserContextRole(teamId);
  const { user } = useUser();
  const accountId = user?.account?.id;
  const { project } = useProject();
  const projectId = project?.id ?? projectIdFromUrl;
  const projectName = project?.name;

  return useMemo(
    () => ({
      sceneId,
      teamId,
      projectId,
      projectName,
      userContextRole,
      accountId,
    }),
    [sceneId, teamId, projectId, projectName, userContextRole, accountId]
  );
};

export const useWebManagerTracking = (explicitTeamId?: string) => {
  const tracking = useTracking();
  const defaultTrackingData = useWebManagerDefaultTrackingDetails(explicitTeamId);

  return useMemo(() => {
    const { sceneId, teamId, projectId, projectName, userContextRole, accountId } = defaultTrackingData;
    return {
      ...tracking,
      trackEvent(track: EventTrack) {
        tracking.trackEvent({
          ...track,
          details: { sceneId, teamId, projectId, projectName, ...track.details },
          userContextRole,
          accountId,
        });
      },
    };
  }, [tracking, defaultTrackingData]);
};
