import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { NativeVideoPlayer, ProcessingIndicator, styled } from '@cofenster/web-components';
import { RemotionPlayer } from '@cofenster/web-remotion-player';

import { useSceneRenderDescription } from '../../../../api/hooks/renderDescription/useSceneRenderDescription';
import type { ThemeVideoType } from '../../../../api/hooks/user/useMe';
import { useIntroOutro } from '../../../../contexts/editorPlayer/useIntroOutro';
import type { BackgroundType, Project } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import type { ProjectEditorRouteParams } from '../../../../routes';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { useMoveSceneKeyboardHandler } from '../../hooks/useMoveSceneKeyboardHandler';
import { EditorMusicHint } from '../EditorMusicHint';
import { ActionsContainer, EditorPlayerActions } from '../EditorPlayerActions';
import { EditorPlayerControls } from '../EditorPlayerControls';
import { EditorPreview } from '../EditorPreview';
import { SceneExcludedHint } from './SceneExcludedHint';

// 1. The theme background is normally applied within the template, so we need
//    to reproduce that behavior. Note that the “Blurred” background mode would
//    just not work here.
const StyledVideoPlayer = styled(NativeVideoPlayer)<{ background: BackgroundType }>(({ background }) => ({
  backgroundColor: background === 'Primary' ? 'var(--primary-color)' : '#000', // 1
}));

const StyledProcessingIndicator = styled(ProcessingIndicator)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
}));

type EditorPlayerProps = {
  project: Project;
  showMusicHint?: boolean;
  isAssetProcessing?: boolean;
};

export const EditorPlayer: FC<EditorPlayerProps> = ({ project, showMusicHint, isAssetProcessing }) => {
  const { sceneId } = useParams() as ProjectEditorRouteParams;
  const { currentScene } = useScenes();
  const { intro, outro, isEnabled } = useIntroOutro();
  const isIntroOrOutro = sceneId && ['intro', 'outro'].includes(sceneId);

  // Only query the scene render description if targeting an excluded scene so
  // we can render a standalone Remotion preview
  const shouldQuerySceneRD = currentScene?.excluded && __PREVIEW_EXCLUDED_SCENES__;
  const { sceneRenderDescription } = useSceneRenderDescription(
    project.id,
    shouldQuerySceneRD ? currentScene.id : undefined
  );

  useMoveSceneKeyboardHandler();

  // For both the intro and the outro, if they are *uploaded* (which is implied
  // by the asset check done in the parent) but *disabled*, we cannot render the
  // regular Remotion preview since the scene does not belong to the render
  // description. In that case, we want to render a video player so the scene
  // can still be selected, previewed and managed.
  if (isIntroOrOutro && !isEnabled(sceneId as Lowercase<ThemeVideoType>)) {
    const scene = sceneId === 'intro' ? intro : outro;
    const src = scene?.videoAsset?.videoUrl;
    const background = project.theme?.background ?? 'Black';

    // This cannot happen as there is a `hasAsset` check in the parent component
    if (!src) return null;

    // @TODO: check how to render a Remotion preview with just the intro or
    // outro instead of a standalone video player
    return (
      <ResponsiveContainer project={project} data-testid={`${sceneId}-preview`}>
        <StyledVideoPlayer src={src} background={background} />
      </ResponsiveContainer>
    );
  }

  if (sceneRenderDescription) {
    return (
      <>
        <ResponsiveContainer project={project} data-testid={`${sceneId}-preview`}>
          <RemotionPlayer
            bundleUrl={project.template.previewBundleUrl}
            templateIdentifier={project.template.templateIdentifier}
            renderDescription={sceneRenderDescription}
            controls
          />
        </ResponsiveContainer>
        <ActionsContainer>
          <SceneExcludedHint sceneId={currentScene?.id} />
        </ActionsContainer>
      </>
    );
  }

  return (
    <>
      <ResponsiveContainer project={project} data-testid="editor-preview">
        <EditorPreview
          bundleUrl={project.template.previewBundleUrl}
          templateIdentifier={project.template.templateIdentifier}
        />

        {isAssetProcessing && <StyledProcessingIndicator />}

        {showMusicHint && <EditorMusicHint />}

        <EditorPlayerControls projectId={project.id} />
      </ResponsiveContainer>

      <EditorPlayerActions />
    </>
  );
};
