import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { Form, GridContainer, GridItem, IconButton, Tooltip, styled } from '@cofenster/web-components';

import { ClickAwayListener } from '@mui/base';
import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useUpdateContributionRequest } from '../../../../api/hooks/contributionRequest/useUpdateContributionRequest';
import { SmartFormField } from '../../SmartFormField';
import { useTrackContributionRequest } from '../../hooks/useTrackContributionRequest';
import { ContributionRequestMoreOptionsMenu } from '../ContributionRequestMoreOptionsMenu';
import { MarkdownTooltip } from '../MarkdownTooltip';

type Values = {
  title: string | undefined;
  instruction: string | undefined;
};

const useInitialValues = (contributionRequest: ContributionRequest): Values =>
  useMemo(
    () => ({
      title: contributionRequest.title ?? '',
      instruction: contributionRequest.instruction ?? '',
    }),
    [contributionRequest]
  );

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  title: Yup.string().trim(),
  instruction: Yup.string().trim(),
});

const useSubmit = (contributionRequestId: string) => {
  const track = useTrackContributionRequest('requestTextUpdated');
  const { updateContributionRequest } = useUpdateContributionRequest();
  return useCallback(
    async (values: FormikValues) => {
      const result = await updateContributionRequest(contributionRequestId, values as Values);
      if (result.data?.updateContributionRequest) {
        track(result.data.updateContributionRequest);
      }
    },
    [contributionRequestId, updateContributionRequest, track]
  );
};

const InstructionField = styled(SmartFormField)(() => ({
  '&:hover ~ button, &:focus ~ button': {
    opacity: 1,
  },
}));

const TooltipToggle = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0.5),
  right: theme.spacing(1),
  opacity: 0,

  '&:hover, &:focus, &[aria-labelledby]': {
    opacity: 1,
  },
}));

export const UpdateContributionRequestForm: FC<{ item: ContributionRequest }> = ({ item }) => {
  const initialValues = useInitialValues(item);
  const onSubmit = useSubmit(item.id);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const closeTooltip = useCallback(() => setIsTooltipOpen(false), []);
  const toggleTooltip = useCallback(() => setIsTooltipOpen((state) => !state), []);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer spacing={1} mb={0} mt={0}>
        <GridItem flex={1} mr={2}>
          <SmartFormField
            id={`title-${item.id}`}
            name="title"
            label="i18n.projectContributions.ContributionRequestCard.form.title.label"
            placeholder="i18n.projectContributions.ContributionRequestCard.form.title.placeholder"
            variant="h5"
            data-testid="contribution-request-title-input"
          />
        </GridItem>
        <GridItem>
          <ContributionRequestMoreOptionsMenu contributionRequest={item} />
        </GridItem>
        <GridItem xs={12} position="relative">
          <InstructionField
            id={`instruction-${item.id}`}
            name="instruction"
            label="i18n.projectContributions.ContributionRequestCard.form.instructions.label"
            placeholder="i18n.projectContributions.ContributionRequestCard.form.instructions.placeholder"
            variant="l"
            multiline
            maxRows={7}
            data-testid="contribution-request-instruction-input"
          />
          <Tooltip
            title={
              <ClickAwayListener onClickAway={closeTooltip}>
                <div>
                  <MarkdownTooltip />
                </div>
              </ClickAwayListener>
            }
            variant="light"
            maxWidth={600}
            withShadow
            placement="top-end"
            open={isTooltipOpen}
            onClose={closeTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <TooltipToggle
              icon="KeyboardIcon"
              onClick={toggleTooltip}
              label="i18n.markdownTooltip.toggle"
              tooltip={null}
              data-testid="markdown-tooltip-toggle"
            />
          </Tooltip>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
