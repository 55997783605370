import { useMemo } from 'react';

import {
  type DeleteAssetFromContributionRequestMutationVariables,
  useDeleteAssetFromContributionRequestMutation,
} from '../../generated';

export const useDeleteAssetFromContributionRequest = (
  contributionRequestId: DeleteAssetFromContributionRequestMutationVariables['contributionRequestId'],
  assetId: DeleteAssetFromContributionRequestMutationVariables['input']['assetId']
) => {
  const [deleteAssetFromContributionRequest, { loading }] = useDeleteAssetFromContributionRequestMutation({
    refetchQueries: ['Scenes'],
    awaitRefetchQueries: true,
  });

  return useMemo(() => {
    return {
      deleteAssetFromContributionRequest: () =>
        deleteAssetFromContributionRequest({
          variables: { contributionRequestId, input: { assetId } },
        }),
      loading,
    };
  }, [deleteAssetFromContributionRequest, loading, contributionRequestId, assetId]);
};
