import type { FormikValues } from 'formik';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSelect,
  Icon,
  Spacing,
  Text,
  renderBasicOptions,
  useSnackbars,
} from '@cofenster/web-components';

import { useMoveProjectFolder } from '../../../api/hooks/projectFolder/useMoveProjectFolder';
import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { type Team, useTeams } from '../../../api/hooks/team/useTeams';
import { useAccountPermissionStatus } from '../../../contexts/user/AccountPermissionRestriction';
import { useRole } from '../../../contexts/user/useRole';

type Values = {
  parentFolderId: string;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  parentFolderId: Yup.string().required(),
});

const useSubmit = (
  projectFolder: ProjectFolder,
  teams: Team[],
  closeDialog: MoveProjectFolderDialogProps['closeDialog']
) => {
  const moveProjectFolder = useMoveProjectFolder();
  const { openSnackbar } = useSnackbars();

  return useCallback(
    async (values: FormikValues) => {
      const input = values as Values;
      const team = teams.find((team) => team.rootProjectFolder.id === input.parentFolderId);
      const result = await moveProjectFolder(projectFolder.id, input);
      if (!result.data?.moveProjectFolder) return;

      closeDialog();
      openSnackbar({
        children: 'i18n.snackbars.projectFolderMoved.copy',
        i18nParams: { teamName: team?.name ?? '' },
      });
    },
    [moveProjectFolder, projectFolder.id, teams, closeDialog, openSnackbar]
  );
};

type MoveProjectFolderDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectFolder: ProjectFolder;
};

export const MoveProjectFolderDialog: FC<MoveProjectFolderDialogProps> = ({ isOpen, closeDialog, projectFolder }) => {
  const [initialValues, setInitialValues] = useState<Values>({ parentFolderId: '' });
  const { teams, loading } = useTeams();
  const { teamRoles } = useRole();
  const isAllowedToCreateAFolder = useAccountPermissionStatus({ has: 'FolderCreate' }) === 'ALLOWED';

  const teamOptions = useMemo(() => {
    if (!teams?.length) return [];

    return teams
      .filter((team) => team.rootProjectFolder.id !== projectFolder.parentFolder?.id)
      .filter((team) =>
        team.public
          ? isAllowedToCreateAFolder
          : teamRoles.find((teamRole) => teamRole.teamId === team.id)?.role.permissions.includes('FolderCreate')
      )
      .map((team) => ({
        value: team.rootProjectFolder.id,
        children: team.name,
      }));
  }, [isAllowedToCreateAFolder, teams, projectFolder, teamRoles]);

  useEffect(() => {
    setInitialValues({ parentFolderId: teamOptions[0]?.value ?? '' });
  }, [teamOptions]);

  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();

  const onChange = useCallback(
    (values: FormikValues) => {
      const input = values as Values;
      const team = teams?.find((team) => team.rootProjectFolder.id === input.parentFolderId);
      setSelectedTeam(team);
    },
    [teams]
  );

  const onSubmit = useSubmit(projectFolder, teams ?? [], closeDialog);

  const isInPrivateSpace = !projectFolder.team;
  const canMove = !!teamOptions.length;

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.moveProjectFolderDialog.headline">
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} onChange={onChange}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              {loading || canMove
                ? 'i18n.dialogs.moveProjectFolderDialog.description'
                : 'i18n.dialogs.moveProjectFolderDialog.noTeams'}
            </Text>
          </Spacing>

          {(loading || canMove) && (
            <>
              {isInPrivateSpace && (
                <Spacing bottom={2}>
                  <Alert
                    severity="info"
                    icon={<Icon type="InfoIcon" size="m" />}
                    i18nParams={{
                      team: <strong>{selectedTeam?.name}</strong>,
                    }}
                  >
                    i18n.dialogs.moveProjectFolderDialog.moveOutOfPrivateSpace
                  </Alert>
                </Spacing>
              )}
              <FormSelect label="i18n.dialogs.moveProjectFolderDialog.teamSelect.label" name="parentFolderId">
                {renderBasicOptions(teamOptions)}
              </FormSelect>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <Button fullWidth type="submit" disabled={!canMove}>
            i18n.dialogs.moveProjectFolderDialog.moveFolder
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
