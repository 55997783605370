import type { VideoFormat } from '@cofenster/constants';
import {
  type FeatureFlagName,
  FieldError,
  GridContainer,
  GridItem,
  type IconType,
  Spacing,
  VisuallyHidden,
  styled,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import { FeatureFlagRestriction } from '../../../components/featureFlag/FeatureFlagRestriction';
import { UpsellBanner } from '../../../components/featureFlag/UpsellBanner';

import { VideoFormatOption } from './VideoFormatOption';

const Fieldset = styled('fieldset')(({ theme }) => ({
  border: 0,
  padding: 0,
  marginBottom: theme.spacing(2),
}));

export type VideoFormatOptionConfiguration = {
  flag?: FeatureFlagName;
  useCases: { iconType: IconType; text: string }[];
};

const VIDEO_FORMATS_FIELD_OPTIONS: Record<VideoFormat, VideoFormatOptionConfiguration> = {
  Horizontal: {
    useCases: [
      { iconType: 'YoutubeIcon', text: 'YouTube' },
      { iconType: 'FacebookIcon', text: 'FB Cover' },
      { iconType: 'BrowserIcon', text: 'Website' },
      { iconType: 'MonitorIcon', text: 'Desktop' },
    ],
  },
  Vertical: {
    useCases: [
      { iconType: 'FacebookIcon', text: 'FB Stories' },
      { iconType: 'TikTokIcon', text: 'TikTok' },
    ],
  },
  Square: {
    flag: 'SPECIAL_FORMATS',
    useCases: [
      { iconType: 'InstagramIcon', text: 'IG Post' },
      { iconType: 'BagIcon', text: 'Carousel Ads' },
      { iconType: 'FacebookIcon', text: 'FB Video' },
    ],
  },
  SocialMedia: {
    flag: 'SPECIAL_FORMATS',
    useCases: [
      { iconType: 'InstagramIcon', text: 'IG Stories' },
      { iconType: 'FacebookIcon', text: 'FB Feed' },
      { iconType: 'InstagramIcon', text: 'IG Reels' },
      { iconType: 'LinkedinIcon', text: 'LI Feed' },
    ],
  },
};

export const VideoFormatSelector = () => {
  const learnMoreUrl = useLocalizedWebsiteUrl('PROJECT_CREATION_INFO');

  return (
    <Fieldset>
      <VisuallyHidden as="legend">i18n.projectCreate.videoFormat.header</VisuallyHidden>
      <GridContainer>
        {Object.entries(VIDEO_FORMATS_FIELD_OPTIONS).map(([format, configuration]) => (
          <GridItem xs={12} sm={3} key={format}>
            <VideoFormatOption format={format as VideoFormat} configuration={configuration} />
          </GridItem>
        ))}
      </GridContainer>

      <FieldError name="videoFormat" />

      <FeatureFlagRestriction not="SPECIAL_FORMATS">
        <Spacing top={3}>
          <UpsellBanner
            title="i18n.upsellBanner.SPECIAL_FORMATS.title"
            learnMoreUrl={learnMoreUrl}
            featureRelation="SPECIAL_FORMATS"
          >
            i18n.upsellBanner.SPECIAL_FORMATS.content
          </UpsellBanner>
        </Spacing>
      </FeatureFlagRestriction>
    </Fieldset>
  );
};
